import * as axios from 'axios';

export default class Api {

  constructor(){
    this.api_token = null;
    this.client = null;
    this.api_url = process.env.REACT_APP_API_URL;        
  }

    init = ()=>{
      this.api_token = localStorage.getItem('api_token')

      let headers = {
        'Accept':'application/json'
      }

      if(this.api_token){
        headers.Authorization = `Bearer ${this.api_token}`
      }

      this.client = axios.create({
        baseURL: this.api_url,
        timeout: 31000, 
        headers: headers,
      })

      return this.client
    }

    login(email,password){
      return this.init().post('/user/login',{email,password});
    }

    resetPassword = (data)=>{
      return this.init().post('/user/forgot-password', data);
    }

    // Set new password by Confirm email / Reset Password
    confirmEmail = (code, data)=>{
      return this.init().post(`/user/confirm-email/${code}`, data);
    }

    /**
     * =====================================================     
     *  Account Settings / Edit profile
     *  for current logged-in user
     * ===================================================== 
     */ 

    getLoginTheme = ()=>{
      return this.init().get(`/setting/theme`);
    }

    getImageTheme = ()=>{
      return this.init().get(`/setting/login-image`);
    }

    updatesSystem = ()=>{
      return this.init().get(`/patch`);
    }

    clearJobsSystem = ()=>{
      return this.init().get(`/dashboard/clear-jobs`);
    }

    getAuthObjectList = ()=>{
      return this.init().get(`/user/module-object-list`);
    }

    getProfile = ()=>{
      return this.init().get(`/user/profile`);
    }

    getLoginHistoryList = (params)=>{
      return this.init().get(`/user/login-history`,{params});
    }

    changePassword = (data)=>{
      return this.init().post(`/user/change-password`, data);
    }

    changePhotoProfile = (data)=>{
      //create form data for uploading file
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
        
      return this.init().post(`/user/change-photo-profile`, formData);
    }

    changeSiganture = (data)=>{
      //create form data for uploading file
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
        
      return this.init().post(`/user/change-image-signature`, formData);
    }

    updateLoginDetail = (data)=>{
      return this.init().post(`/user/update-login-detail`, data);
    }

    updateProfile = (data)=>{
      return this.init().post(`/user/update-profile`, data);
    }

    addForwardingApproval(data) {
      return this.init().post('/user/change-delegate', data);
    }

    editForwardingApproval(id, data) {
      return this.init().put(`/user/update-delegate/${id}`, data);
    }

    viewForwardingApproval(id) {
      return this.init().get(`/user/show-delegate/${id}`);
    }

    listForwardingApproval(params) {
      return this.init().get(`/user/delegate-all`, { params });
    }

    /**
     * =====================================================
     * USER MANAGEMENT
     * for Admin Priviledges
     * ======================================================
     */
    getAllUsersList = (params) => {
      return this.init().get('/master/user-list', { params });
    }

    getAllUsers = (params) => {
      return this.init().get('/master/user', { params });
    }
    
    //tab login detail
    addUser(data){
      return this.init().post('/master/user',data);
    }

    viewUser(id){
      return this.init().get(`/master/user/${id}`);
    }

    updateUser(id, data){
      return this.init().put(`/master/user/${id}`,data);
    }

    deleteUser(id){
      return this.init().delete(`/master/user/${id}`);
    }

    massDeleteUser(data){
      return this.init().post(`/master/multiple-delete-user`, data);
    }

    deleteRoleAssign(id, data){
      return this.init().post(`/master/delete-user-role/${id}`, data);
    }

    changeLogUser(id, params){
      return this.init().get(`/master/user-log/${id}`, {params});
    }

    changeLogRole(id, params){
      return this.init().get(`/master/role-log/${id}`, {params});
    }

    //classification parameter user
    getUserProfileParameter(){
      return this.init().get(`/master/user-profile-param`);
    }

    //tab user profile
    updateUserProfile(id, data){
      return this.init().post(`/master/user/${id}/profile`, data);
    }

    getRoleListbyUser(id,params){
      return this.init().get(`/master/user/${id}/role-list`,{params});
    }

    assignRolesToUser(id, data){
      return this.init().post(`/master/user/${id}/asign-role`, data);
    }

    getLoginHistoryUser(id,params){
      return this.init().get(`/master/user/${id}/login-history`,{params});
    }

    updatePhotoProfileUser(id, data){
      //create form data for uploading file
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
        
      return this.init().post(`/master/user/${id}/photo-profile`, formData);
    }

    downloadUserMatrix = ()=>{
      return this.init().get(`/master/download-user-matrix`, {responseType: 'blob'});
    }

    activateUser(id){
      return this.init().get(`/master/activate-user/${id}`);
    }

    blockUser(id){
      return this.init().get(`/master/block-user/${id}`);
    }

    /**
     * ========================================================
     * SYSTEM SETTINGS
     * ========================================================
     */

    //role
    getMasterRoleList = (params)=>{
      return this.init().get('/master/role-list', {params});
    }

    getMasterRole = (params)=>{
      return this.init().get('/master/role', {params});
    }

    addRole(data){
      return this.init().post('/master/role',data);
    }

    viewRole(id){
      return this.init().get(`/master/role/${id}`);
    }

    updateRole(id, data){
      return this.init().put(`/master/role/${id}`,data);
    }

    deleteRole(id){
      return this.init().delete(`/master/role/${id}`);
    }

    deleteUserAssign(id, data){
      return this.init().post(`/master/delete-role-user/${id}`, data);
    }

    massDeleteRole(data){
      return this.init().post(`/master/multiple-delete-role`, data);
    }

    copyRole(data){
      return this.init().post('/master/copy-role',data);
    }

    //plant
    getMasterPlantList = (params)=>{
      return this.init().get('/master/plant-list', { params });
    }

    getMasterPlant = (params)=>{
      return this.init().get('/master/plant', { params });
    }

    addPlant(data) {
      return this.init().post('/master/plant', data);
    }

    viewPlant(id) {
      return this.init().get(`/master/plant/${id}`);
    }

    updatePlant(id, data) {
      return this.init().put(`/master/plant/${id}`, data);
    }

    deletePlant(id) {
      return this.init().delete(`/master/plant/${id}`);
    }

    massDeletePlant(data) {
      return this.init().post(`/master/multiple-delete-plant`, data);
    }

    getMasterLocationList = (params)=>{
      return this.init().get('/master/location-list-hash', { params });
    }

    getMasterLocation = (params)=>{
      return this.init().get('/master/location', { params });
    }

    getMasterLocationByPlant=(id, params)=>{
      return this.init().get(`/master/location-list-by-plant/${id}`, {params});
    }

    changeLogPlant(id, params){
      return this.init().get(`/master/plant-log/${id}`, {params});
    }
    
    /**
     * for creating user
     * @param {integer} plant plant id
     * @param {integer} company company id
     */
    getMasterLocationBy(plant, company){
      return this.init().get('/master/location-list', { params: { plant, company }})
    }

    //modules
    getMasterModules(params) {
      return this.init().get('/master/modules', {params});
    }

    //get all modules no paginate
    getAllModules(params) {
      return this.init().get('/master/all-modules', {params});
    }

    //classification-type
    getMasterClassificationType(params) {
      return this.init().get('/master/classification-type', {params});
    }

    addClassificationType(data) {
      return this.init().post('/master/classification-type', data);
    }

    viewClassificationType(id) {
      return this.init().get(`/master/classification-type/${id}`);
    }

    updateClassificationType(id, data) {
      return this.init().put(`/master/classification-type/${id}`, data);
    }

    deleteClassificationType(id) {
      return this.init().delete(`/master/classification-type/${id}`);
    }

    massDeleteClassificationType(data) {
      // return this.init().post(`/master/multiple-delete-user`, data);
    }

    //group-material
    getMasterGroupMaterialList = (params) => {
      return this.init().get('/master/group-material-list', {params});
    }

    getMasterGroupMaterial = (params) => {
      return this.init().get('/master/group-material', {params});
    }

    addGroupMaterial(data) {
      return this.init().post('/master/group-material', data);
    }

    viewGroupMaterial(id) {
      return this.init().get(`/master/group-material/${id}`);
    }

    updateGroupMaterial(id, data) {
      return this.init().put(`/master/group-material/${id}`, data);
    }

    deleteGroupMaterial(id) {
      return this.init().delete(`/master/group-material/${id}`);
    }

    massDeleteGroupMaterial(data) {
      return this.init().post(`/master/multiple-delete-group-material`, data);
    }
    
    changeLogGroupMaterial(id, params){
      return this.init().get(`/master/group-material-log/${id}`, {params});
    }
    
    //uom
    getMasterUomList = (params) => {
      return this.init().get('/master/uom-list', { params });
    }

    getMasterUom = (params) => {
      return this.init().get('/master/uom', { params });
    }

    addUom(data) {
      return this.init().post('/master/uom', data);
    }

    viewUom(id) {
      return this.init().get(`/master/uom/${id}`);
    }

    viewUomHash(id) {
      return this.init().get(`/master/uom-hash/${id}`);
    }

    updateUom(id, data) {
      return this.init().put(`/master/uom/${id}`, data);
    }

    deleteUom(id) {
      return this.init().delete(`/master/uom/${id}`);
    }

    massDeleteUom(data) {
      return this.init().post(`/master/multiple-delete-uom`, data);
    }
    
    changeLogUom(id, params){
      return this.init().get(`/master/uom-log/${id}`, {params});
    }

    //procurement-group
    getMasterProcurementGroupList = (params) => {
      return this.init().get('/master/procurement-group-list', {params});
    }

    getMasterProcurementGroup = (params) => {
      return this.init().get('/master/procurement-group', {params});
    }

    addProcurementGroup(data) {
      return this.init().post('/master/procurement-group', data);
    }

    viewProcurementGroup(id) {
      return this.init().get(`/master/procurement-group/${id}`);
    }

    updateProcurementGroup(id, data) {
      return this.init().put(`/master/procurement-group/${id}`, data);
    }

    deleteProcurementGroup(id) {
      return this.init().delete(`/master/procurement-group/${id}`);
    }

    massDeleteProcurementGroup(data) {
      return this.init().post(`/master/multiple-delete-procurement-group`, data);
    }
    
    changeLogProcurementGroup(id, params){
      return this.init().get(`/master/procurement-group-log/${id}`, {params});
    }

    //valuation-group
    getMasterValuationGroup = (params)=>{
      return this.init().get('/master/valuation-group', {params});
    }

    addValuationGroup(data) {
      return this.init().post('/master/valuation-group', data);
    }

    viewValuationGroup(id) {
      return this.init().get(`/master/valuation-group/${id}`);
    }

    updateValuationGroup(id, data) {
      return this.init().put(`/master/valuation-group/${id}`, data);
    }

    deleteValuationGroup(id) {
      return this.init().delete(`/master/valuation-group/${id}`);
    }

    massDeleteValuationGroup(data) {
      // return this.init().post(`/master/multiple-delete-user`, data);
    }

    //location-type
    getMasterLocationTypeList(params) {
      return this.init().get('/master/location-type-list', {params});
    }

    getMasterLocationType = (params) => {
      return this.init().get('/master/location-type', {params});
    }

    addLocationType(data) {
      //create form data for uploading file
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }

      return this.init().post('/master/location-type', formData);
    }

    viewLocationType(id) {
      return this.init().get(`/master/location-type/${id}`);
    }

    updateLocationType(id, data) {
      //create form data for uploading file
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }

      return this.init().post(`/master/update-location-type/${id}`, formData);
    }

    deleteLocationType(id) {
      return this.init().delete(`/master/location-type/${id}`);
    }

    massDeleteLocationType(data) {
      return this.init().post(`/master/multiple-delete-location-type`, data);
    }
    
    changeLogLocationType(id, params){
      return this.init().get(`/master/location-type-log/${id}`, {params});
    }

    //location
    getMasterLocation(params) {
      return this.init().get('/master/location', {params});
    }

    addLocation(data) {
      return this.init().post('/master/location', data);
    }

    viewLocation(id) {
      return this.init().get(`/master/location/${id}`);
    }

    updateLocation(id, data) {
      return this.init().put(`/master/location/${id}`, data);
    }

    deleteLocation(id) {
      return this.init().delete(`/master/location/${id}`);
    }

    massDeleteLocation(data) {
      return this.init().post(`/master/multiple-delete-location`, data);
    }

    changeLogLocation(id, params){
      return this.init().get(`/master/location-log/${id}`, {params});
    }

    //storage
    getMasterStorageList(params) {
      return this.init().get('/master/storage-list',{params});
    }

    getMasterStorage(params) {
      return this.init().get('/master/storage',{params});
    }

    addStorage(data) {
      return this.init().post('/master/storage', data);
    }

    viewStorage(id) {
      return this.init().get(`/master/storage/${id}`);
    }

    updateStorage(id, data) {
      return this.init().put(`/master/storage/${id}`, data);
    }

    deleteStorage(id) {
      return this.init().delete(`/master/storage/${id}`);
    }

    massDeleteStorage(data) {
      return this.init().post(`/master/multiple-delete-storage`, data);
    }

    changeLogStorage(id, params){
      return this.init().get(`/master/storage-log/${id}`, {params});
    }

    //upload storage
    downloadStorageTemplate = ()=>{
      return this.init().get(`/master/download-template-storage`, {responseType: 'blob'});
    }
  
    uploadStorage(data){
      //create form data for uploading file
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return this.init().post('/master/storage-upload', formData);      
    }
  
    getInvalidUploadStorage = (params) => {
      return this.init().get('/master/storage-invalid-data', {params});
    }
  
    downloadStorageInvalid = ()=>{
      return this.init().get(`/master/storage-invalid-download`, {responseType: 'blob'});
    }
    
    deleteInvalidStorage() {
      return this.init().delete(`/master/storage-invalid`);
    }

    //classification-material
    getMasterClassificationMaterialList(params) {
      return this.init().get('/master/classification-material-list', {params});
    }

    getMasterClassificationMaterial(params) {
      return this.init().get('/master/classification-material', {params});
    }

    addClassificationMaterial(data) {
      return this.init().post('/master/classification-material', data);
    }

    viewClassificationMaterial(id) {
      return this.init().get(`/master/classification-material/${id}`);
    }

    updateClassificationMaterial(id, data) {
      return this.init().put(`/master/classification-material/${id}`, data);
    }

    deleteClassificationMaterial(id) {
      return this.init().delete(`/master/classification-material/${id}`);
    }

    massDeleteClassificationMaterial(data) {
      return this.init().post(`/master/multiple-delete-classification-mat`, data);
    }
    
    changeLogClassification(id, params){
      return this.init().get(`/master/classification-material-log/${id}`, {params});
    }  

    //classification-parameter
    addClassificationParameter(id,data) {
      return this.init().post(`/master/classification-material/${id}/addparam`, data);
    }

    viewClassificationParameter(id) {
      return this.init().get(`/master/classification-parameter/${id}`);
    }

    updateClassificationParameter(id, data) {
      return this.init().put(`/master/classification-parameter/${id}`, data);
    }

    deleteClassificationParameter(id) {
      return this.init().delete(`/master/classification-parameter/${id}`);
    }

    /**
     * ROLE ==============================================================================
     */

    //organization-parameter
    addOrganizationParameter(id, data) {
      return this.init().post(`/master/role/${id}/add-organization-parameter`, data);
    }

    viewOrganizationParameter(id) {
      return this.init().get(`/master/role/${id}/organization-parameter-list`);
    }

    updateOrganizationParameter(id, data) {
      return this.init().put(`/master/role/${id}/edit-organization-parameter`, data);
    }

    //auth-object
    addAuthObject(id, data) {
      return this.init().post(`/master/role/${id}/add-auth-object`, data);
    }

    viewAuthObject(id) {
      return this.init().get(`/master/role/${id}/auth-object-list`);
    }

    updateAuthObject(id, data) {
      return this.init().put(`/master/role/${id}/edit-auth-object`, data);
    }

    getUserListByRole(id, params){
      return this.init().get(`/master/role/${id}/user-list`, {params});
    }

    viewCompositeRole(id) {
      return this.init().get(`/master/composite-role/${id}`);
    }

    addCompositeRole(id, data) {
      return this.init().post(`/master/composite-role/${id}`, data);
    }

    deleteCompositeRole(id, data) {
      return this.init().post(`/master/composite-role-delete/${id}`, data);
    }

    //user-list
    updateUserList(id, data) {
      return this.init().post(`/master/role/${id}/asign-user`, data);
    }
    
    //company
    getMasterCompanyList = (params) => {
      return this.init().get('/master/company-list', { params });
    }

    getMasterCompany = (params) => {
      return this.init().get('/master/company', { params });
    }

    addCompany(data) {
      return this.init().post('/master/company', data);
    }

    viewCompany(id) {
      return this.init().get(`/master/company/${id}`);
    }

    updateCompany(id, data) {
      return this.init().put(`/master/company/${id}`, data);
    }

    deleteCompany(id) {
      return this.init().delete(`/master/company/${id}`);
    }

    massDeleteCompany(data) {
      return this.init().post(`/master/multiple-delete-company`, data);
    }
    
    changeLogCompany(id, params){
      return this.init().get(`/master/company-log/${id}`, {params});
    }

    //department
    getMasterDepartmentList(params) {
      return this.init().get('/master/department-list', { params });
    }

    getMasterDepartment = (params) => {
      return this.init().get('/master/department', { params });
    }

    addDepartment(data) {
      return this.init().post('/master/department', data);
    }

    viewDepartment(id) {
      return this.init().get(`/master/department/${id}`);
    }

    updateDepartment(id, data) {
      return this.init().put(`/master/department/${id}`, data);
    }

    deleteDepartment(id) {
      return this.init().delete(`/master/department/${id}`);
    }

    massDeleteDepartment(data) {
      return this.init().post(`/master/multiple-delete-department`, data);
    }
    
    changeLogDepartment(id, params){
      return this.init().get(`/master/department-log/${id}`, {params});
    }

    //material
    getMasterMaterial = (params) => {
      return this.init().get('/master/material', { params });
    }

    addMaterial(data) {
      return this.init().post('/master/material', data);
    }

    viewMaterial(id) {
      return this.init().get(`/master/material/${id}`);
    }

    updateMaterial(id, data) {
      return this.init().put(`/master/material/${id}`, data);
    }

    deleteMaterial(id) {
      return this.init().delete(`/master/material/${id}`);
    }

    massDeleteMaterial(data) {
      return this.init().post(`/master/multiple-delete-material`, data);
    }

    getMaterialCode(id) {
      return this.init().get(`/master/material-code-by-material-type/${id}`);
    }

    addBasic(id, data) {
      return this.init().post(`/master/material/${id}/basic`, data);
    }

    addUnitConverter(id, data) {
      return this.init().post(`/master/material/${id}/add-uom`, data);
    }

    viewMaterialUoM(id) {
      return this.init().get(`/master/material/${id}/uom-list`);
    }

    deleteUnitConverter(id) {
      return this.init().delete(`/master/material-uom/${id}`);
    }

    viewUnitConverter(id) {
      return this.init().get(`/master/material-uom/${id}`);
    }

    updateUnitConverter(id, data) {
      return this.init().put(`/master/material-uom/${id}`, data);
    }

    getMasterClassificationForMaterialOnly = (params) => {
      return this.init().get(`/master/classification-for-material`, { params });
    }

    addClassification(id, data) {
      return this.init().post(`/master/material/${id}/classification`, data);
    }

    getClassificationMaterialParameter(id) {
      return this.init().get(`/master/param-list-by-classification/${id}`);
    }

    addMaterialPuchasing(id, data) {
      return this.init().post(`/master/material/${id}/add-material-purchasing`, data);
    }

    getMasterMaterialPuchasing(id, params) {
      return this.init().get(`/master/material/${id}/material-purchasing-list`, {params});
    }

    deleteMaterialPuchasing(id) {
      return this.init().delete(`/master/material-purchasing/${id}`);
    }

    viewMaterialPuchasing(id) {
      return this.init().get(`/master/material-purchasing/${id}`);
    }

    getMasterClassificationForQualityProfile = (params) => {
      return this.init().get('/master/classification-for-quality-profile', { params });
    }

    addMaterialMRP(id, data) {
      return this.init().post(`/master/material/${id}/add-material-mrp`, data);
    }

    getMasterMaterialMRP(id, params) {
      return this.init().get(`/master/material/${id}/material-mrp-list`, {params});
    }

    deleteMaterialMRP(id) {
      return this.init().delete(`/master/material-mrp/${id}`);
    }

    addMaterialImage(id, data) {
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return this.init().post(`/master/material/${id}/add-image`, formData);
    }

    getMaterialImage(id) {
      return this.init().get(`/master/material/${id}/image-list`);
    }

    deleteMaterialImage(id) {
      return this.init().delete(`/master/material-image/${id}`);
    }

    addMaterialStorage(id, data) {
      return this.init().post(`/master/material/${id}/add-material-storage`, data);
    }

    getMasterMaterialStorage(id, params) {
      return this.init().get(`/master/material/${id}/material-storage-list`, {params});
    }

    deleteMaterialStorage(id) {
      return this.init().delete(`/master/material-storage/${id}`);
    }

    viewMaterialStorage(id) {
      return this.init().get(`/master/material-storage/${id}`);
    }

    updateMaterialStorage(id, data) {
      return this.init().put(`/master/material-storage/${id}`, data);
    }

    addMaterialAccount(id, data) {
      return this.init().post(`/master/material/${id}/add-material-account`, data);
    }

    getMasterMaterialAccount(id, params) {
      return this.init().get(`/master/material/${id}/material-account-list`, {params});
    }

    deleteMaterialAccount(id) {
      return this.init().delete(`/master/material-account/${id}`);
    }

    viewMaterialAccount(id) {
      return this.init().get(`/master/material-account/${id}`);
    }

    updateMaterialAccount(id, data) {
      return this.init().put(`/master/material-account/${id}`, data);
    }

    addMaterialSales(id, data) {
      return this.init().post(`/master/material/${id}/add-material-sales`, data);
    }

    getMasterMaterialSales(id, params) {
      return this.init().get(`/master/material/${id}/material-sales-list`, {params});
    }

    deleteMaterialSales(id) {
      return this.init().delete(`/master/material-sales/${id}`);
    }

    viewMaterialSales(id) {
      return this.init().get(`/master/material-sales/${id}`);
    }

    updateMaterialSales(id, data) {
      return this.init().put(`/master/material-sales/${id}`, data);
    }

    getMasterMaterialPlantList(params) {
      return this.init().get('/master/material-plant', { params });
    }

    massDeleteMaterialPlantList(data) {
      return this.init().post(`/master/multiple-delete-material-plant`, data);
    }

    getMasterMaterialStorageList(params) {
      return this.init().get('/master/material-storage', { params });
    }

    massDeleteMaterialStorageList(data) {
      return this.init().post(`/master/multiple-delete-material-storage`, data);
    }

    getMasterMaterialSalesList(params) {
      return this.init().get('/master/material-sales', { params });
    }

    massDeleteMaterialSalesList(data) {
      return this.init().post(`/master/multiple-delete-material-sales`, data);
    }

    getMasterMaterialAccountList(params) {
      return this.init().get('/master/material-account', { params });
    }

    massDeleteMaterialAccountList(data) {
      return this.init().post(`/master/multiple-delete-material-account`, data);
    }

    //upload material basic
    downloadMaterialBasicTemplate = ()=>{
      return this.init().get(`/master/download-template-material-basic`, {responseType: 'blob'});
    }
  
    uploadMaterialBasic(data){
      //create form data for uploading file
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return this.init().post('/master/material-basic-upload', formData);      
    }
  
    getInvalidUploadMaterialBasic = (params) => {
      return this.init().get('/master/material-basic-invalid-data', {params});
    }
  
    downloadMaterialBasicInvalid = ()=>{
      return this.init().get(`/master/material-basic-invalid-download`, {responseType: 'blob'});
    }
    
    deleteInvalidMaterialBasic() {
      return this.init().delete(`/master/material-basic-invalid`);
    }
    
    changeLogMaterialBasic(id, params){
      return this.init().get(`/master/material-log/${id}`, {params});
    }

    //upload material plant
    downloadMaterialPlantTemplate = ()=>{
      return this.init().get(`/master/download-template-material-plant`, {responseType: 'blob'});
    }
  
    uploadMaterialPlant(data){
      //create form data for uploading file
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return this.init().post('/master/material-plant-upload', formData);      
    }
  
    getInvalidUploadMaterialPlant = (params) => {
      return this.init().get('/master/material-plant-invalid-data', {params});
    }
  
    downloadMaterialPlantInvalid = ()=>{
      return this.init().get(`/master/material-plant-invalid-download`, {responseType: 'blob'});
    }
    
    deleteInvalidMaterialPlant() {
      return this.init().delete(`/master/material-plant-invalid`);
    }
    
    changeLogMaterialPlant(id, params){
      return this.init().get(`/master/material-mrp-log/${id}`, {params});
    }

    //upload material storage
    downloadMaterialStorageTemplate = ()=>{
      return this.init().get(`/master/download-template-material-storage`, {responseType: 'blob'});
    }
  
    uploadMaterialStorage(data){
      //create form data for uploading file
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return this.init().post('/master/material-storage-upload', formData);      
    }
  
    getInvalidUploadMaterialStorage = (params) => {
      return this.init().get('/master/material-storage-invalid-data', {params});
    }
  
    downloadMaterialStorageInvalid = ()=>{
      return this.init().get(`/master/material-storage-invalid-download`, {responseType: 'blob'});
    }
    
    deleteInvalidMaterialStorage() {
      return this.init().delete(`/master/material-storage-invalid`);
    }
    
    changeLogMaterialStorage(id, params){
      return this.init().get(`/master/material-storage-log/${id}`, {params});
    }

    //upload material sales
    downloadMaterialSalesTemplate = ()=>{
      return this.init().get(`/master/download-template-material-sales`, {responseType: 'blob'});
    }
  
    uploadMaterialSales(data){
      //create form data for uploading file
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return this.init().post('/master/material-sales-upload', formData);      
    }
  
    getInvalidUploadMaterialSales = (params) => {
      return this.init().get('/master/material-sales-invalid-data', {params});
    }
  
    downloadMaterialSalesInvalid = ()=>{
      return this.init().get(`/master/material-sales-invalid-download`, {responseType: 'blob'});
    }
    
    deleteInvalidMaterialSales() {
      return this.init().delete(`/master/material-sales-invalid`);
    }
    
    changeLogMaterialSales(id, params){
      return this.init().get(`/master/material-purchasing-log/${id}`, {params});
    }

    //upload material account
    downloadMaterialAccountTemplate = ()=>{
      return this.init().get(`/master/download-template-material-account`, {responseType: 'blob'});
    }
  
    uploadMaterialAccount(data){
      //create form data for uploading file
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return this.init().post('/master/material-account-upload', formData);      
    }
  
    getInvalidUploadMaterialAccount = (params) => {
      return this.init().get('/master/material-account-invalid-data', {params});
    }
  
    downloadMaterialAccountInvalid = ()=>{
      return this.init().get(`/master/material-account-invalid-download`, {responseType: 'blob'});
    }
    
    deleteInvalidMaterialAccount() {
      return this.init().delete(`/master/material-account-invalid`);
    }
    
    changeLogMaterialAccount(id, params){
      return this.init().get(`/master/material-account-log/${id}`, {params});
    }

    //material-type
    getMasterMaterialTypeList = (params) => {
      return this.init().get('/master/material-type-list',{params});
    }

    getMasterMaterialType = (params) => {
      return this.init().get('/master/material-type',{params});
    }

    addMaterialType(data) {
      return this.init().post('/master/material-type', data);
    }

    viewMaterialType(id) {
      return this.init().get(`/master/material-type/${id}`);
    }

    updateMaterialType(id, data) {
      return this.init().put(`/master/material-type/${id}`, data);
    }

    deleteMaterialType(id) {
      return this.init().delete(`/master/material-type/${id}`);
    }

    massDeleteMaterialType(data) {
      return this.init().post(`/master/multiple-delete-material-type`, data);
    }
    
    changeLogMaterialType(id, params){
      return this.init().get(`/master/material-type-log/${id}`, {params});
    }


    //cost-center
    getMasterCostCenterList = (params)=>{
      return this.init().get('/master/cost-center-list', { params });
    }

    getMasterCostCenter = (params)=>{
      return this.init().get('/master/cost-center', { params });
    }

    addCostCenter(data) {
      return this.init().post('/master/cost-center', data);
    }

    viewCostCenter(id) {
      return this.init().get(`/master/cost-center/${id}`);
    }

    updateCostCenter(id, data) {
      return this.init().put(`/master/cost-center/${id}`, data);
    }

    deleteCostCenter(id) {
      return this.init().delete(`/master/cost-center/${id}`);
    }

    massDeleteCostCenter(data) {
      return this.init().post(`/master/multiple-delete-cost-center`, data);
    }
    
    changeLogCostCenter(id, params){
      return this.init().get(`/master/cost-center-log/${id}`, {params});
    }

    //accounting-procedure
    getMasterAccountingProcedureList = (params)=>{
      return this.init().get('/accounting/accounting-procedure-hash', { params });
    }

    getMasterAccountingProcedure = (params)=>{
      return this.init().get('/accounting/accounting-procedure', { params });
    }

    addAccountingProcedure(data) {
      return this.init().post('/accounting/accounting-procedure', data);
    }

    viewAccountingProcedure(id) {
      return this.init().get(`/accounting/accounting-procedure/${id}`);
    }

    updateAccountingProcedure(id, data) {
      return this.init().put(`/accounting/accounting-procedure/${id}`, data);
    }

    deleteAccountingProcedure(id) {
      return this.init().delete(`/accounting/accounting-procedure/${id}`);
    }

    massDeleteAccountingProcedure(data) {
      return this.init().post(`/accounting/multiple-delete-accounting-procedure`, data);
    }
    
    changeLogAccountingProcedure(id, params){
      return this.init().get(`/accounting/accounting-procedure-log/${id}`, {params});
    }

    //user-group
    getMasterUserGroupList(params) {
      return this.init().get('/master/user-group-list', {params});
    }

    getMasterUserGroup = (params) => {
      return this.init().get('/master/user-group', {params});
    }

    addUserGroup(data) {
      return this.init().post('/master/user-group', data);
    }

    viewUserGroup(id) {
      return this.init().get(`/master/user-group/${id}`);
    }

    updateUserGroup(id, data) {
      return this.init().put(`/master/user-group/${id}`, data);
    }

    deleteUserGroup(id) {
      return this.init().delete(`/master/user-group/${id}`);
    }

    massDeleteUserGroup(data) {
      return this.init().post(`/master/multiple-delete-user-group`, data);
    }

    changeLogUserGroup(id, params){
      return this.init().get(`/master/user-group-log/${id}`, {params});
    }

    //profit-center
    getMasterProfitCenterList = (params) => {
      return this.init().get('/master/profit-center-list', {params});
    }

    getMasterProfitCenter = (params) => {
      return this.init().get('/master/profit-center', {params});
    }

    addProfitCenter(data) {
      return this.init().post('/master/profit-center', data);
    }

    viewProfitCenter(id) {
      return this.init().get(`/master/profit-center/${id}`);
    }

    updateProfitCenter(id, data) {
      return this.init().put(`/master/profit-center/${id}`, data);
    }

    deleteProfitCenter(id) {
      return this.init().delete(`/master/profit-center/${id}`);
    }

    massDeleteProfitCenter(data) {
      return this.init().post(`/master/multiple-delete-profit-center`, data);
    }
    
    changeLogProfitCenter(id, params){
      return this.init().get(`/master/profit-center-log/${id}`, {params});
    }

    //mrp
    getMasterMrpList(params) {
      return this.init().get('/master/mrp-list', {params});
    }

    getMasterMrp(params) {
      return this.init().get('/master/mrp', {params});
    }

    addMrp(data) {
      return this.init().post('/master/mrp', data);
    }

    viewMrp(id) {
      return this.init().get(`/master/mrp/${id}`);
    }

    updateMrp(id, data) {
      return this.init().put(`/master/mrp/${id}`, data);
    }

    deleteMrp(id) {
      return this.init().delete(`/master/mrp/${id}`);
    }

    massDeleteMrp(data) {
      return this.init().post(`/master/multiple-delete-mrp`, data);
    }
    
    changeLogMrp(id, params){
      return this.init().get(`/master/mrp-log/${id}`, {params});
    }

    //product-champion
    getMasterProductChampionList(params) {
      return this.init().get('/master/product-champion-list', {params});
    }

    getMasterProductChampion(params) {
      return this.init().get('/master/product-champion', {params});
    }

    addProductChampion(data) {
      return this.init().post('/master/product-champion', data);
    }

    viewProductChampion(id) {
      return this.init().get(`/master/product-champion/${id}`);
    }

    updateProductChampion(id, data) {
      return this.init().put(`/master/product-champion/${id}`, data);
    }

    deleteProductChampion(id) {
      return this.init().delete(`/master/product-champion/${id}`);
    }

    massDeleteProductChampion(data) {
      return this.init().post(`/master/multiple-delete-product-champion`, data);
    }
    
    changeLogProductChampion(id, params){
      return this.init().get(`/master/product-champion-log/${id}`, {params});
    }

    //storage-condition
    getMasterStorageConditionList(params) {
      return this.init().get('/master/storage-condition-list', {params});
    }

    getMasterStorageCondition(params) {
      return this.init().get('/master/storage-condition', {params});
    }

    addStorageCondition(data) {
      return this.init().post('/master/storage-condition', data);
    }

    viewStorageCondition(id) {
      return this.init().get(`/master/storage-condition/${id}`);
    }

    updateStorageCondition(id, data) {
      return this.init().put(`/master/storage-condition/${id}`, data);
    }

    deleteStorageCondition(id) {
      return this.init().delete(`/master/storage-condition/${id}`);
    }

    massDeleteStorageCondition(data) {
      return this.init().post(`/master/multiple-delete-storage-condition`, data);
    }
    
    changeLogStorageCondition(id, params){
      return this.init().get(`/master/storage-condition-log/${id}`, {params});
    }

    //temp-condition
    getMasterTempConditionList(params) {
      return this.init().get('/master/temp-condition-list', {params});
    }

    getMasterTempCondition(params) {
      return this.init().get('/master/temp-condition', {params});
    }

    addTempCondition(data) {
      return this.init().post('/master/temp-condition', data);
    }

    viewTempCondition(id) {
      return this.init().get(`/master/temp-condition/${id}`);
    }

    updateTempCondition(id, data) {
      return this.init().put(`/master/temp-condition/${id}`, data);
    }

    deleteTempCondition(id) {
      return this.init().delete(`/master/temp-condition/${id}`);
    }

    massDeleteTempCondition(data) {
      return this.init().post(`/master/multiple-delete-temp-condition`, data);
    }
    
    changeLogTempCondition(id, params){
      return this.init().get(`/master/temp-condition-log/${id}`, {params});
    }

    //storage-type
    getMasterStorageTypeList(params) {
      return this.init().get('/master/storage-type-list', {params});
    }

    getMasterStorageType(params) {
      return this.init().get('/master/storage-type', {params});
    }

    addStorageType(data) {
      return this.init().post('/master/storage-type', data);
    }

    viewStorageType(id) {
      return this.init().get(`/master/storage-type/${id}`);
    }

    updateStorageType(id, data) {
      return this.init().put(`/master/storage-type/${id}`, data);
    }

    deleteStorageType(id) {
      return this.init().delete(`/master/storage-type/${id}`);
    }

    massDeleteStorageType(data) {
      return this.init().post(`/master/multiple-delete-storage-type`, data);
    }
    
    changeLogStorageType(id, params){
      return this.init().get(`/master/storage-type-log/${id}`, {params});
    }

    //sales-organization
    getMasterSalesOrganizationList(params) {
      return this.init().get('/master/sales-organization-list', {params});
    }

    getMasterSalesOrganization(params) {
      return this.init().get('/master/sales-organization', {params});
    }

    addSalesOrganization(data) {
      return this.init().post('/master/sales-organization', data);
    }

    viewSalesOrganization(id) {
      return this.init().get(`/master/sales-organization/${id}`);
    }

    updateSalesOrganization(id, data) {
      return this.init().put(`/master/sales-organization/${id}`, data);
    }

    deleteSalesOrganization(id) {
      return this.init().delete(`/master/sales-organization/${id}`);
    }

    massDeleteSalesOrganization(data) {
      return this.init().post(`/master/multiple-delete-sales-organization`, data);
    }
    
    changeLogSalesOrganization(id, params){
      return this.init().get(`/master/sales-organization-log/${id}`, {params});
    }

    //account-assignment
    getMasterAccountAssignmentList(params) {
      return this.init().get('/master/account-assignment-list', {params});
    }

    getMasterAccountAssignment(params) {
      return this.init().get('/master/account-assignment', {params});
    }

    addAccountAssignment(data) {
      return this.init().post('/master/account-assignment', data);
    }

    viewAccountAssignment(id) {
      return this.init().get(`/master/account-assignment/${id}`);
    }

    updateAccountAssignment(id, data) {
      return this.init().put(`/master/account-assignment/${id}`, data);
    }

    deleteAccountAssignment(id) {
      return this.init().delete(`/master/account-assignment/${id}`);
    }

    massDeleteAccountAssignment(data) {
      return this.init().post(`/master/multiple-delete-account-assignment`, data);
    }
    
    changeLogAccountAssignment(id, params){
      return this.init().get(`/master/account-assignment-log/${id}`, {params});
    }

    //item-category
    getMasterItemCategoryList(params) {
      return this.init().get('/master/item-category-list', {params});
    }

    getMasterItemCategory(params) {
      return this.init().get('/master/item-category', {params});
    }

    addItemCategory(data) {
      return this.init().post('/master/item-category', data);
    }

    viewItemCategory(id) {
      return this.init().get(`/master/item-category/${id}`);
    }

    updateItemCategory(id, data) {
      return this.init().put(`/master/item-category/${id}`, data);
    }

    deleteItemCategory(id) {
      return this.init().delete(`/master/item-category/${id}`);
    }

    massDeleteItemCategory(data) {
      return this.init().post(`/master/multiple-delete-item-category`, data);
    }

    changeLogItemCategory(id, params){
      return this.init().get(`/master/item-category-log/${id}`, {params});
    }

    //valuation-type
    getMasterValuationTypeList(params) {
      return this.init().get('/master/valuation-type-list', {params});
    }

    getMasterValuationType(params) {
      return this.init().get('/master/valuation-type', {params});
    }

    addValuationType(data) {
      return this.init().post('/master/valuation-type', data);
    }

    viewValuationType(id) {
      return this.init().get(`/master/valuation-type/${id}`);
    }

    updateValuationType(id, data) {
      return this.init().put(`/master/valuation-type/${id}`, data);
    }

    deleteValuationType(id) {
      return this.init().delete(`/master/valuation-type/${id}`);
    }

    massDeleteValuationType(data) {
      return this.init().post(`/master/multiple-delete-valuation-type`, data);
    }
    
    changeLogValuationType(id, params){
      return this.init().get(`/master/valuation-type-log/${id}`, {params});
    }

    //valuation-class
    getMasterValuationClassList(params) {
      return this.init().get('/master/valuation-class-list', {params});
    }

    getMasterValuationClass(params) {
      return this.init().get('/master/valuation-class', {params});
    }

    addValuationClass(data) {
      return this.init().post('/master/valuation-class', data);
    }

    viewValuationClass(id) {
      return this.init().get(`/master/valuation-class/${id}`);
    }

    updateValuationClass(id, data) {
      return this.init().put(`/master/valuation-class/${id}`, data);
    }

    deleteValuationClass(id) {
      return this.init().delete(`/master/valuation-class/${id}`);
    }

    massDeleteValuationClass(data) {
      return this.init().post(`/master/multiple-delete-valuation-class`, data);
    }
    
    changeLogValuationClass(id, params){
      return this.init().get(`/master/valuation-class-log/${id}`, {params});
    }

    //stock-determination
    getMasterStockDeterminationList(params) {
      return this.init().get('/master/stock-determination-list', {params});
    }

    getMasterStockDetermination(params) {
      return this.init().get('/master/stock-determination', {params});
    }

    addStockDetermination(data) {
      return this.init().post('/master/stock-determination', data);
    }

    viewStockDetermination(id) {
      return this.init().get(`/master/stock-determination/${id}`);
    }

    updateStockDetermination(id, data) {
      return this.init().put(`/master/stock-determination/${id}`, data);
    }

    deleteStockDetermination(id) {
      return this.init().delete(`/master/stock-determination/${id}`);
    }

    massDeleteStockDetermination(data) {
      return this.init().post(`/master/multiple-delete-stock-determination`, data);
    }

    getMasterStorageByPlant=(id, params)=>{
      return this.init().get(`/master/storage-by-plant/${id}`, {params});
    }
    
    changeLogStockDetermination(id, params){
      return this.init().get(`/master/stock-determination-log/${id}`, {params});
    }

    //upload stock-determination
    downloadStockDeterminationTemplate = ()=>{
      return this.init().get(`/master/download-template-stock-determination`, {responseType: 'blob'});
    }
  
    uploadStockDetermination(data){
      //create form data for uploading file
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return this.init().post('/master/stock-determination-upload', formData);      
    }
  
    getInvalidUploadStockDetermination = (params) => {
      return this.init().get('/master/stock-determination-invalid-data', {params});
    }
  
    downloadStockDeterminationInvalid = ()=>{
      return this.init().get(`/master/stock-determination-invalid-download`, {responseType: 'blob'});
    }
    
    deleteInvalidStockDetermination() {
      return this.init().delete(`/master/stock-determination-invalid`);
    }

    //work-center
    getMasterWorkCenterList = (params)=>{
      return this.init().get('/master/work-center-list', {params});
    }

    getMasterWorkCenter = (params)=>{
      return this.init().get('/master/work-center', {params});
    }

    addWorkCenter(data) {
      return this.init().post('/master/work-center', data);
    }

    viewWorkCenter(id) {
      return this.init().get(`/master/work-center/${id}`);
    }

    updateWorkCenter(id, data) {
      return this.init().put(`/master/work-center/${id}`, data);
    }

    deleteWorkCenter(id) {
      return this.init().delete(`/master/work-center/${id}`);
    }

    massDeleteWorkCenter(data) {
      return this.init().post(`/master/multiple-delete-work-center`, data);
    }

    getMaterialWorkCenterByBOM = (id, params)=>{
      return this.init().get('/master/material-work-center', {params: {...params, storage_id:id}});
    }

    addMaterialWorkCenter(id, data) {
      return this.init().post(`/master/work-center/${id}/assign-material`, data);
    }

    massDeleteMaterialWorkCenter(id,data) {
      return this.init().post(`/master/work-center/${id}/delete-material`, data);
    }
    
    changeLogWorkCenter(id, params){
      return this.init().get(`/master/work-center-log/${id}`, {params});
    }
    

    //movement-type
    getMasterMovementTypeList = (params) => {
      return this.init().get('/master/movement-type-list', {params});
    }

    getMasterMovementType = (params) => {
      return this.init().get('/master/movement-type', {params});
    }

    addMovementType(data) {
      return this.init().post('/master/movement-type', data);
    }

    viewMovementType(id) {
      return this.init().get(`/master/movement-type/${id}`);
    }

    updateMovementType(id, data) {
      return this.init().put(`/master/movement-type/${id}`, data);
    }

    deleteMovementType(id) {
      return this.init().delete(`/master/movement-type/${id}`);
    }

    massDeleteMovementType(data) {
      return this.init().post(`/master/multiple-delete-movement-type`, data);
    }
    
    changeLogMovementType(id, params){
      return this.init().get(`/master/movement-type-log/${id}`, {params});
    }

    //movement type reason
    getMovementTypeReason(id) {
      return this.init().get(`/master/movement-type/${id}/reason-list`);
    }
    addMovementTypeReason(id,data) {
      return this.init().post(`/master/movement-type/${id}/add-reason`, data);
    }

    viewMovementTypeReason(id) {
      return this.init().get(`/master/movement-type-reason/${id}`);
    }

    updateMovementTypeReason(id, data) {
      return this.init().put(`/master/movement-type-reason/${id}`, data);
    }

    deleteMovementTypeReason(id) {
      return this.init().delete(`/master/movement-type-reason/${id}`);
    }

    //vendor
    getMasterVendorList = (params)=>{
      return this.init().get('/master/supplier-list', {params});
    }

    getMasterVendor = (params)=>{
      return this.init().get('/master/supplier', {params});
    }

    addVendor(data) {
      return this.init().post('/master/supplier', data);
    }

    viewVendor(id) {
      return this.init().get(`/master/supplier/${id}`);
    }

    updateVendor(id, data) {
      return this.init().put(`/master/supplier/${id}`, data);
    }

    deleteVendor(id) {
      return this.init().delete(`/master/supplier/${id}`);
    }

    massDeleteVendor(data) {
      return this.init().post(`/master/multiple-delete-supplier`, data);
    }
    
    changeLogVendor(id, params){
      return this.init().get(`/master/supplier-log/${id}`, {params});
    }

    //vendor-group
    getMasterVendorGroupList(params) {
      return this.init().get('/master/vendor-group-list', {params});
    }

    getMasterVendorGroup = (params) => {
      return this.init().get('/master/vendor-group', {params});
    }

    addVendorGroup(data) {
      return this.init().post('/master/vendor-group', data);
    }

    viewVendorGroup(id) {
      return this.init().get(`/master/vendor-group/${id}`);
    }

    updateVendorGroup(id, data) {
      return this.init().put(`/master/vendor-group/${id}`, data);
    }

    deleteVendorGroup(id) {
      return this.init().delete(`/master/vendor-group/${id}`);
    }

    massDeleteVendorGroup(data) {
      return this.init().post(`/master/multiple-delete-vendor-group`, data);
    }
    
    changeLogVendorGroup(id, params){
      return this.init().get(`/master/vendor-group-log/${id}`, {params});
    }

    //stock-overview
    getMasterStockOverview(params) {
      return this.init().get('/threepl/stock-overview', {params});
    }
    getSerialByStockMain(id, params) {
      return this.init().get(`/threepl/stock-serial/${id}`, {params});
    }
    getSerialByBatch(id, params) {
      return this.init().get(`/threepl/stock-serial-batch/${id}`, {params});
    }
    replenishment(data) {
      return this.init().post(`/threepl/stock-replenishment`, data);
    }

    //reservation
    getReservation(params) {
      return this.init().get('/threepl/reservation', {params});
    }

    getReservationCode() {
      return this.init().get('/threepl/reservation/get-code');
    }

    addReservation(data) {
      return this.init().post('/threepl/reservation', data);
    }

    viewReservation(code) {
      return this.init().get(`/threepl/reservation/${code}`);
    }

    updateReservation(code, data) {
      return this.init().post(`/threepl/reservation/${code}`, data);
    }

    createReservationItems = (code,data)=>{
      return this.init().post(`/threepl/reservation/${code}/item-line`, data);
    }

    updateReservationItems = (code,data)=>{
      return this.init().post(`/threepl/reservation/${code}/update-item-line`, data);
    }

    getPlantReservation = (params)=>{
      return this.init().get('/threepl/reservation/plant-by-stock-determination', {params});
    }

    getStorageReservation = (params)=>{
      return this.init().get('/threepl/reservation/storage-by-stock-determination', {params});
    }

    getDefaultStockDetermination = (params)=>{
      return this.init().get('/threepl/reservation/get-supply-stock-determination', {params});
    }

    createAccountAssignment = (id,data)=>{
      return this.init().post(`/threepl/reservation/account-assignment/${id}`, data);
    }

    createPurchasing = (id,data)=>{
      return this.init().post(`/threepl/reservation/purchasing/${id}`, data);
    }

    viewReservationItem(code) {
      return this.init().get(`/threepl/reservation/show-item/${code}`);
    }

    submitReservation = (code)=>{
      return this.init().post(`/threepl/reservation/submit/${code}`);
    }

    cancelReservation = (code)=>{
      return this.init().post(`/threepl/reservation/cancel/${code}`);
    }

    aprrovalReservation = (code, data)=>{
      return this.init().post(`/threepl/reservation/${code}/approval`, data);
    }

    getDraft() {
      return this.init().get(`/threepl/reservation/get/draft`);
    }

    getUomMaterial = (id, params)=>{
      return this.init().get(`/threepl/uom-list-by-material/${id}`, {params});
    }

    uploadReservationAttachment(code, data) {
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return this.init().post(`/threepl/reservation/${code}/add-attachment`, formData);
    }

    deleteReservationAttachment(code, data) {
      return this.init().post(`/threepl/reservation/delete-attachment/${code}`, data);
    }

    getPreveredVendor=(id, params)=>{
      return this.init().get(`/threepl/reservation/vendor-list/${id}`, {params});
    }

    deleteReservation(id) {
      return this.init().delete(`/threepl/reservation/${id}`);
    }
    
    changeLogReservation(id, params){
      return this.init().get(`/threepl/reservation/show-log/${id}`, {params});
    }

    //PR
    getReservationPR(params) {
      return this.init().get('/threepl/purchase-requisition', {params});
    }

    getPRCode() {
      return this.init().get('/threepl/purchase-requisition/get-pr-code');
    }

    converetPRToPO(data) {
      return this.init().post(`/threepl/purchase-requisition/convert/purchase-order`, data);
    }

    addPR(data) {
      return this.init().post('/threepl/purchase-requisition', data);
    }

    viewPR(code) {
      return this.init().get(`/threepl/purchase-requisition/${code}`);
    }

    updatePR(code, data) {
      return this.init().post(`/threepl/purchase-requisition/${code}`, data);
    }

    createPRItems = (code,data)=>{
      return this.init().post(`/threepl/purchase-requisition/${code}/item-line`, data);
    }

    updatePRItems = (code,data)=>{
      return this.init().post(`/threepl/purchase-requisition/${code}/update-item-line`, data);
    }

    createAccountAssignmentPR = (id,data)=>{
      return this.init().post(`/threepl/purchase-requisition/account-assignment/${id}`, data);
    }

    createPurchasingPR = (id,data)=>{
      return this.init().post(`/threepl/purchase-requisition/purchasing/${id}`, data);
    }

    viewPRItem(code) {
      return this.init().get(`/threepl/purchase-requisition/show-item/${code}`);
    }

    submitPR = (code)=>{
      return this.init().post(`/threepl/purchase-requisition/submit/${code}`);
    }

    cancelPR = (code)=>{
      return this.init().post(`/threepl/purchase-requisition/cancel/${code}`);
    }

    aprrovalPR = (code, data)=>{
      return this.init().post(`/threepl/purchase-requisition/${code}/approval`, data);
    }

    dataUoP(id_material, id_plant) {
      return this.init().get(`/threepl/purchase-requisition/unit-of-procurement/${id_material}/${id_plant}`);
    }

    uploadPRAttachment(code, data) {
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return this.init().post(`/threepl/purchase-requisition/${code}/add-attachment`, formData);
    }

    deletePRAttachment(code, data) {
      return this.init().post(`/threepl/purchase-requisition/delete-attachment/${code}`, data);
    }

    deletePR(id) {
      return this.init().delete(`/threepl/purchase-requisition/${id}`);
    }

    assignProcGroup(data) {
      return this.init().post('/threepl/purchase-requisition/assign/proc-group', data);
    }
    
    changeLogPR(id, params){
      return this.init().get(`/threepl/purchase-requisition/show-log/${id}`, {params});
    }

    //PO
    getPurchaseOrders(params) {
      return this.init().get('/threepl/purchase-orders', {params});
    }

    viewPurchaseOrder(id) {
      return this.init().get(`/threepl/purchase-orders/${id}`);
    }

    updatePurchaseOrderHeader(id, data) {
      return this.init().put(`/threepl/purchase-orders/${id}/header`, data);
    }

    updatePurchaseOrderItem(id, data) {
      return this.init().put(`/threepl/purchase-orders/${id}/item`, data);
    }

    viewPurchaseOrderItem(id) {
      return this.init().get(`/threepl/purchase-orders/${id}/show-item`);
    }

    POAccountAssignment = (id,data)=>{
      return this.init().put(`/threepl/purchase-orders/${id}/account-assignment`, data);
    }

    submitPO(data) {
      return this.init().post(`/threepl/purchase-orders/submit`, data);
    }

    approvePO(data) {
      return this.init().post(`/threepl/purchase-orders/approve`, data);
    }

    rejectPO(data) {
      return this.init().post(`/threepl/purchase-orders/reject`, data);
    }

    downloadPDFPO = (id)=>{
      return this.init().get(`/threepl/purchase-orders/${id}/pdf`, {responseType: 'blob'});
    }

    downloadMemoRFQ = (your_reference)=>{
      return this.init().get(`/threepl/rfq/${your_reference}/memo`, {responseType: 'blob'});
    }

    completePO = (id_po, data)=>{
      return this.init().post(`/threepl/purchase-orders/${id_po}/complete-item`, data);
    }

    uploadPOAttachment(id, data) {
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return this.init().post(`/threepl/purchase-orders/${id}/add-attachment`, formData);
    }

    deletePOAttachment(id, data) {
      return this.init().post(`/threepl/purchase-orders/delete-attachment/${id}`, data);
    }

    addConditionPO(id, data) {
      return this.init().post(`/threepl/purchase-orders/${id}/add-condition`, data);
    }

    deleteConditionPO(id) {
      return this.init().delete(`/threepl/purchase-orders/${id}/delete-condition`);
    }

    deletePOHeader(id) {
      return this.init().delete(`/threepl/purchase-orders/${id}/header`);
    }

    sendPurchaseOrder(id) {
      return this.init().get(`/threepl/purchase-orders/${id}/send`);
    }
    
    changeLogPO(id, params){
      return this.init().get(`/threepl/purchase-orders/show-header-log/${id}`, {params});
    }
    
    priviewPDFPO = (id) => {
      return this.init().get(`/threepl/purchase-orders/${id}/priview`, {responseType: 'blob'});
    }

    //RFQ
    converetPRToRFQ(data) {
      return this.init().post(`/threepl/rfq/convert-from-pr`, data);
    }

    getRFQ(params) {
      return this.init().get('/threepl/rfq', {params});
    }

    viewRFQ(id) {
      return this.init().get(`/threepl/rfq/${id}`);
    }

    updateRFQHeader(id, data) {
      return this.init().put(`/threepl/rfq/${id}/header`, data);
    }

    submitRFQ(data) {
      return this.init().post(`/threepl/rfq/submit`, data);
    }

    updateRFQItem(id, data) {
      return this.init().put(`/threepl/rfq/${id}/item`, data);
    }

    viewRFQItem(id) {
      return this.init().get(`/threepl/rfq/${id}/show-item`);
    }

    RFQAccountAssignment = (id,data)=>{
      return this.init().put(`/threepl/rfq/${id}/account-assignment`, data);
    }

    copyRFQ(id, data) {
      return this.init().post(`/threepl/rfq/${id}/copy`, data);
    }

    getRFQEvaluate(bid_number) {
      return this.init().get(`/threepl/rfq/evaluate/${bid_number}`);
    }

    awardRFQ(bid_number, data) {
      return this.init().post(`/threepl/rfq/award/${bid_number}`, data);
    }

    covertRFQtoPO(id) {
      return this.init().get(`/threepl/rfq/${id}/convert-to-purchase-order`);
    }

    sendRFQ(id) {
      return this.init().get(`/threepl/rfq/${id}/send`);
    }

    downloadPDFRFQ = (id)=>{
      return this.init().get(`/threepl/rfq/${id}/print`, {responseType: 'blob'});
    }

    deleteRFQ(id) {
      return this.init().delete(`/threepl/rfq/${id}/header`);
    }

    approveRFQ(id, data) {
      return this.init().post(`/threepl/rfq/${id}/signature`, data);
    }

    rejectRFQ(id, data) {
      return this.init().post(`/threepl/rfq/${id}/reject`, data);
    }
    
    changeLogRFQ(id, params){
      return this.init().get(`/threepl/rfq/show-header-log/${id}`, {params});
    }

    /**
     * Goods Movement =====================================================================
     */

    getMovementTypeSpesific = (params)=>{
      return this.init().get('/threepl/goods-movement/specific-movement-type', {params});
    }

    getReasonByMovementType = (id,params)=>{
      return this.init().get(`/threepl/goods-movement/reason-by-movement-type/${id}`, {params});
    }

    getWorkCenterByStorage = (id,params)=>{
      return this.init().get(`/threepl/goods-movement/work-center-by-storage/${id}`, {params});
    }

    getCostCenterByWorkCenter = (id,params)=>{
      return this.init().get(`/threepl/goods-movement/cost-center-by-work-center/${id}`, {params});
    }

    createGoodsMovementHeader = (data)=>{
      return this.init().post('/threepl/goods-movement/header', data);
    }

    getGoodsMovementIncompleteList = (params)=>{
      return this.init().get('/threepl/goods-movement/incomplete-list', {params});
    }

    //upload GM
    downloadGMTemplate = ()=>{
      return this.init().get(`/threepl/goods-movement/download-template-initial-stock-balance`, {responseType: 'blob'});
    }
  
    uploadGM(data){
      //create form data for uploading file
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return this.init().post('/threepl/goods-movement/initial-stock-balance-upload', formData);      
    }
  
    getInvalidUploadGM = (params) => {
      return this.init().get('/threepl/goods-movement/initial-stock-balance-invalid-data', {params});
    }
  
    downloadGMInvalid = ()=>{
      return this.init().get(`/threepl/goods-movement/initial-stock-balance-invalid-download`, {responseType: 'blob'});
    }
    
    deleteInvalidGM() {
      return this.init().delete(`/threepl/goods-movement/initial-stock-balance-invalid`);
    }

    //production confirmation
    getProductionConfirmationList = (params)=>{
      return this.init().get('/threepl/goods-movement/incomplete-production-confirmation', {params});
    }

    viewGoodsMovementIncomplete = (id)=>{
      return this.init().get(`/threepl/goods-movement/incomplete/${id}`);
    }

    getMaterialHaveMaterialPlant = (params)=>{
      return this.init().get('/threepl/material', {params});
    }

    getMaterialcatalog = (params)=>{
      return this.init().get('/threepl/material-catalog', {params});
    }

    getMaterialHaveMaterialPlantByPlantId = (id, params)=>{
      return this.init().get('/threepl/material', {params: {...params, plant_id:id}});
    }

    //add items by header
    createGoodsMovementItems = (id,data)=>{
      return this.init().post(`/threepl/goods-movement/${id}/item-serial-batch`, data);
    }

    getBatchList = (params)=>{
      return this.init().get('/threepl/batch-list', {params});
    }

    getSerialList = (params)=>{
      return this.init().get('/threepl/serial-list', {params});
    }

    getPlantReceiverByStockDetermination = (params)=>{
      return this.init().get('/threepl/plant-receiver-by-stock-determination', {params});
    }

    getStorageReceiverByStockDetermination = (params)=>{
      return this.init().get('/threepl/storage-receiver-by-stock-determination', {params});
    }

    // picking list
    getPickingList = (params) => {
      return this.init().get('/threepl/delivery-orders/picking-list', { params });
    }
    editAllocate(id, data) {
      return this.init().post(`/threepl/delivery-orders/edit-allocated-qty/${id}`, data);
    }

    convertDo(data) {
      return this.init().post(`/threepl/delivery-orders/convert-as-delivery-order`, data);
    }

    convertPr(data) {
      return this.init().post(`/threepl/delivery-orders/convert-as-purchase-requisition`, data);
    }

    rejectPickingList(data) {
      return this.init().post(`/threepl/delivery-orders/reject-picking-list`, data);
    }

    sendPrToNav(data) {
      return this.init().post(`/threepl/delivery-orders/convert-as-purchase-requisition`, data);
    }

    alocateDO(id) {
      return this.init().get(`/threepl/delivery-orders/${id}/allocate-batch`);
    }

    cancelDO(id) {
      return this.init().post(`/threepl/delivery-orders/cancel-do/${id}`);
    }

    //monthly-consumption
    getMonthlyConsumption(params) {
      return this.init().get('/threepl/monthly-consumption', {params});
    }

    downloadMonthlyConsumption = (params)=>{
      return this.init().get(`/threepl/monthly-consumption/download-excel`, {params, responseType: 'blob'});
    }

    getMovementTypeMountlyConsumption = (params) => {
      return this.init().get('/threepl/monthly-consumption/specific-movement-type', {params});
    }

    // delivery list
    getDeliveryList = (params) => {
      return this.init().get('/threepl/delivery-orders', { params });
    }

    sendDO(id,data) {
      return this.init().post(`/threepl/delivery-orders/send-do/${id}`, data);
    }
    viewDeliveryDetail = (id)=>{
      return this.init().get(`/threepl/delivery-orders/${id}`);
    }
    updateDeliveryDetail(id, data) {
      return this.init().put(`/threepl/delivery-orders/${id}`, data);
    }
    downloadPDF = (id)=>{
      return this.init().get(`/threepl/delivery-orders/${id}/pdf`, {responseType: 'blob'});
    }
    downloadPDFpicking = (id)=>{
      return this.init().get(`/threepl/delivery-orders/${id}/pdf-picking`, {responseType: 'blob'});
    }
    
    reverseMovement(data) {
      return this.init().post(`/threepl/reverse-movement/${data}`);
    }

    showDOByCode = (code)=>{
      return this.init().get(`/threepl/delivery-orders/${code}/code`);
    }
    showPOByCode = (code)=>{
      return this.init().get(`/threepl/purchase-orders/${code}/code`);
    }
    showProdOByCode = (code)=>{
      return this.init().get(`/threepl/production-order/${code}/code`);
    }
    
    changeLogDO(id, params){
      return this.init().get(`/threepl/delivery-orders/show-header-log/${id}`, {params});
    }


    //goods-movement-report
    getGoodsMovementReport = (params)=>{
      return this.init().get('/threepl/history-movement', {params});
    }

    downloadGMReport = (params)=>{
      return this.init().get(`/threepl/history-movement/download-excel`, {params, responseType: 'blob'});
    }

    viewGoodsMovementReport = (id)=>{
      return this.init().get(`/threepl/history-movement/show/${id}`);
    }

    getSerialByMovementHistory(id, params) {
      return this.init().get(`/threepl/history-movement/serial/${id}`, {params});
    }

    downloadPDFGR = (code)=>{
      return this.init().get(`/threepl/history-movement/pdf/${code}`, {responseType: 'blob'});
    }

    //transport-type
    getMasterTransportTypeList = (params) => {
      return this.init().get('/master/transport-type-list', {params});
    } 

    getMasterTransportType = (params) => {
      return this.init().get('/master/transport-type', {params});
    }

    addTransportType(data) {
      return this.init().post('/master/transport-type', data);
    }

    viewTransportType(id) {
      return this.init().get(`/master/transport-type/${id}`);
    }

    updateTransportType(id, data) {
      return this.init().put(`/master/transport-type/${id}`, data);
    }

    deleteTransportType(id) {
      return this.init().delete(`/master/transport-type/${id}`);
    }

    massDeleteTransportType(data) {
      return this.init().post(`/master/multiple-delete-transport-type`, data);
    }
    
    changeLogTransportType(id, params){
      return this.init().get(`/master/transport-type-log/${id}`, {params});
    }

    //incoterm
    getMasterIncotermList = (params) => {
      return this.init().get('/master/incoterm-list', {params});
    }

    getMasterIncoterm = (params) => {
      return this.init().get('/master/incoterm', {params});
    }

    addIncoterm(data) {
      return this.init().post('/master/incoterm', data);
    }

    viewIncoterm(id) {
      return this.init().get(`/master/incoterm/${id}`);
    }

    updateIncoterm(id, data) {
      return this.init().put(`/master/incoterm/${id}`, data);
    }

    deleteIncoterm(id) {
      return this.init().delete(`/master/incoterm/${id}`);
    }

    massDeleteIncoterm(data) {
      return this.init().post(`/master/multiple-delete-incoterm`, data);
    }
    
    changeLogIncoterm(id, params){
      return this.init().get(`/master/incoterm-log/${id}`, {params});
    }

    //term-of-payment
    getMasterTermOfPaymentList = (params) => {
      return this.init().get('/master/term-of-payment-list', {params});
    }

    getMasterTermOfPayment = (params) => {
      return this.init().get('/master/term-of-payment', {params});
    }

    addTermOfPayment(data) {
      return this.init().post('/master/term-of-payment', data);
    }

    viewTermOfPayment(id) {
      return this.init().get(`/master/term-of-payment/${id}`);
    }

    updateTermOfPayment(id, data) {
      return this.init().put(`/master/term-of-payment/${id}`, data);
    }

    deleteTermOfPayment(id) {
      return this.init().delete(`/master/term-of-payment/${id}`);
    }

    massDeleteTermOfPayment(data) {
      return this.init().post(`/master/multiple-delete-term-of-payment`, data);
    }
    
    changeLogTermOfPayment(id, params){
      return this.init().get(`/master/term-of-payment-log/${id}`, {params});
    }

    //project
    getMasterProjectList = (params) => {
      return this.init().get('/master/project-list', {params});
    }

    getMasterProject = (params) => {
      return this.init().get('/master/project', {params});
    }

    addProject(data) {
      return this.init().post('/master/project', data);
    }

    viewProject(id) {
      return this.init().get(`/master/project/${id}`);
    }

    updateProject(id, data) {
      return this.init().put(`/master/project/${id}`, data);
    }

    deleteProject(id) {
      return this.init().delete(`/master/project/${id}`);
    }

    massDeleteProject(data) {
      return this.init().post(`/master/multiple-delete-project`, data);
    }
    
    changeLogProject(id, params){
      return this.init().get(`/master/project-log/${id}`, {params});
    }

    //chart-of-account
    getMasterChartOfAccountList = (params) => {
      return this.init().get('/master/chart-of-account-list', {params});
    }

    getMasterChartOfAccount(params) {
      return this.init().get('/master/chart-of-account', {params});
    }

    addChartOfAccount(data) {
      return this.init().post('/master/chart-of-account', data);
    }

    viewChartOfAccount(id) {
      return this.init().get(`/master/chart-of-account/${id}`);
    }

    updateChartOfAccount(id, data) {
      return this.init().put(`/master/chart-of-account/${id}`, data);
    }

    deleteChartOfAccount(id) {
      return this.init().delete(`/master/chart-of-account/${id}`);
    }

    massDeleteChartOfAccount(data) {
      return this.init().post(`/master/multiple-delete-chart-of-account`, data);
    }

    exportCoa = (params) => {
      return this.init().get('/master/chart-of-account-download', {params, responseType: 'blob'});
    }
    
    changeLogChartOfAccount(id, params){
      return this.init().get(`/master/chart-of-account-log/${id}`, {params});
    }

    //upload coa
    downloadChartOfAccountTemplate = ()=>{
      return this.init().get(`/master/download-template-chart-of-account`, {responseType: 'blob'});
    }
  
    uploadChartOfAccount(data){
      //create form data for uploading file
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return this.init().post('/master/chart-of-account-upload', formData);      
    }
  
    getInvalidUploadChartOfAccount = (params) => {
      return this.init().get('/master/chart-of-account-invalid-data', {params});
    }
  
    downloadChartOfAccountInvalid = ()=>{
      return this.init().get(`/master/chart-of-account-invalid-download`, {responseType: 'blob'});
    }
    
    deleteInvalidChartOfAccount() {
      return this.init().delete(`/master/chart-of-account-invalid`);
    }

    //account-group
    getMasterAccountGroup(id, params) {
      return this.init().get(`/master/chart-of-account/${id}/account-group`, {params});
    }

    addAccountGroup(id, data) {
      return this.init().post(`/master/chart-of-account/${id}/add-account-group`, data);
    }

    viewAccountGroup(id) {
      return this.init().get(`/master/account-group/${id}`);
    }

    updateAccountGroup(id, data) {
      return this.init().put(`/master/account-group/${id}`, data);
    }

    deleteAccountGroup(id) {
      return this.init().delete(`/master/account-group/${id}`);
    }

    massDeleteAccountGroup(data) {
      return this.init().post(`/master/multiple-delete-account-group`, data);
    }
    
    changeLogAccountGroup(id, params){
      return this.init().get(`/master/account-group-log/${id}`, {params});
    }

    //account
    getMasterAccountOnly(id, params) {
      return this.init().get(`/master/account-group/${id}/account`, {params});
    }

    addAccount(id, data) {
      return this.init().post(`/master/account-group/${id}/add-account`, data);
    }

    viewAccount(id) {
      return this.init().get(`/master/account/${id}`);
    }

    updateAccount(id, data) {
      return this.init().put(`/master/account/${id}`, data);
    }

    deleteAccount(id) {
      return this.init().delete(`/master/account/${id}`);
    }

    massDeleteAccount(data) {
      return this.init().post(`/master/multiple-delete-account`, data);
    }

    getMasterAccount = (params) => {
      return this.init().get('/master/account', {params});
    }
    
    changeLogAccount(id, params){
      return this.init().get(`/master/account-log/${id}`, {params});
    }


    //info-records
    getMasterInfoRecords(params) {
      return this.init().get('/master/material-vendor', { params });
    }

    addInfoRecords(data) {
      return this.init().post('/master/material-vendor', data);
    }

    viewInfoRecords(id) {
      return this.init().get(`/master/material-vendor/${id}`);
    }

    updateInfoRecords(id, data) {
      return this.init().put(`/master/material-vendor/${id}`, data);
    }

    deleteInfoRecords(id) {
      return this.init().delete(`/master/material-vendor/${id}`);
    }

    massDeleteInfoRecords(data) {
      return this.init().post(`/master/multiple-delete-material-vendor`, data);
    }

    historyInfoRecords(id, params) {
      return this.init().get(`/master/material-vendor-history/${id}`, { params });
    }
    
    changeLogInfoRecords(id, params){
      return this.init().get(`/master/material-vendor-log/${id}`, {params});
    }

    //budget
    getMasterBudgetList(params) {
      return this.init().get('/master/budget-list', { params });
    } 

    getMasterBudget = (params) => {
      return this.init().get('/master/budget', { params });
    } 

    getCommitmentItem = (params) => {
      return this.init().get('/master/budget', { status: 1, ...params });
    }

    viewBudget(id) {
      return this.init().get(`/master/budget/${id}`);
    }

    addBudget(params) {
      return this.init().post('/master/budget', params);
    }

    updateBudget(id, data) {
      return this.init().put(`/master/budget/${id}`, data);
    }

    deleteBudget(id) {
      return this.init().delete(`/master/budget/${id}`);
    }

    submitBudget(id, data) {
      return this.init().post(`/master/budget-submit/${id}`, data);
    }

    approveBudget(id, data) {
      return this.init().post(`/master/budget-approve/${id}`, data);
    }

    rejectBudget(id, data) {
      return this.init().post(`/master/budget-reject/${id}`, data);
    }

    releaseBudget(id, data) {
      return this.init().post(`/master/budget-release/${id}`, data);
    }

    transferBudget(data) {
      return this.init().post(`/master/budget-transfer`, data);
    }
    
    changeLogBudget(id, params){
      return this.init().get(`/master/budget-log/${id}`, {params});
    }

    // Add from Upload ====
    downloadBudgetTemplate(params) {
      return this.init().get('/master/budget-template', {responseType: 'blob'});
    }

    uploadBudget(data){
      //create form data for uploading file
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return this.init().post('/master/budget-upload', formData);      
    }

    processValidUploadBudget() {
      return this.init().post('/master/budget-valid-upload');
    }

    getValidDataBudget() {
      return this.init().get('/master/budget-valid-data');
    }

    // Budget Adjustment from Upload ====
    downloadBudgetAdjustmentTemplate(params) {
      return this.init().get('/master/budget-adjustment-template', {responseType: 'blob'});
    }

    uploadBudgetAdjustment(data){
      //create form data for uploading file
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return this.init().post('/master/budget-adjustment-upload', formData);      
    }

    processValidUploadBudgetAdjustment() {
      return this.init().post('/master/budget-adjustment-valid-upload');
    }

    deleteValidUploadBudgetAdjustment() {
      return this.init().delete('/master/budget-adjustment-valid');
    }

    getValidDataBudgetAdjustment() {
      return this.init().get('/master/budget-adjustment-valid-data');
    }

    submitMassBudget(data) {
      return this.init().post(`/master/budget-submit-bulk`, data);
    }

    approveMassBudget(data) {
      return this.init().post(`/master/budget-approve-bulk`, data);
    }

    rejectMassBudget(data) {
      return this.init().post(`/master/budget-reject-bulk`, data);
    }

    releaseMassBudget(data) {
      return this.init().post(`/master/budget-release-bulk`, data);
    }

    //bill-of-material
    getMasterBillOfMaterial(params) {
      return this.init().get('/master/bill-of-material', { params });
    }

    addBillOfMaterial(data) {
      return this.init().post('/master/bill-of-material', data);
    }

    viewBillOfMaterial(id) {
      return this.init().get(`/master/bill-of-material/${id}`);
    }

    updateBillOfMaterial(id, data) {
      return this.init().put(`/master/bill-of-material/${id}`, data);
    }

    deleteBillOfMaterial(id) {
      return this.init().delete(`/master/bill-of-material/${id}`);
    }

    massDeleteBillOfMaterial(data) {
      return this.init().post(`/master/multiple-delete-bill-of-material`, data);
    }
    //bill-of-material-detail
    addBillOfMaterialDetail(id, data) {
      return this.init().post(`/master/bill-of-material/${id}/detail`, data);
    }

    updateBillOfMaterialDetail(id, data) {
      return this.init().put(`/master/bill-of-material/${id}/detail`, data);
    }

    deleteBillOfMaterialDetail(id) {
      return this.init().delete(`/master/bill-of-material/${id}/detail`);
    }

    massDeleteBillOfMaterialDetail(data) {
      return this.init().post(`/master/multiple-delete-bill-of-material-detail`, data);
    }

    //global-setting
    getMasterGlobalSetting = (params)=>{
      return this.init().get('/master/setting', { params });
    }

    viewGlobalSetting(key) {
      return this.init().get(`/master/setting/${key}`);
    }

    updateGlobalSetting(key, data) {
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return this.init().post(`/master/setting/${key}`, formData);
    }
    
    changeLogGlobalSetting(id, params){
      return this.init().get(`/master/setting-log/${id}`, {params});
    }

    //stock-opname
    getMasterStockOpname(params) {
      return this.init().get('/threepl/stock-opname', { params });
    }

    getStockOpnameDetail(code, params) {
      return this.init().get(`/threepl/stock-opname/detail/${code}`, { params });
    }

    approvalStockOpname(code,data) {
      return this.init().post(`/threepl/stock-opname/approve/${code}`, data);
    }

    //production order report
    getProductionOrder(params) {
      return this.init().get('/threepl/production-order', { params });
    }

    getResourceRequirement(params) {
      return this.init().get('/threepl/production-order/resource-requirement', { params });
    }

    // Production Order Schedule ================================================================
    // List Data / Calendar / Ganttchart ====
    getProductionOrderSchedule(params) {
      return this.init().get('/threepl/production-order/schedule', { params });
    }

    confirmProductionSchedule(data) {
      return this.init().post('/threepl/production-order/confirm-schedule', data);
    }

    // Add from Upload ====
    downloadProductionOrderTemplate(params) {
      return this.init().get('/threepl/production-order/template', {responseType: 'blob'});
    }

    uploadProductionOrderSchedule(data){
      //create form data for uploading file
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return this.init().post('/threepl/production-order/upload', formData);      
    }

    processValidUploadProductionSchedule() {
      return this.init().post('/threepl/production-order/upload-valid-data');
    }

    getValidDataProductionSchedule() {
      return this.init().get('/threepl/production-order/valid-data');
    }
    // end of - Production Order Schedule ======================================================

    //release-object
    getMasterReleaseObjectList = (params) => {
      return this.init().get('/master/release-object-list', {params});
    }

    getMasterReleaseObject = (params) => {
      return this.init().get('/master/release-object', {params});
    }

    addReleaseObject(data) {
      return this.init().post('/master/release-object', data);
    }

    viewReleaseObject(id) {
      return this.init().get(`/master/release-object/${id}`);
    }

    updateReleaseObject(id, data) {
      return this.init().put(`/master/release-object/${id}`, data);
    }

    deleteReleaseObject(id) {
      return this.init().delete(`/master/release-object/${id}`);
    }

    massDeleteReleaseObject(data) {
      return this.init().post(`/master/multiple-delete-release-object`, data);
    }
    
    changeLogReleaseObject(id, params){
      return this.init().get(`/master/release-object-log/${id}`, {params});
    }

    //release-group
    getMasterReleaseGroupList = (params) => {
      return this.init().get('/master/release-group-list', {params});
    }

    getMasterReleaseGroup = (params) => {
      return this.init().get('/master/release-group', {params});
    }

    addReleaseGroup(data) {
      return this.init().post('/master/release-group', data);
    }

    viewReleaseGroup(id) {
      return this.init().get(`/master/release-group/${id}`);
    }

    updateReleaseGroup(id, data) {
      return this.init().put(`/master/release-group/${id}`, data);
    }

    deleteReleaseGroup(id) {
      return this.init().delete(`/master/release-group/${id}`);
    }

    massDeleteReleaseGroup(data) {
      return this.init().post(`/master/multiple-delete-release-group`, data);
    }

    getMasterClassificationForWAOnly = (params) => {
      return this.init().get(`/master/classification-for-workflow`, { params });
    }
    
    changeLogReleaseGroup(id, params){
      return this.init().get(`/master/release-group-log/${id}`, {params});
    }

    //release-code
    getMasterReleaseCodeList = (params) => {
      return this.init().get('/master/release-code-list', {params});
    }

    getMasterReleaseCode = (params) => {
      return this.init().get('/master/release-code', {params});
    }

    addReleaseCode(data) {
      return this.init().post('/master/release-code', data);
    }

    viewReleaseCode(id) {
      return this.init().get(`/master/release-code/${id}`);
    }

    updateReleaseCode(id, data) {
      return this.init().put(`/master/release-code/${id}`, data);
    }

    deleteReleaseCode(id) {
      return this.init().delete(`/master/release-code/${id}`);
    }

    massDeleteReleaseCode(data) {
      return this.init().post(`/master/multiple-delete-release-code`, data);
    }
    
    changeLogReleaseCode(id, params){
      return this.init().get(`/master/release-code-log/${id}`, {params});
    }

    //release-strategy
    getMasterReleaseStrategyList = (params) => {
      return this.init().get('/master/release-strategy-list', {params});
    }

    getMasterReleaseStrategy = (params) => {
      return this.init().get('/master/release-strategy', {params});
    }

    addReleaseStrategy(data) {
      return this.init().post('/master/release-strategy', data);
    }

    viewReleaseStrategy(id) {
      return this.init().get(`/master/release-strategy/${id}`);
    }

    updateReleaseStrategy(id, data) {
      return this.init().put(`/master/release-strategy/${id}`, data);
    }

    deleteReleaseStrategy(id) {
      return this.init().delete(`/master/release-strategy/${id}`);
    }

    massDeleteReleaseStrategy(data) {
      return this.init().post(`/master/multiple-delete-release-strategy`, data);
    }
    
    changeLogReleaseStrategy(id, params){
      return this.init().get(`/master/release-strategy-log/${id}`, {params});
    }

    //assign code
    assignReleaseCode(id, data) {
      return this.init().post(`/master/release-strategy/${id}/assign-code`, data);
    }

    //add code
    addignReleaseCode(id, data) {
      return this.init().post(`/master/release-strategy/${id}/add-release-code`, data);
    }

    //delete code
    deleteignReleaseCode(id, data) {
      return this.init().post(`/master/release-strategy/${id}/delete-release-code`, data);
    }

    //get classification parameter workflow
    getWorkflowApprovalParameter(){
      return this.init().get(`/master/classification-for-workflow`);
    }

    //add classification
    releaseStrategyClassification(id, data) {
      return this.init().post(`/master/release-strategy/${id}/classification`, data);
    } 

    //view release status
    getReleaseStatus(id) {
      return this.init().get(`/master/release-strategy/${id}/status`);
    }

    //add status
    releaseStatus(id, data) {
      return this.init().post(`/master/release-strategy/${id}/status`, data);
    }   

    // activate
    activateReleaseStrategy(id) {
      return this.init().get(`/master/release-strategy-activate/${id}`);
    }

    // deactivate
    deactivateReleaseStrategy(id) {
      return this.init().get(`/master/release-strategy-deactivate/${id}`);
    }

    //release-code-user
    getMasterReleaseCodeUserList = (params) => {
      return this.init().get('/master/release-code-user-list', {params});
    }

    getMasterReleaseCodeUser = (params) => {
      return this.init().get('/master/release-code-user', {params});
    }

    addReleaseCodeUser(data) {
      return this.init().post('/master/release-code-user', data);
    }

    viewReleaseCodeUser(id) {
      return this.init().get(`/master/release-code-user/${id}`);
    }

    updateReleaseCodeUser(id, data) {
      return this.init().put(`/master/release-code-user/${id}`, data);
    }

    deleteReleaseCodeUser(id) {
      return this.init().delete(`/master/release-code-user/${id}`);
    }

    massDeleteReleaseCodeUser(data) {
      return this.init().post(`/master/multiple-delete-release-code-user`, data);
    }
    
    changeLogReleaseCodeUser(id, params){
      return this.init().get(`/master/release-code-user-log/${id}`, {params});
    }
    
    //depreciation group
    getMasterDepreciationGroupList(params) {
      return this.init().get('/master/depreciation-group-list', {params});
    }

    getMasterDepreciationGroup(params) {
      return this.init().get('/master/depreciation-group', {params});
    }

    addDepreciationGroup(data) {
      return this.init().post('/master/depreciation-group', data);
    }

    viewDepreciationGroup(id) {
      return this.init().get(`/master/depreciation-group/${id}`);
    }

    updateDepreciationGroup(id, data) {
      return this.init().put(`/master/depreciation-group/${id}`, data);
    }

    deleteDepreciationGroup(id) {
      return this.init().delete(`/master/depreciation-group/${id}`);
    }

    massDeleteDepreciationGroup(data) {
      return this.init().post(`/master/multiple-delete-depreciation-group`, data);
    }
    
    changeLogDepreciationGroup(id, params){
      return this.init().get(`/master/depreciation-group-log/${id}`, {params});
    }

    //depreciation type
    getMasterDepreciationTypeList(params) {
      return this.init().get('/master/depreciation-type-list', {params});
    }

    getMasterDepreciationType(params) {
      return this.init().get('/master/depreciation-type', {params});
    }

    getDoubleDecline = (params) => {
      return this.init().get('/master/depreciation-select/doubledecline', { params });
    }

    getStraightLine = (params) => {
      return this.init().get('/master/depreciation-select/straightline', { params });
    }

    addDepreciationType(data) {
      return this.init().post('/master/depreciation-type', data);
    }

    viewDepreciationType(id) {
      return this.init().get(`/master/depreciation-type/${id}`);
    }

    updateDepreciationType(id, data) {
      return this.init().put(`/master/depreciation-type/${id}`, data);
    }

    deleteDepreciationType(id) {
      return this.init().delete(`/master/depreciation-type/${id}`);
    }

    massDeleteDepreciationType(data) {
      return this.init().post(`/master/multiple-delete-depreciation-type`, data);
    }
    
    changeLogDepreciationType(id, params){
      return this.init().get(`/master/depreciation-type-log/${id}`, {params});
    }

    //depreciation-period
    addDepreciationPeriod(id,data) {
      return this.init().post(`/master/depreciation-type/${id}/addparam`, data);
    }

    viewDepreciationPeriod(id) {
      return this.init().get(`/master/depreciation-period/${id}`);
    }

    updateDepreciationPeriod(id, data) {
      return this.init().put(`/master/depreciation-period/${id}`, data);
    }

    deleteDepreciationPeriod(id) {
      return this.init().delete(`/master/depreciation-period/${id}`);
    }
    
    //asset valuation
    getAssetValuationList(params) {
      return this.init().get('/master/valuation-asset-list', {params});
    }

    // getAssetValuation(params) {
    //   return this.init().get('/master/valuation-asset', {params});
    // }

    getAssetValuation = (params) => {
      return this.init().get('/master/valuation-asset', { params });
    }

    addAssetValuation(data) {
      return this.init().post('/master/valuation-asset', data);
    }

    viewAssetValuation(id) {
      return this.init().get(`/master/valuation-asset/${id}`);
    }

    updateAssetValuation(id, data) {
      return this.init().put(`/master/valuation-asset/${id}`, data);
    }

    deleteAssetValuation(id) {
      return this.init().delete(`/master/valuation-asset/${id}`);
    }

    massDeleteAssetValuation(data) {
      return this.init().post(`/master/multiple-delete-valuation-asset`, data);
    }
    
    changeLogAssetValuation(id, params){
      return this.init().get(`/master/valuation-asset-log/${id}`, {params});
    }
    
    //Retired Reason
    getRetiredReasonList(params) {
      return this.init().get('/master/retired-reason-list', {params});
    }

    // getRetiredReason(params) {
    //   return this.init().get('/master/retired-reason', {params});
    // }

    getRetiredReason = (params) => {
      return this.init().get('/master/retired-reason', { params });
    }

    addRetiredReason(data) {
      return this.init().post('/master/retired-reason', data);
    }

    viewRetiredReason(id) {
      return this.init().get(`/master/retired-reason/${id}`);
    }

    updateRetiredReason(id, data) {
      return this.init().put(`/master/retired-reason/${id}`, data);
    }

    deleteRetiredReason(id) {
      return this.init().delete(`/master/retired-reason/${id}`);
    }

    massDeleteRetiredReason(data) {
      return this.init().post(`/master/multiple-delete-retired-reason`, data);
    }
    
    changeLogRetiredReason(id, params){
      return this.init().get(`/master/retired-reason-log/${id}`, {params});
    }
    
    //Asset Type
    getAssetTypeList(params) {
      return this.init().get('/master/asset-type-list', {params});
    }

    // getAssetType(params) {
    //   return this.init().get('/master/asset-type', {params});
    // }

    getAssetType = (params) => {
      return this.init().get('/master/asset-type', { params });
    }

    addAssetType(data) {
      return this.init().post('/master/asset-type', data);
    }

    viewAssetType(id) {
      return this.init().get(`/master/asset-type/${id}`);
    }

    updateAssetType(id, data) {
      return this.init().put(`/master/asset-type/${id}`, data);
    }

    deleteAssetType(id) {
      return this.init().delete(`/master/asset-type/${id}`);
    }

    massDeleteAssetType(data) {
      return this.init().post(`/master/multiple-delete-asset-type`, data);
    }
    
    changeLogAssetType(id, params){
      return this.init().get(`/master/asset-type-log/${id}`, {params});
    }
    
    //Asset Status
    getAssetStatusList(params) {
      return this.init().get('/master/asset-status-list', {params});
    }

    // getAssetStatus(params) {
    //   return this.init().get('/master/asset-status', {params});
    // }

    getAssetStatus = (params) => {
      return this.init().get('/master/asset-status', { params });
    }

    getAssetStatusNormal = (params) => {
      return this.init().get('/master/asset-status-normal', { params });
    }

    addAssetStatus(data) {
      return this.init().post('/master/asset-status', data);
    }

    viewAssetStatus(id) {
      return this.init().get(`/master/asset-status/${id}`);
    }

    updateAssetStatus(id, data) {
      return this.init().put(`/master/asset-status/${id}`, data);
    }

    deleteAssetStatus(id) {
      return this.init().delete(`/master/asset-status/${id}`);
    }

    massDeleteAssetStatus(data) {
      return this.init().post(`/master/multiple-delete-asset-status`, data);
    }
    
    changeLogAssetStatus(id, params){
      return this.init().get(`/master/asset-status-log/${id}`, {params});
    }
    
    //Master Asset
    getAssetsList(params) {
      return this.init().get('/master/asset-list', {params});
    }

    getAssetsReport(params) {
      return this.init().get('/master/asset-report', {params});
    }

    getAssets = (params) => {
      return this.init().get('/master/asset', { params });
    }

    getOldNumberAssets = (params) => {
      return this.init().get('/master/asset-old-number', { params });
    }

    addAssets(data) {
      return this.init().post('/master/asset', data);
    }

    viewAssets(id) {
      return this.init().get(`/master/asset/${id}`);
    }

    updateAssets(id, data) {
      return this.init().put(`/master/asset/${id}`, data);
    }

    deleteAssets(id) {
      return this.init().delete(`/master/asset/${id}`);
    }

    assetResponsibleHistory(id, params) {
      return this.init().get(`/master/asset-responsible-person-history-pagination/${id}`, { params });
    }

    assetLocationHistory(id, params) {
      return this.init().get(`/master/asset-location-history-pagination/${id}`, { params });
    }

    assetRequestForRetired(id, data) {
      return this.init().post(`/master/asset-request-for-retired/${id}`, data);
    }

    assetRetiredApproval(id, status) {
      return this.init().post(`/master/asset-status-approval/${id}/${status}`);
    }

    massDeleteAssets(data) {
      return this.init().post(`/master/multiple-delete-asset`, data);
    }

    getAssetImage(id) {
      return this.init().get(`/master/asset/${id}/image-list`);
    }

    addAssetImage(id, data) {
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return this.init().post(`/master/asset/${id}/add-image`, formData);
    }

    deleteAssetImage(id) {
      return this.init().delete(`/master/asset-image/${id}`);
    }

    getAssetImage360(id) {
      return this.init().get(`/master/asset/${id}/image-360-list`);
    }

    addAssetImage360(id, data) {
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return this.init().post(`/master/asset/${id}/add-image-360`, formData);
    }

    deleteAssetImage360(id) {
      return this.init().delete(`/master/asset-image-360/${id}`);
    }

    updateAssetClassification(id, data) {
      return this.init().post(`/master/asset/${id}/store-classification-parameters`, data);
    }

    getClassificationAssetParameter(id) {
      return this.init().get(`/master/classification-parameters-by-material/${id}`);
    }

    updateAssetLocation(id, data) {
      return this.init().put(`/master/asset/${id}/move-location`, data);
    }

    updateAssetResponsible(id, data) {
      return this.init().put(`/master/asset/${id}/move-responsible-person`, data);
    }

    viewAssetStraightLine(id) {
      return this.init().get(`/master/asset-straightline/${id}`);
    }

    viewAssetDoubleDecline(id) {
      return this.init().get(`/master/asset-doubledecline/${id}`);
    }    

    viewAssetStraightLine2(id) {
      return this.init().get(`/master/show-asset-depresiasi-sl/${id}`);
    }

    viewAssetDoubleDecline2(id) {
      return this.init().get(`/master/show-asset-depresiasi-dd/${id}`);
    }    

    downloadPDFAsset = (params) => {
      return this.init().get(`/master/asset-barcode`, {params, responseType: 'blob'});
    }

    downloadAssetTemplate(params) {
      return this.init().get('/master/asset-template', {responseType: 'blob'});
    }

    uploadAsset(data){
      //create form data for uploading file
      let formData = new FormData();
      for(let key in data){
        formData.append(key, data[key])
      }
      return this.init().post('/master/asset-upload', formData);      
    }

    downloadFailedData(params) {
      return this.init().get('/master/asset-invalid-download', {responseType: 'blob'});
    }

    deleteFailedData(params) {
      return this.init().delete(`/master/asset-invalid`);
    }

    getInvalidDataAsset() {
      return this.init().get('/master/asset-invalid-data');
    }

    exportAsset = (params) => {
      return this.init().get('/master/asset-export', {params, responseType: 'blob'});
    }
    
    getAssetNumber = (params) => {
      return this.init().get('/master/asset-number', { params });
    }

    RequestActivation(data) {
      return this.init().post(`/master/asset-request-for-activation`, data);
    }

    ActivationAsset(status, data) {
      return this.init().post(`/master/asset-status-activation/${status}`, data);
    }
    
    changeLogAsset(id, params){
      return this.init().get(`/master/asset-log/${id}`, {params});
    }

    /**
 * =====================================================
 * Work Order
 * ======================================================
 */

  // Task List
  getTaskList = (params) => {
    return this.init().get('/work-order/task-list', { params });
  }

  getTaskListHash = (params) => {
    return this.init().get('/work-order/task-list-hash', { params });
  }

  addTaskList(data) {
    return this.init().post('/work-order/task-list', data);
  }

  viewTaskList(id) {
    return this.init().get(`/work-order/task-list/${id}`);
  }

  updateTaskList(id, data) {
    return this.init().put(`/work-order/task-list/${id}`, data);
  }

  deleteTaskList(id) {
    return this.init().delete(`/work-order/task-list/${id}`);
  }

  massDeleteTaskList(data) {
    return this.init().post(`/work-order/multiple-delete-task-list`, data);
  }

  getMaterialByClassification = (id, params)=>{
    return this.init().get('/master/material', {params: {...params, classification:id}});
  }

  getArRegion = (params) => {
    return this.init().get('/master/plant', { params });
  }

  getTaskListItem(id, params) {
    return this.init().get(`/work-order/task-list-item-hash-by-tasklist-id/${id}`, {params});
  }

  addTaskListItem(id, data) {
    return this.init().post(`/work-order/task-list/${id}/store-item`, data);
  }

  viewTaskItem(id) {
    return this.init().get(`/work-order/task-list-item/${id}`);
  }

  updateTaskItem(id, data) {
    return this.init().put(`/work-order/task-list/${id}/update-item`, data);
  }

  deleteTaskItem(id) {
    return this.init().delete(`/work-order/task-list-item/${id}`);
  }
    
  changeLogTaskList(id, params){
    return this.init().get(`/work-order/task-list-log/${id}`, {params});
  }

  //Service Request
  getServiceRequestList = (params)=>{
    return this.init().get('/work-order/service-request-hash', { params });
  }

  getServiceRequest = (params)=>{
    return this.init().get('//work-order/service-request', { params });
  }

  addServiceRequest(data) {
    return this.init().post('/work-order/service-request', data);
  }

  viewServiceRequest(id) {
    return this.init().get(`/work-order/service-request/${id}`);
  }

  updateServiceRequest(id, data) {
    return this.init().put(`/work-order/service-request/${id}`, data);
  }

  deleteServiceRequest(id) {
    return this.init().delete(`/work-order/service-request/${id}`);
  }

  massDeleteServiceRequest(data) {
    return this.init().post(`/work-order/multiple-delete-service-request`, data);
  }

  approvalSR(id, data) {
    return this.init().post(`/work-order/approve-service-request/${id}`, data);
  }
    
  changeLogServiceRequest(id, params){
    return this.init().get(`/work-order/service-request-log/${id}`, {params});
  }

  // Work Order
  addWorkOrder(data) {
    return this.init().post('/work-order/corrective-maintenance', data);
  }

  viewWorkOrderCM(id) {
    return this.init().get(`/work-order/corrective-maintenance/${id}`);
  }

  updateWO(id, data) {
    return this.init().put(`/work-order/corrective-maintenance/${id}`, data);
  }

  getListWo = (params) =>{
    return this.init().get('/work-order/corrective-maintenance-hash', { params });
  }

  getWoStatus = (params) => {
    return this.init().get('/work-order/wo-status', { params });
  }
  
  getLevel = (params) => {
    return this.init().get('/work-order/wo-level', { params });
  }

  addTaskManualWO(id, data) {
    return this.init().post(`/work-order/add-task-manual/${id}`, data);
  }

  getWoTask(id, params) {
    return this.init().get(`/work-order/wo-task-hash/${id}`, { params });
  }

  updateTaskWO(id, data) {
    return this.init().put(`/work-order/wo-task/${id}`, data);
  }

  viewWOTask(id) {
    return this.init().get(`/work-order/show-wo-task/${id}`);
  }

  importWoTask(id, data) {
    return this.init().post(`/work-order/import-task/${id}`, data);
  }

  deleteWoTask(id) {
    return this.init().delete(`/work-order/wo-task/${id}`);
  }

  approvalWO(id, data) {
    return this.init().post(`/work-order/approve-work-order/${id}`, data);
  }
    
  changeLogCM(id, params){
    return this.init().get(`/work-order/corrective-maintenance-log/${id}`, {params});
  }

  // Incoming Invoice
  getIncomingInvoice = (params) => {
    return this.init().get('/accounting/incoming-invoice', { params });
  }

  getPOGRByVendor = (id, params) => {
    return this.init().get(`/accounting/incoming-invoice/po-gr-by-vendor/${id}`, { params });
  }

  addInvoice = (data) => {
    let formData = new FormData();
    for(let key in data){
      if(key == 'item'){

        data[key].forEach((item, idx)=>{

          formData.append(`${key}[${idx}][gr_id]`, item.gr_id)
        })
      }else if(key == 'attachments'){

        data[key].forEach((item, idx)=>{

          formData.append(`${key}[${idx}]`, item)
        })
      }else{

        formData.append(key, data[key])
      }
    }

    return this.init().post(`/accounting/incoming-invoice/save`, formData);
  }

  viewInvoice(id) {
    return this.init().get(`/accounting/incoming-invoice/${id}`);
  }

  submitInvoice(id, data) {
    return this.init().post(`/accounting/incoming-invoice/${id}/submit`, data);
  }

  paidInvoice(id, data) {
    return this.init().post(`/accounting/incoming-invoice/${id}/paid`, data);
  }

  approveInvoice(id, data) {
    return this.init().post(`/accounting/incoming-invoice/${id}/approve`, data);
  }

  rejectInvoice(id, data) {
    return this.init().post(`/accounting/incoming-invoice/${id}/reject`, data);
  }

  viewInvoiceItemDetail(id) {
    return this.init().get(`/accounting/incoming-invoice/show-detail/${id}`);
  }

  getIncomingInvoiceReport = (params) => {
    return this.init().get('/accounting/incoming-invoice/payment/reportAp', { params });
  }

  downloadAPReport = (params)=>{
    return this.init().get(`/accounting/incoming-invoice/payment/print-report`, {params, responseType: 'blob'});
  }

  addConditionInvoiceDetail(id, data) {
    return this.init().post(`accounting/incoming-invoice/add-condition/${id}`, data);
  }

  editConditionInvoiceDetail(id, data) {
    return this.init().put(`accounting/incoming-invoice/edit-condition/${id}`, data);
  }

  deleteConditionInvoiceDetail(id) {
    return this.init().delete(`accounting/incoming-invoice/delete-condition/${id}`);
  }

  editDetailItemInvoice(id, data) {
    return this.init().put(`accounting/incoming-invoice/edit-item-detail/${id}`, data);
  }

  downloadAgreementForm = (id)=>{
    return this.init().get(`/accounting/incoming-invoice/form-persetujuan/${id}`, {responseType: 'blob'});
  }

  getDocumentTypeInvoice = (params) => {
    return this.init().get('/accounting/document-type', { params });
  }

  getGroupType = (params) => {
    return this.init().get('/master/group-type', { params });
  }

  getAccountType = (params) => {
    return this.init().get('/master/account-type', { params });
  }

  getReconAccount = (params) => {
    return this.init().get('/master/gl-account-type-vendor', { params });
  }

  getSubType = (params) => {
    return this.init().get('/accounting/sub-type', { params });
  }

  getTransactionType = (params) => {
    return this.init().get('/accounting/transaction-type', { params });
  }

  getValuationClassList = (params) => {
    return this.init().get('/master/valuation-class', { params });
  }

  //DASHBOARD =============================================================

  getDashboardTotalReservation = (params) => {
    return this.init().get('/dashboard/reservation', { params });
  }

  getDashboardTotalPR = (params) => {
    return this.init().get('/dashboard/pr', { params });
  }

  getDashboardTotalPickingList = (params) => {
    return this.init().get('/dashboard/picking-list', { params });
  }

  getDashboardTotalPO = (params) => {
    return this.init().get('/dashboard/po', { params });
  }

  getDashboardTotalRFQ = (params) => {
    return this.init().get('/dashboard/rfq', { params });
  }

  // purchase document history
  getPurchaseStatusReport(params) {
    return this.init().get('/threepl/purchase-status-report', {params});
  }

  //journal-depreciation
  getJournalDepreciation(params) {
    return this.init().get('/master/depreciation-journal', {params});
  }

  downloadJournalDepreciation = (params)=>{
    return this.init().get(`/master/depreciation-journal-download`, {params, responseType: 'blob'});
  }
  
  downloadJournalDepreciationTxt = (params)=>{
    return this.init().get(`/master/depreciation-journal-downloadtxt`, {params, responseType: 'blob'});
  }

  //accounting-condition
  getMasterAccountingConditionList(params) {
    return this.init().get('/accounting/condition-master-hash', {params});
  }

  getMasterAccountingCondition=(params)=> {
    return this.init().get('/accounting/condition-master', {params});
  }

  addAccountingCondition(data) {
    return this.init().post('/accounting/condition-master', data);
  }

  viewAccountingCondition(id) {
    return this.init().get(`/accounting/condition-master/${id}`);
  }

  updateAccountingCondition(id, data) {
    return this.init().put(`/accounting/condition-master/${id}`, data);
  }

  deleteAccountingCondition(id) {
    return this.init().delete(`/accounting/condition-master/${id}`);
  }

  massDeleteAccountingCondition(data) {
    return this.init().post(`/accounting/multiple-delete-condition-master`, data);
  }
  
  changeLogAccountingCondition(id, params){
    return this.init().get(`/accounting/condition-master-log/${id}`, {params});
  }

}
