import React from 'react'
import { Row, Col } from 'antd';
import { Form, Icon, Input, Button, Checkbox } from 'antd';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { setUser } from '@/actions/user';
import { setAuthObject } from 'actions/authobject';
import Api from '@/helpers/api';
import './Login.css';
// import '@/assets/styles/red.css';
import bgImage from '@/assets/img/background.jpg'
import izora_powered from '@/assets/img/izora-powered.png'

const FormItem = Form.Item;
const api = new Api();

class NormalLoginForm extends React.Component {
  constructor(){
    super()
    this.state = {
      email:'',
      password:'',
      errors:{}, 
      bgImage: null,
      primary_color: null
    }
  }

  componentDidMount(){
    const { dispatch } = this.props
    let user = JSON.parse(localStorage.getItem('user'));
    dispatch(setUser(user));

    if(user){
      this.props.history.push('/app');
    }

    this.getTheme()
    this.getImage()
  }

  getTheme = () => {
    
    api.getLoginTheme()
      .then(response=>{
        console.log('theme', JSON.parse(response.data.value));
        let value = JSON.parse(response.data.value)
        this.setState({primary_color: value ? value.primary_color : null})
      })
      .catch(error=>{
        // this.setState({ loading: false });
      })
  }

  getImage = () => {
    
    api.getImageTheme()
      .then(response=>{
        console.log('image', response.data);
        this.setState({bgImageLogin:response.data.value})
      })
      .catch(error=>{
        // this.setState({ loading: false });
      })
  }

    handleSubmit = (e) => {
      const { email, password, } = this.state
      const { dispatch, history } = this.props

      e.preventDefault();
      this.props.form.validateFields((err, values) => {
        if (!err) {
          // console.log('Received values of form: ', values);
          this.setState({loading:true})

          api.login(email,password)
            .then(response=>{
              this.setState({loading:false})
              // console.log(response.data);
              if(response.status === 200){ //OK
                try{
                  let user = response.data
                  let token = user.token
                  // Remove Token
                  delete user.token
                  dispatch(setUser(user));
                  localStorage.setItem('user',JSON.stringify(user));
                  localStorage.setItem('api_token', token);

                  history.push('/dashboard')
                  // this.getAuthObject()
                }catch(error){
                  // console.log('Error save user data',error)
                }
              }
            })
            .catch(error=>{
              this.setState({loading:false})
              // console.log(error);
              if(error.response && error.response.data){
                const { data } = error.response
                this.setState({errors:data})
              }
            });

        }
      });
    }

    getAuthObject = ()=>{
      const { dispatch, history } = this.props
  
      //get data if not exist in redux 
      api.getAuthObjectList()
        .then(response=>{
          // console.log(response.data);
          const { data } = response
          if(response.status === 200){ //OK
            dispatch(setAuthObject(data))
            history.push('/dashboard')
          }
        })
        .catch(error=>{
          // console.log(error.response)
        })
    }

    render() {
      const { getFieldDecorator } = this.props.form;
      const { email, password, bgImageLogin, primary_color } = this.state;
      return (
        <div className="wrapper" style={{
          backgroundImage:`url(${bgImageLogin ? bgImageLogin : bgImage})`,
          backgroundSize:' cover',
          // backgroundPosition: 'right top',
          // backgroundPosition: '100% 20%',
        }}>
          <div className="login-form-wrapper">
            <Row>
              <Col md={{span:9}}>
                <div  className="login-form-container">
                  
                  <h1 className="text-head">Welcome</h1>
                  <p className="text-about">
                    IZORA is a very cool platform that combines multiple tools. <br/>
                    All of them are very cool and this is why:
                  </p>   

                  <p className="text-poin"><Icon type="check-circle" theme="filled" style={{color:'#1ea73c'}}/> You can do really cool stuff</p>
                  <p className="text-poin"><Icon type="check-circle" theme="filled" style={{color:'#1ea73c'}}/> And even more cool stuff</p>
                  <p className="text-poin"><Icon type="check-circle" theme="filled" style={{color:'#1ea73c'}}/> You won't believe it!</p>

                  { this.state.errors.message ? <p style={{color:'red', marginTop:10, textAlign:'center'}}>{this.state.errors.message}</p> : null }

                  <Form onSubmit={this.handleSubmit} className="login-form">
                    <FormItem>
                      {getFieldDecorator('email', {
                        rules: [{ required: true, message: 'Please input your username or email!' }],
                        setFieldValue: email,
                      })(
                        <Input 
                          prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} 
                          placeholder="Enter your Username" 
                          onChange={e=>this.setState({email:e.target.value})}/>
                      )}
                    </FormItem>
                    <FormItem>
                      {getFieldDecorator('password', {
                        rules: [{ required: true, message: 'Please input your Password!' }],
                        setFieldValue: password,
                      })(
                        <Input.Password 
                          prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                          placeholder="Enter your Password" 
                          onChange={e=>this.setState({password:e.target.value})}/>
                      )}
                    </FormItem>
                    <FormItem>
                      {getFieldDecorator('remember', {
                        valuePropName: 'checked',
                        initialValue: true,
                      })(
                        <Checkbox>Remember me</Checkbox>
                      )}
                      <Link className="login-form-forgot" to="/forgot-password">Forgot password</Link>
                      <p></p>
                      <Button type="primary" style={{backgroundColor:primary_color ? primary_color : '#1890ff', borderColor:primary_color ? primary_color : '#1890ff'}} htmlType="submit" size="large" className="login-form-button" loading={this.state.loading}>
                        LOGIN TO YOUR ACCOUNT <Icon type="arrow-right" />
                      </Button>
                      {/* Or <a href="">register now!</a> */}
                    </FormItem>
                  </Form>

                  <p className="logo-powered">
                    <a href="http://mindaperdana.com/" target="blank">
                      <img src={izora_powered} height={50} alt=""/>
                    </a>
                  </p>

                </div>
              </Col>
            </Row>
          </div>
        </div>
      );
    }
}

const WrappedNormalLoginForm = Form.create()(NormalLoginForm);

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps)(WrappedNormalLoginForm);