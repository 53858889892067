// import { message } from 'antd'
import { setMessage } from '@/actions/auth'
import store from '@/store'
import { logoutUser } from '@/actions/user';

/**
 * error from catch axios
 * @param error : axios
 * @param props : page props, wee need to take { history } props
 */
const handleApiError = (error, props = {})=>{
  const { history } = props

  // console.log(error.response)
  if(error.response){
    const { data, status } = error.response
        
    //ant design show alert
    // message.error(data.message)

    switch(status){
    case 401:
      // message.error('Login expired, please relogin');
      store.dispatch(logoutUser());
      if(history)
        history.replace('/login');
      redirectToLogin();
      break;

    case 403:
      //set redux auth message & redirect to 403 page
      store.dispatch(setMessage(data.message))
      // store.dispatch(replace('/403'))
      if(history)
        history.replace('/403');
      break;
    case 400:
      // store.dispatch(replace('/400'))
      if(history)
        history.replace('/400');
      break;
                
    default:
      break;
    }
  }
}

const redirectToLogin = ()=>{
  let path = window.location.pathname
  if(path!='/#/login'){
    window.location.reload();                                                
  }

}

export default handleApiError;