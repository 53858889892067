let initialState = {
  count:null,
  q:null
}

const catalogitems = (state = initialState, action) => {
  switch (action.type) {
  case 'SET_CATALOG_ITEMS_COUNT':
    return {...state, count: action.count}
  case 'SET_CATALOG_ITEMS_SEARCH_TEXT':
    return {...state, q: action.q}
  case 'SET_CATALOG_ITEMS_GROUP_MATERIAL':
    return {...state, group_material_id: action.group_material_id}
  default:
    return state
  }
}

export default catalogitems;