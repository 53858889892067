/**
 * dashboard 
 */

let initialState = {
  dashboard: false,
}

const dashboard = (state = initialState, action) => {
  switch (action.type) {
  case 'SET_DASHBOARD':
    return {...state, dashboard: action.data}
  default:
    return state
  }
}

export default dashboard;