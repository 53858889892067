import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router'
import user from "./user";
import auth from "./auth";
import authobject from "./authobject";
import catalogitems from "./catalogitems";
import globalconfig from "./globalconfig";
import dashboard from "./dashboard";

export default (history)=> combineReducers({
  router: connectRouter(history),
  //all reducers
  user,
  auth,
  authobject,
  catalogitems,
  globalconfig,
  dashboard,
})