import store from '@/store'

const hasAuthObject = (object) => {
  //get state from redux store
  let state = store.getState()
  let authobject = state.authobject.data
  if(authobject==null){ authobject = [] }
  // console.log(authobject)

  //auth object
  const ao = authobject.find(i=>i.object===object)
    
  // if user have auth object
  if(ao){
    return true
  }else{
    return false
  }
}

export default hasAuthObject