import React from 'react';
import { Layout, Icon, Row, Col, Avatar, Dropdown, Menu } from 'antd';
import { connect } from 'react-redux';
import { Switch, Link } from "react-router-dom";
import { setUser, logoutUser } from 'actions/user';
import { setAuthObject } from 'actions/authobject';
import { setConfig } from '@/actions/globalconfig';
import 'handsontable/dist/handsontable.full.css';
import './App.css';
import menu from './menu';
import RouteWithSubRoutes from '@/components/RouteWithSubRoutes'
import toUpperFirstChar from '@/helpers/toUpperFirstChar'
import sortRoute from '@/helpers/sortRoute';
import Api from '@/helpers/api';
import config from 'config';
import handleApiError from 'helpers/handleApiError'
import hasAuthObject from 'helpers/hasAuthObject'

const { Header, Sider } = Layout;
const SubMenu = Menu.SubMenu

const api = new Api();

class Main extends React.Component {
  constructor(){
    super()
    this.state = {
      collapsed: true,
    }
    this.onUserMenuClick = this.onUserMenuClick.bind(this)
    this.logout = this.logout.bind(this)
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  setCollapse = (collapsed)=>{
    this.setState({collapsed});
  }

  componentDidMount(){
    const { dispatch } = this.props
    this.setState({collapsed:true})
    let user = JSON.parse(localStorage.getItem('user'));
    
    if(!user){
      this.props.history.push('/login');
    }

    // console.log(user)
    dispatch(setUser(user));

    //get auth objects user
    this.getAuthObject();

    //get profile for further user data, i.e avatar
    this.getProfile();

    this.getLogoAndTheme();
  }

  getProfile = ()=>{
    const { dispatch } = this.props
    api.getProfile()
      .then(response=>{
        // console.log(response.data)
        const { data } = response
        dispatch(setUser(data))
      })
      .catch(error=>{
        console.log(error)
        handleApiError(error, this.props)
      })
  }

  getAuthObject = ()=>{
    const { dispatch, authobject } = this.props
    // console.log('get authobject')
    if(authobject.length < 1){
      //get data if not exist in redux 
      api.getAuthObjectList()
        .then(response=>{
          // console.log(response.data);
          const { data } = response
          if(response.status === 200){ //OK
            dispatch(setAuthObject(data))
          }
        })
        .catch(error=>{
          handleApiError(error, this.props)
        })
    }
  }

  getLogoAndTheme = ()=>{
    this.getGlobalConfigAndSet('COMPANY_LOGO')
    this.getGlobalConfigAndSet('THEME')
  }

  getGlobalConfigAndSet = (key) => {
    const { dispatch } = this.props

    api.viewGlobalSetting(key)
      .then(response=>{
      // console.log(response.data)
        const { data } = response
        dispatch(setConfig(data.key, data.value))
      })
      .catch(error=>{
        console.log(error)
        handleApiError(error, this.props)
      })
  }

  onUserMenuClick = function ({ key }) {
    switch(key){
    case 'account-settings':
      this.props.history.push('/account-settings');   
      break
    case 'logout':
      this.logout();
      break
    default:
      break
    }
  };

  render() {
    const { user, globalconfig } = this.props
    const { collapsed } = this.state
    const curUrl = this.props.location.pathname
    const pathname = curUrl.replace('/','')
    const paths = pathname.split('/')

    let theme = globalconfig.THEME ? JSON.parse(globalconfig.THEME) : null

    const userMenu = (
      <Menu onClick={this.onUserMenuClick}>
        <Menu.Item key="account-settings">
          <Icon type='setting' />Account Settings
        </Menu.Item>
        <Menu.Divider/>
        <Menu.Item key="logout">
          <Icon type="logout" />Logout
        </Menu.Item>
      </Menu>
    );

    return (
      <Layout>
        <Sider 
          theme={config.sidebarTheme}
          style={{ overflow: 'auto', 
            height: '100vh', 
            position: 'fixed', 
            left: 0, 
            backgroundImage: theme ? `linear-gradient(${theme.primary_color}, ${theme.secondary_color})` : `linear-gradient(#fff, #fff)`
          }}
          width={256}
          trigger={null}
          collapsible
          collapsed={collapsed}
          // breakpoint="lg"
          //collapsedWidth="0"
          //onBreakpoint={(broken) => { console.log(broken); }}
          onCollapse={(collapsed) => { 
            this.setCollapse(collapsed) 
          }}
        >
          {/* <img src={require('@/assets/img/inventiry-text.png')} className={ this.state.collapsed ? `logo-collapsed` : `logo`} alt="Logo" style={{width:'150px', height:'auto'}}/>     */}
          {/* <img src={ this.state.collapsed ? require('@/assets/img/inventory.png') : require('@/assets/img/inventiry-text.png')} className={`logo`} alt="Logo" style={{width:'auto', height:'25px'}}/>     */}
          <Menu 
            theme={config.sidebarTheme} 
            style={{backgroundColor:'transparent'}}
            mode="inline" 
            defaultSelectedKeys={['dashboard']} 
            selectedKeys={paths}>
            { menu.map((item)=>{
              //if route doesnt have submenu
              if(!item.submenu){
                if(item.authobject && hasAuthObject(item.authobject)){
                  return (
                    <Menu.Item key={item.key}>
                      <a href={`#${item.path}`}>
                        <Icon type={item.icon} style={{fontSize:19}}/><span>{toUpperFirstChar(item.name)}</span>
                      </a>
                    </Menu.Item>
                  )
                }else if(!item.authobject){
                  return (
                    <Menu.Item key={item.key}>
                      <a href={`#${item.path}`}>
                        <Icon type={item.icon} style={{fontSize:19}}/><span>{toUpperFirstChar(item.name)}</span>
                      </a>
                    </Menu.Item>
                  )
                }
                return null
              }

              //if route has sub routes
              else if(item.submenu){
                //hide parent if user doesnt have any auth object in sub
                let hasAnyAuthObjectInSub = item.submenu.map(i=>hasAuthObject(i.authobject))
                if(!hasAnyAuthObjectInSub.includes(true)){ return null }
                return(
                  <SubMenu
                    key={item.key}
                    title={<span><Icon type={item.icon} style={{fontSize:19}}/><span>{toUpperFirstChar(item.name)}</span></span>}
                  >
                    {item.submenu.map((sub)=>{
                      if(!sub.submenu){
                        if(sub.authobject && hasAuthObject(sub.authobject)){
                          return (<Menu.Item key={sub.key}><Link to={sub.path}>{toUpperFirstChar(sub.name)}</Link></Menu.Item>)
                        }if(!sub.authobject){
                          return (<Menu.Item key={sub.key}><Link to={sub.path}>{toUpperFirstChar(sub.name)}</Link></Menu.Item>)
                        }
                        return null
                      }
                      //if route doesnt have component but has sub routes
                      else if(sub.submenu){
                        //hide parent if user doesnt have any auth object in sub
                        let hasAnyAuthObjectInSub = sub.submenu.map(i=>hasAuthObject(i.authobject))
                        if(!hasAnyAuthObjectInSub.includes(true)){ return null }
                        return(
                          <SubMenu
                            key={sub.key}
                            title={<span>{toUpperFirstChar(sub.name)}</span>}
                          >
                            {sub.submenu.map((subA)=>{
                              if(!subA.submenu){
                                if(subA.authobject && hasAuthObject(subA.authobject)){
                                  return (<Menu.Item key={subA.key}><Link to={subA.path}>{toUpperFirstChar(subA.name)}</Link></Menu.Item>)
                                }else if(!subA.authobject){
                                  return (<Menu.Item key={subA.key}><Link to={subA.path}>{toUpperFirstChar(subA.name)}</Link></Menu.Item>)
                                }
                                return null
                              }
                              else{ return null }
                            })}
                          </SubMenu>
                        )
                      }
                      else{ return null }
                    })}
                  </SubMenu>
                )
              }
              
              //redirect routing
              else{ 
                return null 
              }          
            })
            }

          </Menu>
        </Sider>
      
        <Layout style={{ marginLeft: this.state.collapsed ? 80 : 256 }}>

          <Header className="header"
            style={{ background: config.headerColor}}
          >
            <Row type="flex">
              <Col md={{span:20}}>
                <Icon style={{color:theme ? theme.primary_color : '#000'}}
                  className="trigger"
                  type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                  onClick={this.toggle}
                />
                <img src={ globalconfig.COMPANY_LOGO ? globalconfig.COMPANY_LOGO : null } style={{width:'auto', height:45, marginLeft:15}} alt=""/>
              </Col>
              <Col md={4} style={{paddingRight:10}}>
                <Col md={{span:6, offset:4}} >
                  <Dropdown overlay={userMenu}>
                    <div style={{cursor:'pointer'}}>
                      <Avatar 
                        src={user && user.detail ? user.detail.photo_url_full : null} 
                        style={{ backgroundColor: '#aaa', verticalAlign: 'middle', marginRight:5 }} size="medium">{user && user.name ? user.name.charAt(0) : '' }</Avatar>
                    </div>
                  </Dropdown>
                </Col>
                <Col md={12}>
                  <img src={require('@/assets/img/izora.png')} style={{width:100,height:'auto'}} alt=""/>    
                </Col>
              </Col>
            </Row>
          </Header>

          {/* Content */}
          <Switch>
            {sortRoute(this.props.routes).map((route,i)=>(
              <RouteWithSubRoutes key={i} {...route}/>
            ))}
          </Switch>

        </Layout>

      </Layout>
    );
  }

  logout(){
    const { dispatch } = this.props
    dispatch(logoutUser())
    dispatch(setAuthObject([]))
    this.props.history.push('/login');
  }

}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    authobject: state.authobject.data,
    globalconfig: state.globalconfig,
  }
}

export default connect(mapStateToProps)(Main);