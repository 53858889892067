export function setCatalogItemsCount(count) {
  return { type: 'SET_CATALOG_ITEMS_COUNT', count }
}

export function setCatalogItemsSearchText(q) {
  return { type: 'SET_CATALOG_ITEMS_SEARCH_TEXT', q }
}

export function setCatalogItemsMaterialGroup(group_material_id) {
  return { type: 'SET_CATALOG_ITEMS_GROUP_MATERIAL', group_material_id }
}