let initialState = {
  //example key:value
  COMPANY_LOGO: '',
}

const globalconfig = (state = initialState, action) => {
  switch (action.type) {
  case 'SET_CONFIG':
    return {...state, [action.key]: action.value }
  default:
    return state
  }
}

export default globalconfig;