/**
 * auth message for error get api
 */

let initialState = {
  message: '',
}

const auth = (state = initialState, action) => {
  switch (action.type) {
  case 'SET_AUTH_MSG':
    return {...state, message: action.data}
  default:
    return state
  }
}

export default auth;