/**
 * Documentation
 * 
 * path : link to url
 * key : define key for sidebar component, handling current active menu
 * name : displayed menu name in sidebar
 * icon : icon name for menu, please refer to https://ant.design/components/icon/
 * submenu : array of submenu, defined for collapsed child menu 
 * 
 */
export default [
  // dashboard
  {
    path: '/dashboard',
    key: 'dashboard',
    name:'Dashboard',
    icon:'pie-chart',
  },

  //master
  {
    path: '/master',
    key: 'master', 
    name: 'Master Data', 
    icon: 'database',
    submenu:[
      {
        path: '/master/material',
        key: 'material',
        name: 'Material',
        authobject: 'material-view',
      },
      // {
      //   path: '/master/info-records',
      //   key: 'info-records',
      //   name: 'Info Records',
      //   authobject: 'material-vendor-view',
      // },
      // {
      //   path: '/master/budget',
      //   key: 'budget',
      //   name: 'Budget',
      //   authobject: 'budget-view',
      // },
      {
        path: '/master/asset',
        key: 'asset',
        name: 'Asset',
        authobject: 'asset-view',
      }, 
      {
        path: '/master/journal-depreciation',
        key: 'journal-depreciation',
        name: 'Journal Depreciation',
        authobject: 'journal-depreciation-view',
      },
      // {
      //   path: '/master/asset-report',
      //   key: 'asset-report',
      //   name: 'Asset Report',
      //   authobject: 'asset-view',
      // },
    ]
  },

  //business partner
  {
    path: '/business-partner',
    key: 'business-partner',
    name:'Business Partner',
    icon:'deployment-unit',
    submenu:[
      {
        path: '/business-partner/location',
        key: 'location',
        name: 'Location',
        authobject: 'location-view',
      },
      {
        path: '/business-partner/vendor',
        key: 'vendor',
        name: 'Vendor',
        authobject: 'supplier-view',
      },
    ]
  },

  //purchasing
  {
    path: '/purchasing',
    key: 'purchasing',
    name:'Purchasing',
    icon:'dollar',
    submenu:[
      {
        path: '/master/info-records',
        key: 'info-records',
        name: 'Info Records',
        authobject: 'material-vendor-view',
      },
      {
        path: '/inventory/purchase-requisition',
        key: 'purchase-requisition',
        name: 'Purchase Requisition',
        authobject: 'purchase-requisition-view',
      },
      {
        path: '/inventory/request-for-quotation',
        key: 'request-for-quotation',
        name: 'Request for Quotation',
        authobject: 'rfq-view',
      },
      {
        path: '/inventory/purchase-order',
        key: 'purchase-order',
        name: 'Purchase Order',
        authobject: 'purchase-orders-view',
      },
      // {
      //   path: '/inventory/incoming-invoice',
      //   key: 'incoming-invoice',
      //   name: 'Incoming Invoice',
      //   authobject: 'incoming-invoice-view',
      // }, 
      {
        path: '/inventory/purchase-document-history',
        key: 'purchase-document-history',
        name: 'Purchase Document History',
        authobject: 'purchase-orders-view',
      },
    ]
  },

  //inventory
  {
    path: '/inventory',
    key: 'inventory',
    name:'Inventory',
    icon:'audit',
    submenu:[
      {
        path: '/inventory/stock-overview',
        key: 'stock-overview',
        name: 'Stock Overview',
        authobject: 'stock-overview',
      },
      {
        path: '/inventory/reservation',
        key: 'reservation',
        name: 'Reservation',
        authobject: 'reservation-view',
      },
      // {
      //   path: '/inventory/purchase-requisition',
      //   key: 'purchase-requisition',
      //   name: 'Purchase Requisition',
      //   authobject: 'reservation-view',
      // },
      // {
      //   path: '/inventory/request-for-quotation',
      //   key: 'request-for-quotation',
      //   name: 'Request for Quotation',
      //   authobject: 'rfq-view',
      // },
      // {
      //   path: '/inventory/purchase-order',
      //   key: 'purchase-order',
      //   name: 'Purchase Order',
      //   authobject: 'purchase-orders-view',
      // },
      {
        path: '/inventory/goods-movement',
        key: 'goods-movement',
        name: 'Goods Movement',
        authobject: 'goods-movement-view',
      },
      {
        path: '/inventory/goods-movement-report',
        key: 'goods-movement-report',
        name: 'Goods Movement Report',
        authobject: 'goods-movement-view',
      },
      {
        path: '/inventory/monthly-consumption',
        key: 'monthly-consumption',
        name: 'Monthly Consumption',
        authobject: 'monthly-consumption-view',
      },
      {
        path: '/inventory/stock-opname',
        key: 'stock-opname',
        name: 'Stock Opname',
        authobject: 'stock-opname-view',
      },
    ]
  },

  //work order
  {
    path: '/work-order',
    key: 'work-order',
    name:'Asset Maintenance',
    icon:'file-protect',
    submenu:[
      {
        path: '/work-order/task-list',
        key: 'task-list',
        name: 'Task List',
        authobject: 'task-list-view',
      },
      {
        path: '/work-order/service-request',
        key: 'service-request',
        name: 'Service Request',
        authobject: 'service-request-view',
      },
      {
        path: '/work-order/corrective-maintenance',
        key: 'corrective-maintenance',
        name: 'Corrective',
        authobject: 'corrective-maintenance-view',
      },
    ]
  },

  //delivery
  {
    path: '/delivery',
    key: 'delivery',
    name:'delivery',
    icon:'rocket',
    submenu:[
      {
        path: '/delivery/picking-list',
        key: 'picking-list',
        name: 'Picking List',
        authobject: 'picking-list-view',
      },
      {
        path: '/delivery/delivery-list',
        key: 'delivery-list',
        name: 'Delivery List',
        authobject: 'delivery-orders-view',
      },
    ]
  },

  //finance
  {
    path: '/finance',
    key: 'finance',
    name:'finance',
    icon:'calculator',
    submenu:[
      {
        path: '/master/budget',
        key: 'budget',
        name: 'Budget Master',
        authobject: 'budget-view',
      },
      {
        path: '/inventory/incoming-invoice',
        key: 'incoming-invoice',
        name: 'Invoice Receipt',
        authobject: 'incoming-invoice-view',
      },
      {
        path: '/inventory/incoming-invoice-report',
        key: 'incoming-invoice-report',
        name: 'Payment Report',
        authobject: 'incoming-invoice-view',
      }
    ]
  },

  // //production
  // {
  //   path: '/production',
  //   key: 'production',
  //   name:'production',
  //   icon:'schedule',
  //   submenu:[
  //     {
  //       path: '/production/bill-of-material',
  //       key: 'bill-of-material',
  //       name: 'Bill of Material',
  //       authobject: 'bom-view',
  //     },
  //     {
  //       path: '/production/production-schedule',
  //       key: 'production-schedule',
  //       name: 'Production Schedule',
  //       //authobject: 'production-schedule-view',
  //     },
  //     {
  //       path: '/production/production-order',
  //       key: 'production-order',
  //       name: 'Production Order',
  //       authobject: 'production-order-view',
  //     },
  //     {
  //       path: '/production/resource-requirement',
  //       key: 'resource-requirement',
  //       name: 'Resource Requirement',
  //       authobject: 'production-order-view',
  //     },
  //     {
  //       path: '/production/production-confirmation',
  //       key: 'production-confirmation',
  //       name: 'Production Confirmation',
  //       //authobject: 'production-order-view',
  //     },
  //   ]
  // },
  
  //System
  {
    path: '/system-settings',
    key: 'system-settings',
    name: 'System Settings',
    icon: 'setting',
  },
]