import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import * as Sentry from '@sentry/browser';

import './index.css';
import Root from './Root';
import * as serviceWorker from './serviceWorker';

import { ConnectedRouter } from 'connected-react-router'
import store, { history } from './store'

if(process.env.NODE_ENV!=='development'){
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });
}

class SentryCatchBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.error) {
      //render fallback UI
      return (
        <a onClick={() => Sentry.showReportDialog()}>Report feedback</a>
      );
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }
}

ReactDOM.render(
  <SentryCatchBoundary>
    <Provider store={store}>
      <ConnectedRouter history={history} basename={ process.env.REACT_APP_BASENAME }>
        <Root/>
      </ConnectedRouter>
    </Provider>
  </SentryCatchBoundary>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
