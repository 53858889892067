import React from 'react';
import { Layout, Button } from 'antd';
import { connect } from 'react-redux';
import '@/assets/styles/global.css'
import notfoundImg from '@/assets/img/notfound.svg'

const { Content } = Layout;

class Page extends React.Component {

  render() {
    const { message } = this.props
    return (
      <Content style={styles.content}>
        <h1 style={styles.title}>You need { message ? <span style={styles.pre}>"{message}"</span> : null } authorization to access to this function.</h1>    
        <div style={{backgroundImage:`url(${notfoundImg})`, ...styles.image}}/>  
        <Button type="primary" shape="circle" icon="arrow-left" size="large" style={{marginTop:'10px'}} onClick={()=>this.props.history.goBack()} />           
      </Content>
    )
  }
}

const styles ={
  content:{
    margin: '15px', 
    padding: 20, 
    minHeight: 280, 
    overflow:'initial'
  },
  title:{
    fontWeight:200,
    textAlign:'center',
  },
  image:{
    height:400,
    marginTop:40,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    backgroundSize: 'contain',
  },
  pre:{
    fontFamily:'monospace',
    fontSize:'16pt',
    borderRadius:5,
    backgroundColor:'#ddd'
  }
}
const mapStateToProps = (state) => {
  return {
    message: state.auth.message
  }
}

export default connect(mapStateToProps)(Page);