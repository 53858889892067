import React from 'react';
import { Layout, Icon, Row, Col, Avatar, Dropdown, Menu, Input, Empty, Badge } from 'antd';
import { connect } from 'react-redux';
import { Switch } from "react-router-dom";
import { setUser, logoutUser } from 'actions/user';
import './index.css';
import RouteWithSubRoutes from '@/components/RouteWithSubRoutes'
import sortRoute from '@/helpers/sortRoute';
import NumberFormat from 'react-number-format';
import { setCatalogItemsSearchText } from '@/actions/catalogitems';
import { setCatalogItemsMaterialGroup } from '@/actions/catalogitems';

import config from 'config'
import Api from '@/helpers/api';
import handleApiError from '@/helpers/handleApiError';

const { Header, Footer } = Layout;
const api = new Api();

class Page extends React.Component {
  constructor(){
    super()
    this.state = {
      categories:[],
      draft_reservation:[]
    }
    this.onUserMenuClick = this.onUserMenuClick.bind(this)
    this.logout = this.logout.bind(this)
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  setCollapse = (collapsed)=>{
    this.setState({collapsed});
  }

  componentDidMount(){
    const { dispatch } = this.props
    let user = JSON.parse(localStorage.getItem('user'));
    dispatch(setUser(user));

    if(!user){
      this.props.history.push('/login');
    }

    this.getCategories()
    this.getDraftReservation()
  }

  componentDidUpdate(prevProps) {
    const { count } = this.props
        
    // check if refId changed
    if (count !== prevProps.count) {
      this.getDraftReservation()
      // console.log(count)
    }
  }

  getCategories = (value)=>{
    api.getMasterGroupMaterial({q:value})
      .then(response=>{
      // console.log(response.data)
        if(response.status===200){
          const { data } = response.data
          // console.log(data)
          this.setState({categories:data})
        }
      })
      .catch(error=>{
      // console.log(error.response)
        handleApiError(error, this.props)
      })
  }

  onUserMenuClick = function ({ key }) {
    switch(key){
    case 'account-settings':
      this.props.history.push('/account-settings');   
      break
    case 'logout':
      this.logout();
      break
    default:
      break
    }
  };

  categories = ()=>{
    const { categories } = this.state
    return(
      <Menu onClick={e => this.handleMenuCategoryClick(e)}>
        <Menu.Item key={null}>All</Menu.Item>
        {categories.map((item,index)=>(
          <Menu.Item key={item.id}>{item.description}</Menu.Item>
        ))}
      </Menu>
    )
  }

  cartPopup = ()=>{
    const { draft_reservation } = this.state
    let total_amount = '' + draft_reservation.total_amount
    return(
      <div className="menu-popup">  
        <div className="menu-header">
          Total: {draft_reservation.items ? draft_reservation.items.length :'0'} items
          <br/>
          Total Price: <NumberFormat value={total_amount} decimalScale={2} thousandSeparator={true} displayType={'text'} fixedDecimalScale={true}/></div>
        <div className="menu-content">
          { draft_reservation.items && draft_reservation.items.length != 0 ?
            draft_reservation.items.map((item,index)=>{
     
              return (
                <p key={index}>{item.material.description} <NumberFormat value={item.quantity} thousandSeparator={true} displayType={'text'}/> {item.material.base_uom && item.material.base_uom.description}</p>
              )})
            :
            <Empty/>            
          }
        </div>
        { draft_reservation.reservation_code &&
          <div className="menu-footer">
            <a onClick={()=>this.goToReservation()}>Show Reservation</a>
          </div>
        }
      </div>
    )
  }

  notificationPopup = ()=>{
    return( 
      <div className="menu-popup">  
        <div className="menu-header">Notifications</div>
        <div className="menu-content"><Empty/></div>
        <div className="menu-footer">
          <a href="#">Show All</a>
        </div>
      </div>
    )
  }

  goToReservation = ()=>{
    const { draft_reservation } = this.state
    this.props.history.push(`/inventory/reservation/${draft_reservation.reservation_code}`);
  }

  getDraftReservation= ()=>{
    api.getDraft()
      .then(response=>{
        if(response.status===200){
          console.log('draft', response.data)
          if (response.data.header_draft){
            let draft_reservation = response.data.header_draft
            this.setState({
              draft_reservation
            })
          }
          
        }
      })
      .catch(error=>{
        // console.log(error.response)
        handleApiError(error, this.props)
      })
  }

  handleSearch = (q)=>{
    const { dispatch } = this.props
    dispatch(setCatalogItemsSearchText(q));
    // console.log(q)
  }

  handleMenuCategoryClick(e){
    const { dispatch } = this.props
    let group_material_id = e.key
    dispatch(setCatalogItemsMaterialGroup([group_material_id]));
  }

  render() {

    const { draft_reservation } = this.state
    // console.log('asdasd', draft_reservation.items)
    const userMenu = (
      <Menu onClick={this.onUserMenuClick}>
        <Menu.Item key="account-settings">
          <Icon type='setting' />Account Settings
        </Menu.Item>
        <Menu.Divider/>
        <Menu.Item key="logout">
          <Icon type="logout" />Logout
        </Menu.Item>
      </Menu>
    );

    return (
      <Layout>
        <Header className="header"
          style={{ background: config.headerColor, position: 'fixed', zIndex: 1, width: '100%', ...styles.wrapper}}
        >
          <Row type="flex">
            <Col md={4}>
              <img src={require('@/assets/img/inventiry-text.png')} className={`logo`} alt="Logo" style={{width:'auto', height:'25px'}}/>                    
            </Col>
            <Col md={14}>
              <Row>
                <Col md={4}>
                  <Dropdown 
                    overlay={this.categories()} 
                    overlayClassName="menu-popup-wrapper" 
                    placement="bottomCenter" 
                    trigger={['click']}>
                    <div style={{cursor:'pointer'}}>
                      <span style={{
                        fontSize:'12pt',
                        color:config.headerTheme==='dark' ? '#fff' : '#000'
                      }}><Icon type="bars" /> Category</span>
                    </div>
                  </Dropdown>
                </Col>
                <Col md={20}>
                  <Input.Search size="large"
                    placeholder="Search material..."
                    onSearch={value => this.handleSearch(value)}
                    style={{ width: 600 }}
                  />
                </Col>
              </Row>
            </Col>
            <Col md={6} style={{paddingRight:10}}>
              <Row type="flex" justify="end" gutter={10}>
                <Col>
                  <Badge count={draft_reservation.items && draft_reservation.items.length} offset={[-5,-5]}>
                    <Dropdown 
                      overlay={this.cartPopup()} 
                      overlayClassName="menu-popup-wrapper" 
                      placement="bottomCenter" 
                      trigger={['click']}>
                      <div className="nav-btn">
                        <span style={{fontSize:'12pt',color:config.headerTheme==='dark' ? '#fff' : '#000'}}><Icon type="shopping-cart" /></span>
                      </div>
                    </Dropdown>
                  </Badge>
                </Col>
                {/* <Col>
                  <Dropdown 
                    overlay={this.notificationPopup()} 
                    overlayClassName="menu-popup-wrapper" 
                    placement="bottomCenter" 
                    trigger={['click']}>
                    <div className="nav-btn">
                      <span style={{fontSize:'12pt',color:config.headerTheme==='dark' ? '#fff' : '#000'}}><Icon type="bell" /></span>
                    </div>
                  </Dropdown>
                </Col> */}
                <Col>
                  <Dropdown 
                    overlay={userMenu} 
                    overlayClassName="menu-popup-wrapper" 
                    placement="bottomCenter" 
                    trigger={['click']}>
                    <div style={{cursor:'pointer'}}>
                      <Avatar style={{ backgroundColor: '#aaa', verticalAlign: 'middle', marginRight:5 }} size="medium">A</Avatar>
                      <span style={{color:config.headerTheme==='dark' ? '#fff' : '#000'}}>{ this.props.user ? this.props.user.name : null }</span>
                    </div>
                  </Dropdown>
                </Col>
              </Row>
            </Col>
          </Row>
        </Header>

        {/* Content */}
        <Switch>
          {sortRoute(this.props.routes).map((route,i)=>(
            <RouteWithSubRoutes key={i} {...route}/>
          ))}
        </Switch>
          
          
        <Footer className="footer">
          <Row>
            <Col md={4}></Col>
            <Col md={4}>
              <div className="footer-widget">
                <p className="widget-header">Inventory</p>
                <div className="widget-content">
                  <p><a>Aturan Penggunaan</a></p>
                  <p><a>FAQ (Tanya Jawab)</a></p>
                  <p><a>Panduan Reservasi</a></p>
                  <p><a>Panduan Purchase Order</a></p>
                  <p><a>Panduan Delivery Order</a></p>
                  <p><a>Panduan Reservasi</a></p>
                  <p><a>Panduan Reservasi</a></p>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="footer-widget">
                <p className="widget-header">Inventory</p>
                <div className="widget-content">
                  <p>Aturan Penggunaan</p>
                  <p>Panduan Purchase Order</p>
                  <p>Panduan Delivery Order</p>
                  <p>FAQ (Tanya Jawab)</p>
                  <p>Panduan Reservasi</p>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="footer-widget">
                <p className="widget-header">Inventory</p>
                <div className="widget-content">
                  <p>Aturan Penggunaan</p>
                  <p>FAQ (Tanya Jawab)</p>
                  <p>Panduan Purchase Order</p>
                  <p>Panduan Delivery Order</p>
                </div>
              </div>
            </Col>
            <Col md={4}></Col>
          </Row>
        </Footer>
        <Footer className="footer-2">
          <p className="cr">© 2019 Inventory.</p> 
        </Footer>
      </Layout>
    );
  }

  logout(){
    const { dispatch } = this.props
    dispatch(logoutUser())
    this.props.history.push('/login');
  }

}

const styles = {
  wrapper:{
    padding: '0 40px',
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    count: state.catalogitems.count,
    q: state.catalogitems.q,
    group_material_id: state.catalogitems.group_material_id
  }
}

export default connect(mapStateToProps)(Page);