export default {
  /** 
     * set theme to dark so icon color will be light
     */
  headerTheme: 'light', // light | dark
  headerColor: '#fff',
  /** 
     * set theme to dark so icon and link color will be light
     */
  sidebarTheme: 'dark', // light | dark
  sidebarColor: '#034303',

  formatDate: 'D-MMM-YYYY HH:mm:ss',
  formatDateShort: 'D-MMM-YYYY',
  formatTime: 'HH:mm:ss',

  formatPasswordRegex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).+$/,
  formatPasswordMsg: 'Password must have a lowercase letter, uppercase letter, and a number',
}