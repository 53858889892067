/**
 * all authobject modules that user have
 */

let initialState = {
  data: [],
}

const authobject = (state = initialState, action) => {
  switch (action.type) {
  case 'SET_AUTH_OBJECT':
    return {...state, data: action.data}
  default:
    return state
  }
}

export default authobject;