import React from 'react'
import loadable from 'react-loadable';
import { Spin } from 'antd';

import Login from '@/pages/login/Login'
import App from '@/layouts/app/App'
import CatalogLayout from '@/layouts/catalog'
import Page403 from '@/pages/403';
import Page400 from '@/pages/400';

const LoadingComponent = ()=>(<div style={{textAlign:'center', margin:'20px 0'}}><Spin/></div>)

//import pages with lazy load, so app can load faster

const ForgotPassword = loadable({ loader: ()=> import('@/pages/forgot-password'), loading: LoadingComponent })
const ResetPassword = loadable({ loader: ()=> import('@/pages/reset-password'), loading: LoadingComponent })
const CreatePassword = loadable({ loader: ()=> import('@/pages/create-password'), loading: LoadingComponent })

const Dashboard = loadable({ loader: ()=> import('@/pages/dashboard/Dashboard'), loading: LoadingComponent })
const UserList = loadable({ loader: ()=> import('@/pages/user/User'), loading: LoadingComponent })
const UserForm = loadable({ loader: ()=> import('@/pages/user/Form'), loading: LoadingComponent })

const RoleList = loadable({ loader: ()=> import('@/pages/role/Role'), loading: LoadingComponent })
const RoleForm = loadable({ loader: ()=> import('@/pages/role/Form'), loading: LoadingComponent })
const CopyRoleForm = loadable({ loader: ()=> import('@/pages/role/CopyForm'), loading: LoadingComponent })

const AuthObjectList = loadable({ loader: () => import('@/pages/auth-object/List'), loading: LoadingComponent })

const PlantList = loadable({ loader: () => import('@/pages/plant/List'), loading: LoadingComponent })
const PlantForm = loadable({ loader: () => import('@/pages/plant/Form'), loading: LoadingComponent })

const ClassificationTypeList = loadable({ loader: () => import('@/pages/classification-type/List'), loading: LoadingComponent })

const GroupMaterialList = loadable({ loader: () => import('@/pages/group-material/List'), loading: LoadingComponent })
const GroupMaterialForm = loadable({ loader: () => import('@/pages/group-material/Form'), loading: LoadingComponent })

const UomList = loadable({ loader: () => import('@/pages/uom/List'), loading: LoadingComponent })
const UomForm = loadable({ loader: () => import('@/pages/uom/Form'), loading: LoadingComponent })

const ProcurementGroupList = loadable({ loader: () => import('@/pages/procurement-group/List'), loading: LoadingComponent })
const ProcurementGroupForm = loadable({ loader: () => import('@/pages/procurement-group/Form'), loading: LoadingComponent })

const LocationTypeList = loadable({ loader: () => import('@/pages/location-type/List'), loading: LoadingComponent })
const LocationTypeForm = loadable({ loader: () => import('@/pages/location-type/Form'), loading: LoadingComponent })

const LocationList = loadable({ loader: () => import('@/pages/location/List'), loading: LoadingComponent })
const LocationForm = loadable({ loader: () => import('@/pages/location/Form'), loading: LoadingComponent })

const StorageList = loadable({ loader: () => import('@/pages/storage/List'), loading: LoadingComponent })
const StorageForm = loadable({ loader: () => import('@/pages/storage/Form'), loading: LoadingComponent })
const StorageUpload = loadable({ loader: () => import('@/pages/storage/UploadStorage'), loading: LoadingComponent })

const ClassificationMaterialList = loadable({ loader: () => import('@/pages/classification-material/List'), loading: LoadingComponent })
const ClassificationMaterialForm = loadable({ loader: () => import('@/pages/classification-material/Form'), loading: LoadingComponent })
const ClassificationEditParamForm = loadable({ loader: () => import('@/pages/classification-material/FormEditParam'), loading: LoadingComponent })

const MaterialTypeList = loadable({ loader: () => import('@/pages/material-type/List'), loading: LoadingComponent })
const MaterialTypeForm = loadable({ loader: () => import('@/pages/material-type/Form'), loading: LoadingComponent })

const SystemSettings = loadable({ loader: () => import('@/pages/system-settings/SystemSettings'), loading: LoadingComponent })

const CompanyList = loadable({ loader: () => import('@/pages/company/List'), loading: LoadingComponent })
const CompanyForm = loadable({ loader: () => import('@/pages/company/Form'), loading: LoadingComponent })

const DepartmentList = loadable({ loader: () => import('@/pages/department/List'), loading: LoadingComponent })
const DepartmentForm = loadable({ loader: () => import('@/pages/department/Form'), loading: LoadingComponent })

const CostCenterList = loadable({ loader: () => import('@/pages/cost-center/List'), loading: LoadingComponent })
const CostCenterForm = loadable({ loader: () => import('@/pages/cost-center/Form'), loading: LoadingComponent })

const UserGroupList = loadable({ loader: () => import('@/pages/user-group/List'), loading: LoadingComponent })
const UserGroupForm = loadable({ loader: () => import('@/pages/user-group/Form'), loading: LoadingComponent })

const ProfitCenterList = loadable({ loader: () => import('@/pages/profit-center/List'), loading: LoadingComponent })
const ProfitCenterForm = loadable({ loader: () => import('@/pages/profit-center/Form'), loading: LoadingComponent })

const MaterialList = loadable({ loader: () => import('@/pages/material/List'), loading: LoadingComponent })
const MaterialForm = loadable({ loader: () => import('@/pages/material/Form'), loading: LoadingComponent })
const MaterialEditUnitConvertionForm = loadable({ loader: () => import('@/pages/material/FormEditUnitConvertion'), loading: LoadingComponent })
const MaterialEditPurchasingForm= loadable({ loader: () => import('@/pages/material/FormEditPurchasing'), loading: LoadingComponent })
const MaterialEditMRPForm= loadable({ loader: () => import('@/pages/material/FormEditMRP'), loading: LoadingComponent })
const MaterialEditStorageForm= loadable({ loader: () => import('@/pages/material/FormEditStorage'), loading: LoadingComponent })
const MaterialEditAccountForm= loadable({ loader: () => import('@/pages/material/FormEditAccount'), loading: LoadingComponent })
const MaterialEditSalesForm= loadable({ loader: () => import('@/pages/material/FormEditSales'), loading: LoadingComponent })
const MaterialUploadBasic= loadable({ loader: () => import('@/pages/material/UploadMaterialBasic'), loading: LoadingComponent })
const MaterialUploadPlant= loadable({ loader: () => import('@/pages/material/UploadMaterialPlant'), loading: LoadingComponent })
const MaterialUploadStorage= loadable({ loader: () => import('@/pages/material/UploadMaterialStorage'), loading: LoadingComponent })
const MaterialUploadSales= loadable({ loader: () => import('@/pages/material/UploadMaterialSales'), loading: LoadingComponent })
const MaterialUploadAccount= loadable({ loader: () => import('@/pages/material/UploadMaterialAccount'), loading: LoadingComponent })

const AccountSettings = loadable({ loader: () => import('@/pages/account-settings'), loading: LoadingComponent })


const MrpList = loadable({ loader: () => import('@/pages/mrp/List'), loading: LoadingComponent })
const MrpForm = loadable({ loader: () => import('@/pages/mrp/Form'), loading: LoadingComponent })

const ProductChampionList = loadable({ loader: () => import('@/pages/product-champion/List'), loading: LoadingComponent })
const ProductChampionForm = loadable({ loader: () => import('@/pages/product-champion/Form'), loading: LoadingComponent })

const StorageConditionList = loadable({ loader: () => import('@/pages/storage-condition/List'), loading: LoadingComponent })
const StorageConditionForm = loadable({ loader: () => import('@/pages/storage-condition/Form'), loading: LoadingComponent })

const TempConditionList = loadable({ loader: () => import('@/pages/temp-condition/List'), loading: LoadingComponent })
const TempConditionForm = loadable({ loader: () => import('@/pages/temp-condition/Form'), loading: LoadingComponent })

const StorageTypeList = loadable({ loader: () => import('@/pages/storage-type/List'), loading: LoadingComponent })
const StorageTypeForm = loadable({ loader: () => import('@/pages/storage-type/Form'), loading: LoadingComponent })

const SalesOrganizationList = loadable({ loader: () => import('@/pages/sales-organization/List'), loading: LoadingComponent })
const SalesOrganizationForm = loadable({ loader: () => import('@/pages/sales-organization/Form'), loading: LoadingComponent })

const AccountAssignmentList = loadable({ loader: () => import('@/pages/account-assignment/List'), loading: LoadingComponent })
const AccountAssignmentForm = loadable({ loader: () => import('@/pages/account-assignment/Form'), loading: LoadingComponent })

const ItemCategoryList = loadable({ loader: () => import('@/pages/item-category/List'), loading: LoadingComponent })
const ItemCategoryForm = loadable({ loader: () => import('@/pages/item-category/Form'), loading: LoadingComponent })

const ValuationTypeList = loadable({ loader: () => import('@/pages/valuation-type/List'), loading: LoadingComponent })
const ValuationTypeForm = loadable({ loader: () => import('@/pages/valuation-type/Form'), loading: LoadingComponent })

const ValuationClassList = loadable({ loader: () => import('@/pages/valuation-class/List'), loading: LoadingComponent })
const ValuationClassForm = loadable({ loader: () => import('@/pages/valuation-class/Form'), loading: LoadingComponent })

const StockDeterminationList = loadable({ loader: () => import('@/pages/stock-determination/List'), loading: LoadingComponent })
const StockDeterminationForm = loadable({ loader: () => import('@/pages/stock-determination/Form'), loading: LoadingComponent })
const StockDeterminationUpload = loadable({ loader: () => import('@/pages/stock-determination/Upload'), loading: LoadingComponent })

const WorkCenterList = loadable({ loader: () => import('@/pages/work-center/List'), loading: LoadingComponent })
const WorkCenterForm = loadable({ loader: () => import('@/pages/work-center/Form'), loading: LoadingComponent })

const MovementTypeList = loadable({ loader: () => import('@/pages/movement-type/List'), loading: LoadingComponent })
const MovementTypeForm = loadable({ loader: () => import('@/pages/movement-type/Form'), loading: LoadingComponent })
const MovementTypeEditReasonForm = loadable({ loader: () => import('@/pages/movement-type/FormEditReason'), loading: LoadingComponent })

const VendorList = loadable({ loader: () => import('@/pages/vendor/List'), loading: LoadingComponent })
const VendorForm = loadable({ loader: () => import('@/pages/vendor/Form'), loading: LoadingComponent })

const VendorGroupList = loadable({ loader: () => import('@/pages/vendor-group/List'), loading: LoadingComponent })
const VendorGroupForm = loadable({ loader: () => import('@/pages/vendor-group/Form'), loading: LoadingComponent })

const StockOverviewList = loadable({ loader: () => import('@/pages/stock-overview/List'), loading: LoadingComponent })

const ReservationList = loadable({ loader: () => import('@/pages/reservation/List'), loading: LoadingComponent })
const ReservationForm = loadable({ loader: () => import('@/pages/reservation/Form'), loading: LoadingComponent })
const ReservationCatalogue = loadable({ loader: () => import('@/pages/reservation/Catalogue'), loading: LoadingComponent })

const PurchaseRequisitionList = loadable({ loader: () => import('@/pages/purchase-requisition/List'), loading: LoadingComponent })
const PurchaseRequisitionForm = loadable({ loader: () => import('@/pages/purchase-requisition/Form'), loading: LoadingComponent })

const IncomingInvoiceList = loadable({ loader: () => import('@/pages/incoming-invoice/List'), loading: LoadingComponent })
const IncomingInvoiceForm = loadable({ loader: () => import('@/pages/incoming-invoice/Form'), loading: LoadingComponent })
const IncomingInvoiceReport = loadable({ loader: () => import('@/pages/incoming-invoice/ListReport'), loading: LoadingComponent })

const PurchaseOrderList = loadable({ loader: () => import('@/pages/purchase-order/List'), loading: LoadingComponent })
const PurchaseOrderForm = loadable({ loader: () => import('@/pages/purchase-order/Form'), loading: LoadingComponent })

const RFQList = loadable({ loader: () => import('@/pages/request-for-quotation/List'), loading: LoadingComponent })
const RFQForm = loadable({ loader: () => import('@/pages/request-for-quotation/Form'), loading: LoadingComponent })
const RFQFormAwarding = loadable({ loader: () => import('@/pages/request-for-quotation/FormAwarding'), loading: LoadingComponent })

const GoodsMovementList = loadable({ loader: () => import('@/pages/goods-movement/List'), loading: LoadingComponent })
const GoodsMovementForm = loadable({ loader: () => import('@/pages/goods-movement/Form'), loading: LoadingComponent })
const GoodsMovementUpload = loadable({ loader: () => import('@/pages/goods-movement/Upload'), loading: LoadingComponent })

const ProductionConfirmationList = loadable({ loader: () => import('@/pages/production-confirmation/List'), loading: LoadingComponent })
const ProductionConfirmationForm = loadable({ loader: () => import('@/pages/production-confirmation/Form'), loading: LoadingComponent })

const PickingList = loadable({ loader: () => import('@/pages/picking-list/List'), loading: LoadingComponent })
const DeliveryList = loadable({ loader: () => import('@/pages/delivery-list/List'), loading: LoadingComponent })
const DeliveryForm = loadable({ loader: () => import('@/pages/delivery-list/Form'), loading: LoadingComponent })

const GoodsMovementReport = loadable({ loader: () => import('@/pages/goods-movement-report/List'), loading: LoadingComponent })
const GoodsMovementReportForm = loadable({ loader: () => import('@/pages/goods-movement-report/detail/Form'), loading: LoadingComponent })

const MonthlyConsumptionReport = loadable({ loader: () => import('@/pages/monthly-consumption/List'), loading: LoadingComponent })

const TransportTypeList = loadable({ loader: () => import('@/pages/transport-type/List'), loading: LoadingComponent })
const TransportTypeForm = loadable({ loader: () => import('@/pages/transport-type/Form'), loading: LoadingComponent })

const IncotermList = loadable({ loader: () => import('@/pages/incoterm/List'), loading: LoadingComponent })
const IncotermForm = loadable({ loader: () => import('@/pages/incoterm/Form'), loading: LoadingComponent })

const TermOfPaymentList = loadable({ loader: () => import('@/pages/term-of-payment/List'), loading: LoadingComponent })
const TermOfPaymentForm = loadable({ loader: () => import('@/pages/term-of-payment/Form'), loading: LoadingComponent })

const ProjectList = loadable({ loader: () => import('@/pages/project/List'), loading: LoadingComponent })
const ProjectForm = loadable({ loader: () => import('@/pages/project/Form'), loading: LoadingComponent })

const CoaList = loadable({ loader: () => import('@/pages/chart-of-account/List'), loading: LoadingComponent })
const CoaForm = loadable({ loader: () => import('@/pages/chart-of-account/Form'), loading: LoadingComponent })
const CoaUpload = loadable({ loader: () => import('@/pages/chart-of-account/UploadCOA'), loading: LoadingComponent })

const AccountGroupList = loadable({ loader: () => import('@/pages/chart-of-account/ListAccountGroup'), loading: LoadingComponent })
const AccountGroupForm = loadable({ loader: () => import('@/pages/chart-of-account/FormAccountGroup'), loading: LoadingComponent })

const AccountList = loadable({ loader: () => import('@/pages/chart-of-account/ListAccount'), loading: LoadingComponent })
const AccountForm = loadable({ loader: () => import('@/pages/chart-of-account/FormAccount'), loading: LoadingComponent })

const AccountingProcedureList = loadable({ loader: () => import('@/pages/accounting-procedure/List'), loading: LoadingComponent })
const AccountingProcedureForm = loadable({ loader: () => import('@/pages/accounting-procedure/Form'), loading: LoadingComponent })

const InfoRecordsList = loadable({ loader: () => import('@/pages/info-records/List'), loading: LoadingComponent })
const InfoRecordsForm = loadable({ loader: () => import('@/pages/info-records/Form'), loading: LoadingComponent })

const BudgetList = loadable({ loader: () => import('@/pages/budget/List'), loading: LoadingComponent })
const BudgetUpload = loadable({ loader: () => import('@/pages/budget/Add'), loading: LoadingComponent })
const BudgetAdjustmentUpload = loadable({ loader: () => import('@/pages/budget/BudgetAdjustment'), loading: LoadingComponent })
const BudgetAdd = loadable({ loader: () => import('@/pages/budget/Form'), loading: LoadingComponent })
const BudgetTransfer = loadable({ loader: () => import('@/pages/budget/BudgetTransfer'), loading: LoadingComponent })

const BillOfMaterialList = loadable({ loader: () => import('@/pages/bill-of-material/List'), loading: LoadingComponent })
const BillOfMaterialForm = loadable({ loader: () => import('@/pages/bill-of-material/Form'), loading: LoadingComponent })

const GlobalConfigList= loadable({ loader: () => import('@/pages/global-config/List'), loading: LoadingComponent })
const GlobalConfigForm = loadable({ loader: () => import('@/pages/global-config/Form'), loading: LoadingComponent })

const StockOpnameList = loadable({ loader: () => import('@/pages/stock-opname/List'), loading: LoadingComponent })
const StockOpnameForm = loadable({ loader: () => import('@/pages/stock-opname/Form'), loading: LoadingComponent })

const ProductionScheduleList = loadable({ loader: () => import('@/pages/production-schedule/List'), loading: LoadingComponent })
const ProductionScheduleAdd = loadable({ loader: () => import('@/pages/production-schedule/Add'), loading: LoadingComponent })

const ResourceRequirementList = loadable({ loader: () => import('@/pages/resource_requirement/List'), loading: LoadingComponent })
const ProductionOrder = loadable({ loader: () => import('@/pages/production-order/List'), loading: LoadingComponent })

// Pages for Catalog Layout
const CatalogHome = loadable({ loader: () => import('@/pages/catalog/index'), loading: LoadingComponent })

const ReleaseObjectList = loadable({ loader: () => import('@/pages/release-object/List'), loading: LoadingComponent })
const ReleaseObjectForm = loadable({ loader: () => import('@/pages/release-object/Form'), loading: LoadingComponent })

const ReleaseGroupList = loadable({ loader: () => import('@/pages/release-group/List'), loading: LoadingComponent })
const ReleaseGroupForm = loadable({ loader: () => import('@/pages/release-group/Form'), loading: LoadingComponent })

const ReleaseCodeList = loadable({ loader: () => import('@/pages/release-code/List'), loading: LoadingComponent })
const ReleaseCodeForm = loadable({ loader: () => import('@/pages/release-code/Form'), loading: LoadingComponent })

const ReleaseStrategyList = loadable({ loader: () => import('@/pages/release-strategy/List'), loading: LoadingComponent })
const ReleaseStrategyForm = loadable({ loader: () => import('@/pages/release-strategy/Form'), loading: LoadingComponent })

const ReleaseCodeUserList = loadable({ loader: () => import('@/pages/release-code-user/List'), loading: LoadingComponent })
const ReleaseCodeUserForm = loadable({ loader: () => import('@/pages/release-code-user/Form'), loading: LoadingComponent })
//asset
const DepreciationGroupList = loadable({ loader: () => import('@/pages/depreciation-group/List'), loading: LoadingComponent })
const DepreciationGroupForm = loadable({ loader: () => import('@/pages/depreciation-group/Form'), loading: LoadingComponent })

const DepreciationTypeList = loadable({ loader: () => import('@/pages/depreciation-type/List'), loading: LoadingComponent })
const DepreciationTypeForm = loadable({ loader: () => import('@/pages/depreciation-type/Form'), loading: LoadingComponent })
const DepreciationEditPeriodForm = loadable({ loader: () => import('@/pages/depreciation-type/FormEditParam'), loading: LoadingComponent })

const AssetValuationList = loadable({ loader: () => import('@/pages/asset-valuation-type/List'), loading: LoadingComponent })
const AssetValuationForm = loadable({ loader: () => import('@/pages/asset-valuation-type/Form'), loading: LoadingComponent })

const RetiredReasonList = loadable({ loader: () => import('@/pages/retired-reason/List'), loading: LoadingComponent })
const RetiredReasonForm = loadable({ loader: () => import('@/pages/retired-reason/Form'), loading: LoadingComponent })

const AssetTypeList = loadable({ loader: () => import('@/pages/asset-type/List'), loading: LoadingComponent })
const AssetTypeForm = loadable({ loader: () => import('@/pages/asset-type/Form'), loading: LoadingComponent })

const AssetStatusList = loadable({ loader: () => import('@/pages/asset-status/List'), loading: LoadingComponent })
const AssetStatusForm = loadable({ loader: () => import('@/pages/asset-status/Form'), loading: LoadingComponent })

const AssetList = loadable({ loader: () => import('@/pages/asset/List'), loading: LoadingComponent })
const AssetReport = loadable({ loader: () => import('@/pages/asset/Report'), loading: LoadingComponent })
const AssetForm = loadable({ loader: () => import('@/pages/asset/Form'), loading: LoadingComponent })
const AssetFormUpload = loadable({ loader: () => import('@/pages/asset/Upload'), loading: LoadingComponent })

const TaskList = loadable({ loader: () => import('@/pages/task-list/List'), loading: LoadingComponent })
const TaskListForm = loadable({ loader: () => import('@/pages/task-list/Form'), loading: LoadingComponent })

const ServiceRequestList = loadable({ loader: () => import('@/pages/service-request/List'), loading: LoadingComponent })
const ServiceRequestForm = loadable({ loader: () => import('@/pages/service-request/Form'), loading: LoadingComponent })

const CmWorkOrderDetail = loadable({ loader: () => import('@/pages/corrective-maintenance/FormDetail'), loading: LoadingComponent })
const CmWorkOrderForm = loadable({ loader: () => import('@/pages/corrective-maintenance/Form'), loading: LoadingComponent })
const CmWorkOrderList = loadable({ loader: () => import('@/pages/corrective-maintenance/List'), loading: LoadingComponent })

const PurchaseDocumentHistoryList = loadable({ loader: () => import('@/pages/purchase-document-history/List'), loading: LoadingComponent })

const JournalDepreciationReport = loadable({ loader: () => import('@/pages/journal-depreciation/List'), loading: LoadingComponent })

const AccountConditionList = loadable({ loader: () => import('@/pages/accounting-condition/List'), loading: LoadingComponent })
const AccountConditionForm = loadable({ loader: () => import('@/pages/accounting-condition/Form'), loading: LoadingComponent })

const routes = [
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/reset-password/:code',
    component: ResetPassword,
  },
  {
    path: '/create-password/:code',
    component: CreatePassword,
  },
  //catalog layout
  {
    path: '/catalog',
    name: 'catalog',
    component: CatalogLayout,
    routes:[
      //Reservation
      {
        path: '/catalog',
        name: 'catalog',
        breadcrumbName: 'Catalog',
        component: CatalogHome,
      }
    ]
  },
  // main app layout
  { 
    path: '/',
    name: 'home',
    component: App,
    routes: [
      // dashboard
      {
        path: '/dashboard',
        name:'dashboard',  
        component: Dashboard,
      },
            
      /**
             * Master =============================================================
             */
      {
        path: '/master',
        name: 'master',
        breadcrumbName: 'Master',
        routes:[
          //material
          {
            path: '/master/material',
            name: 'material',
            breadcrumbName: 'Material',
            routes: [
              { path: '/master/material', redirect: '/master/material/list' },
              {
                path: '/master/material/list',
                name: 'material list',
                component: MaterialList,
                authobject: 'material-view',
              },
              {
                path: '/master/material/add',
                name: 'material add',
                component: (props) => <MaterialForm add {...props} />,
                authobject: 'material-create',
              },
              {
                path: '/master/material/upload-basic',
                name: 'material basic upload',
                component: (props) => <MaterialUploadBasic add {...props} />,
                authobject: 'material-create',
              },
              {
                path: '/master/material/upload-plant',
                name: 'material plant upload',
                component: (props) => <MaterialUploadPlant add {...props} />,
                authobject: 'material-create',
              },
              {
                path: '/master/material/upload-storage',
                name: 'material storage upload',
                component: (props) => <MaterialUploadStorage add {...props} />,
                authobject: 'material-create',
              },
              {
                path: '/master/material/upload-sales',
                name: 'material sales upload',
                component: (props) => <MaterialUploadSales add {...props} />,
                authobject: 'material-create',
              },
              {
                path: '/master/material/upload-account',
                name: 'material account upload',
                component: (props) => <MaterialUploadAccount add {...props} />,
                authobject: 'material-create',
              },
              {
                path: '/master/material/:id/view',
                name: 'material view',
                component: (props) => <MaterialForm view {...props} />,
                authobject: 'material-view',
              },
              {
                path: '/master/material/:id/:sales_material_id/sales',
                name: 'material edit',
                component: MaterialEditSalesForm,
                authobject: 'material-update',
              },
              {
                path: '/master/material/:id/:account_material_id/account',
                name: 'material edit',
                component: MaterialEditAccountForm,
                authobject: 'material-update',
              },
              {
                path: '/master/material/:id/:storage_material_id/storage',
                name: 'material edit',
                component: MaterialEditStorageForm,
                authobject: 'material-update',
              },
              {
                path: '/master/material/:id/:purchassingid/purchasing',
                name: 'material edit',
                component: MaterialEditPurchasingForm,
                authobject: 'material-update',
              },
              {
                path: '/master/material/:id/:purchassingid/mrp',
                name: 'material edit',
                component: MaterialEditMRPForm,
                authobject: 'material-update',
              },
              {
                path: '/master/material/:id/:uomconvid',
                name: 'material edit',
                component: MaterialEditUnitConvertionForm,
                authobject: 'material-update',
              },
              {
                path: '/master/material/:id',
                name: 'material edit',
                component: (props) => <MaterialForm edit {...props} />,
                authobject: 'material-update',
              },
                            
            ]
          },
        ]
      },
      //info-records
      {
        path: '/master/info-records',
        name: 'info-records',
        breadcrumbName: 'Info Records',
        routes: [
          { path: '/master/info-records', redirect: '/master/info-records/list' },
          {
            path: '/master/info-records/list',
            name: 'info-records',
            component: InfoRecordsList,
            authobject: 'material-vendor-view',
          },
          {
            path: '/master/info-records/add',
            name: 'info-records add',
            component: (props) => <InfoRecordsForm add {...props} />,
            authobject: 'material-vendor-create',
          },
          {
            path: '/master/info-records/:id/view',
            name: 'info-records view',
            component: (props) => <InfoRecordsForm view {...props} />,
            authobject: 'material-vendor-view',
          },
          {
            path: '/master/info-records/:id',
            name: 'info-records edit',
            component: (props) => <InfoRecordsForm edit {...props} />,
            authobject: 'material-vendor-update',
          },
        ]
      },

      //budget
      {
        path: '/master/budget',
        name: 'budget',
        breadcrumbName: 'Budget',
        routes: [
          { path: '/master/budget', redirect: '/master/budget/list' },
          {
            path: '/master/budget/list',
            name: 'budget',
            component: BudgetList,
            authobject: 'budget-view',
          },
          {
            path: '/master/budget/add',
            name: 'budget add',
            component: (props) => <BudgetAdd add {...props} />,
            authobject: 'budget-create',
          },
          {
            path: '/master/budget/upload',
            name: 'budget upload',
            component: (props) => <BudgetUpload add {...props} />,
            authobject: 'budget-create',
          },
          {
            path: '/master/budget/adjustment',
            name: 'budget upload',
            component: (props) => <BudgetAdjustmentUpload add {...props} />,
            authobject: 'budget-adjustment',
          },
          {
            path: '/master/budget/transfer',
            name: 'budget transfer',
            component: (props) => <BudgetTransfer {...props} />,
            authobject: 'budget-create',
          },
          {
            path: '/master/budget/:id/view',
            name: 'budget view',
            component: (props) => <BudgetAdd view {...props} />,
            authobject: 'budget-view',
          },
          {
            path: '/master/budget/:id',
            name: 'budget edit',
            component: (props) => <BudgetAdd edit {...props} />,
            authobject: 'budget-update',
          },
        ]
      },

      //asset
      {
        path: '/master/asset',
        name: 'asset',
        breadcrumbName: 'Asset',
        routes: [
          { path: '/master/asset', redirect: '/master/asset/list' },
          {
            path: '/master/asset/list',
            name: 'asset',
            component: AssetList,
            authobject: 'asset-view',
          },
          {
            path: '/master/asset/add',
            name: 'asset add',
            component: (props) => <AssetForm add {...props} />,
            authobject: 'asset-create',
          },
          {
            path: '/master/asset/upload',
            name: 'asset upload',
            component: (props) => <AssetFormUpload add {...props} />,
            authobject: 'asset-create',
          },
          {
            path: '/master/asset/:id/view',
            name: 'asset view',
            component: (props) => <AssetForm view {...props} />,
            authobject: 'asset-view',
          },
          {
            path: '/master/asset/:id',
            name: 'asset edit',
            component: (props) => <AssetForm edit {...props} />,
            authobject: 'asset-update',
          },
        ]
      },

      //asset
      {
        path: '/master/asset-report',
        name: 'asset',
        breadcrumbName: 'Asset Report',
        routes: [
          {
            path: '/master/asset-report',
            name: 'asset-report',
            component: AssetReport,
            authobject: 'asset-view',
          },
        ]
      },
            
      /**
             * System Settings ====================================================
             */
      {
        path: '/system-settings',
        name: 'system-settings',
        breadcrumbName: 'System Settings',
        component: SystemSettings,
      },
      //role
      {
        path: '/system-settings/role',
        name: 'role',
        breadcrumbName: 'Role',
        routes:[
          { path: '/system-settings/role', redirect: '/system-settings/role/list' },
          {
            path: '/system-settings/role/list',
            name: 'role',
            component: RoleList,
            authobject: 'role-view',
          },
          {
            path: '/system-settings/role/add',
            name: 'role add',
            component: (props)=> <RoleForm add {...props}/>,
            authobject: 'role-create',
          },
          {
            path: '/system-settings/role/copy',
            name: 'role copy',
            component: (props)=> <CopyRoleForm add {...props}/>,
            authobject: 'role-create',
          },
          {
            path: '/system-settings/role/:id/view',
            name: 'role view',
            component: (props) => <RoleForm view {...props} />,
            authobject: 'role-view',
          },
          {
            path: '/system-settings/role/:id',
            name: 'role edit',
            component: (props)=> <RoleForm edit {...props}/>,
            authobject: 'role-update',
          },
        ]
      },
      // user-group
      {
        path: '/system-settings/user-group',
        name: 'user-group',
        breadcrumbName: 'User Group',
        routes: [
          { path: '/system-settings/user-group', redirect: '/system-settings/user-group/list' },
          {
            path: '/system-settings/user-group/list',
            name: 'user-group',
            component: UserGroupList,
            authobject: 'user-group-view',
          },
          {
            path: '/system-settings/user-group/add',
            name: 'user-group add',
            component: (props) => <UserGroupForm add {...props} />,
            authobject: 'user-group-create',
          },
          {
            path: '/system-settings/user-group/:id/view',
            name: 'user-group view',
            component: (props) => <UserGroupForm view {...props} />,
            authobject: 'user-group-view',
          },
          {
            path: '/system-settings/user-group/:id',
            name: 'user-group edit',
            component: (props) => <UserGroupForm edit {...props} />,
            authobject: 'user-group-update',
          },
        ]
      },
      // user
      {
        path: '/system-settings/user',
        name:'user', 
        breadcrumbName: 'User',
        routes:[
          { path: '/system-settings/user', redirect: '/system-settings/user/list' },
          {
            path: '/system-settings/user/list',
            name: 'user',
            component: UserList,
            authobject: 'user-view',
          },
          {
            path: '/system-settings/user/add',
            name: 'user add',
            component: (props)=> <UserForm add {...props}/>, //handling add prop
            authobject: 'user-create',
          },
          {
            path: '/system-settings/user/:id/view',
            name: 'user view',
            component: (props) => <UserForm view {...props} />,
            authobject: 'user-view',
          },
          {
            path: '/system-settings/user/:id',
            name: 'user edit',
            component: (props)=> <UserForm edit {...props}/>, // handling edit prop
            authobject: 'user-update',
          },
        ]
      },
      //plant
      {
        path: '/system-settings/plant',
        name: 'plant',
        breadcrumbName: 'Plant',
        routes: [
          { path: '/system-settings/plant', redirect: '/system-settings/plant/list' },
          {
            path: '/system-settings/plant/list',
            name: 'plant',
            component: PlantList,
            authobject: 'plant-view',
          },
          {
            path: '/system-settings/plant/add',
            name: 'plant add',
            component: (props) => <PlantForm add {...props} />,
            authobject: 'plant-create',
          },
          {
            path: '/system-settings/plant/:id/view',
            name: 'plant view',
            component: (props) => <PlantForm view {...props} />,
            authobject: 'plant-view',
          },
          {
            path: '/system-settings/plant/:id',
            name: 'plant edit',
            component: (props) => <PlantForm edit {...props} />,
            authobject: 'plant-update',
          },
        ]
      },
      /**
             * Production ====================================================
             */
      {
        path: '/production',
        name: 'production',
        breadcrumbName: 'Production',
        component: Dashboard,
      },
      
      //Bill of Material
      {
        path: '/production/bill-of-material',
        name: 'bill-of-material',
        breadcrumbName: 'Bill of Material',
        routes:[
          { path: '/production/bill-of-material', redirect: '/production/bill-of-material/list' },
          {
            path: '/production/bill-of-material/list',
            name: 'bill-of-material list',
            component: BillOfMaterialList,
            authobject: 'bom-view',
          },
          {
            path: '/production/bill-of-material/add',
            name: 'bill-of-material add',
            component: (props) => <BillOfMaterialForm add {...props} />,
            authobject: 'bom-create',
          },
          {
            path: '/production/bill-of-material/:id/view',
            name: 'bill-of-material view',
            component: (props) => <BillOfMaterialForm view {...props} />,
            authobject: 'bom-view',
          },
          {
            path: '/production/bill-of-material/:id',
            name: 'bill-of-material edit',
            component: (props)=> <BillOfMaterialForm edit {...props}/>,
            authobject: 'bom-update',
          },
        ]
      },
      //Production Schedule
      {
        path: '/production/production-schedule',
        name: 'production-schedule',
        breadcrumbName: 'Production Schedule',
        routes:[
          { path: '/production/production-schedule', redirect: '/production/production-schedule/list' },
          {
            path: '/production/production-schedule/list',
            name: 'production-schedule list',
            component: ProductionScheduleList,
            authobject: 'production-order-view',
          },
          {
            path: '/production/production-schedule/add',
            name: 'production-schedule add',
            component: ProductionScheduleAdd,
            // authobject: 'production-order-add',
          },
        ]
      },
      //Resource Requirement
      {
        path: '/production/resource-requirement',
        name: 'resource-requirement',
        breadcrumbName: 'Resource Requirement',
        routes:[
          { path: '/production/resource-requirement', redirect: '/production/resource-requirement/list' },
          {
            path: '/production/resource-requirement/list',
            name: 'resource-requirement list',
            component: ResourceRequirementList,
            authobject: 'production-order-view',
          },
        ]
      },
      
      //Production Order
      {
        path: '/production/production-order',
        name: 'production-order',
        breadcrumbName: 'Production Order',
        routes:[
          { path: '/production/production-order', redirect: '/production/production-order/list' },
          {
            path: '/production/production-order/list',
            name: 'production-order list',
            component: ProductionOrder,
            authobject: 'production-order-view',
          },
        ]
      },

      //Production Confirmation
      {
        path: '/production/production-confirmation',
        name: 'production-confirmation',
        breadcrumbName: 'Production Confirmation',
        routes:[
          { path: '/production/production-confirmation', redirect: '/production/production-confirmation/list' },
          {
            path: '/production/production-confirmation/create',
            name: 'production-confirmation create',
            component: ProductionConfirmationForm,
            authobject: 'goods-movement-create',
          },
          {
            path: '/production/production-confirmation/list',
            name: 'production-confirmation list',
            component: ProductionConfirmationList,
            authobject: 'goods-movement-view',
          },
          {
            path: '/production/production-confirmation/:id',
            name: 'production-confirmation edit',
            component: (props)=> <ProductionConfirmationForm edit {...props}/>,
            authobject: 'goods-movement-update',
          },
        ]
      },

      //modules
      {
        path: '/system-settings/auth-object',
        name: 'auth-object',
        breadcrumbName: 'Authorization Object',
        component: AuthObjectList,
        authobject: 'role-view',
      },
      //classification-type
      {
        path: '/system-settings/classification-type',
        name: 'classification-type',
        breadcrumbName: 'Classification Type',
        routes: [
          { path: '/system-settings/classification-type', redirect: '/system-settings/classification-type/list' },
          {
            path: '/system-settings/classification-type/list',
            name: 'classification-type',
            component: ClassificationTypeList,
            authobject: 'classification-type-view',
          },
          // {
          //     path: '/system-settings/classification-type/add',
          //     name: 'classification-type add',
          //     component: (props) => <ClassificationTypeForm add {...props} />,
          // },
          // {
          //     path: '/system-settings/classification-type/:id/view',
          //     name: 'classification-type view',
          //     component: (props) => <ClassificationTypeForm view {...props} />,
          // },
          // {
          //     path: '/system-settings/classification-type/:id',
          //     name: 'classification-type edit',
          //     component: (props) => <ClassificationTypeForm edit {...props} />,
          // },
        ]
      },
      // group-material
      {
        path: '/system-settings/group-material',
        name: 'group-material',
        breadcrumbName: 'Group Material',
        routes: [
          { path: '/system-settings/group-material', redirect: '/system-settings/group-material/list' },
          {
            path: '/system-settings/group-material/list',
            name: 'group-material',
            component: GroupMaterialList,
            authobject: 'group-material-view',
          },
          {
            path: '/system-settings/group-material/add',
            name: 'group-material add',
            component: (props) => <GroupMaterialForm add {...props} />,
            authobject: 'group-material-create',
          },
          {
            path: '/system-settings/group-material/:id/view',
            name: 'group-material view',
            component: (props) => <GroupMaterialForm view {...props} />,
            authobject: 'group-material-view',
          },
          {
            path: '/system-settings/group-material/:id',
            name: 'group-material edit',
            component: (props) => <GroupMaterialForm edit {...props} />,
            authobject: 'group-material-update',
          },
        ]
      },
      //material type
      {
        path: '/system-settings/material-type',
        name: 'material-type',
        breadcrumbName: 'Material Type',
        routes: [
          { path: '/system-settings/material-type', redirect: '/system-settings/material-type/list' },
          {
            path: '/system-settings/material-type/list',
            name: 'material-type',
            component: MaterialTypeList,
            authobject: 'material-view',
          },
          {
            path: '/system-settings/material-type/add',
            name: 'material-type add',
            component: (props) => <MaterialTypeForm add {...props} />,
            authobject: 'material-create',
          },
          {
            path: '/system-settings/material-type/:id/view',
            name: 'material-type view',
            component: (props) => <MaterialTypeForm view {...props} />,
            authobject: 'material-view',
          },
          {
            path: '/system-settings/material-type/:id',
            name: 'material-type edit',
            component: (props) => <MaterialTypeForm edit {...props} />,
            authobject: 'material-update',
          },
        ]
      },
      //uom
      {
        path: '/system-settings/uom',
        name: 'uom',
        breadcrumbName: 'UoM',
        routes: [
          { path: '/system-settings/uom', redirect: '/system-settings/uom/list' },
          {
            path: '/system-settings/uom/list',
            name: 'uom',
            component: UomList,
            authobject: 'uom-view',
          },
          {
            path: '/system-settings/uom/add',
            name: 'uom add',
            component: (props) => <UomForm add {...props} />,
            authobject: 'uom-create',
          },
          {
            path: '/system-settings/uom/:id/view',
            name: 'uom view',
            component: (props) => <UomForm view {...props} />,
            authobject: 'uom-view',
          },
          {
            path: '/system-settings/uom/:id',
            name: 'uom edit',
            component: (props) => <UomForm edit {...props} />,
            authobject: 'uom-update',
          },
        ]
      },
      //profit-center
      {
        path: '/system-settings/profit-center',
        name: 'profit-center',
        breadcrumbName: 'Profit Center',
        routes: [
          { path: '/system-settings/profit-center', redirect: '/system-settings/profit-center/list' },
          {
            path: '/system-settings/profit-center/list',
            name: 'profit-center',
            component: ProfitCenterList,
            authobject: 'profit-center-view',
          },
          {
            path: '/system-settings/profit-center/add',
            name: 'profit-center add',
            component: (props) => <ProfitCenterForm add {...props} />,
            authobject: 'profit-center-create',
          },
          {
            path: '/system-settings/profit-center/:id/view',
            name: 'profit-center view',
            component: (props) => <ProfitCenterForm view {...props} />,
            authobject: 'profit-center-view',
          },
          {
            path: '/system-settings/profit-center/:id',
            name: 'profit-center edit',
            component: (props) => <ProfitCenterForm edit {...props} />,
            authobject: 'profit-center-update',
          },
        ]
      },
      //mrp
      {
        path: '/system-settings/mrp',
        name: 'mrp',
        breadcrumbName: 'MRP Controller',
        routes: [
          { path: '/system-settings/mrp', redirect: '/system-settings/mrp/list' },
          {
            path: '/system-settings/mrp/list',
            name: 'mrp',
            component: MrpList,
            authobject: 'mrp-view',
          },
          {
            path: '/system-settings/mrp/add',
            name: 'mrp add',
            component: (props) => <MrpForm add {...props} />,
            authobject: 'mrp-create',
          },
          {
            path: '/system-settings/mrp/:id/view',
            name: 'mrp view',
            component: (props) => <MrpForm view {...props} />,
            authobject: 'mrp-view',
          },
          {
            path: '/system-settings/mrp/:id',
            name: 'mrp edit',
            component: (props) => <MrpForm edit {...props} />,
            authobject: 'mrp-update',
          },
        ]
      },
      //product-champion
      {
        path: '/system-settings/product-champion',
        name: 'product-champion',
        breadcrumbName: 'Product Champion',
        routes: [
          { path: '/system-settings/product-champion', redirect: '/system-settings/product-champion/list' },
          {
            path: '/system-settings/product-champion/list',
            name: 'product-champion',
            component: ProductChampionList,
            authobject: 'product-champion-view',
          },
          {
            path: '/system-settings/product-champion/add',
            name: 'product-champion add',
            component: (props) => <ProductChampionForm add {...props} />,
            authobject: 'product-champion-create',
          },
          {
            path: '/system-settings/product-champion/:id/view',
            name: 'product-champion view',
            component: (props) => <ProductChampionForm view {...props} />,
            authobject: 'product-champion-view',
          },
          {
            path: '/system-settings/product-champion/:id',
            name: 'product-champion edit',
            component: (props) => <ProductChampionForm edit {...props} />,
            authobject: 'product-champion-update',
          },
        ]
      },
      //storage-condition
      {
        path: '/system-settings/storage-condition',
        name: 'storage-condition',
        breadcrumbName: 'Storage Condition',
        routes: [
          { path: '/system-settings/storage-condition', redirect: '/system-settings/storage-condition/list' },
          {
            path: '/system-settings/storage-condition/list',
            name: 'storage-condition',
            component: StorageConditionList,
            authobject: 'storage-condition-view',
          },
          {
            path: '/system-settings/storage-condition/add',
            name: 'storage-condition add',
            component: (props) => <StorageConditionForm add {...props} />,
            authobject: 'storage-condition-create',
          },
          {
            path: '/system-settings/storage-condition/:id/view',
            name: 'storage-condition view',
            component: (props) => <StorageConditionForm view {...props} />,
            authobject: 'storage-condition-view',
          },
          {
            path: '/system-settings/storage-condition/:id',
            name: 'storage-condition edit',
            component: (props) => <StorageConditionForm edit {...props} />,
            authobject: 'storage-condition-update',
          },
        ]
      },
      //temp-condition
      {
        path: '/system-settings/temp-condition',
        name: 'temp-condition',
        breadcrumbName: 'Temp Condition',
        routes: [
          { path: '/system-settings/temp-condition', redirect: '/system-settings/temp-condition/list' },
          {
            path: '/system-settings/temp-condition/list',
            name: 'temp-condition',
            component: TempConditionList,
            authobject: 'temp-condition-view',
          },
          {
            path: '/system-settings/temp-condition/add',
            name: 'temp-condition add',
            component: (props) => <TempConditionForm add {...props} />,
            authobject: 'temp-condition-create',
          },
          {
            path: '/system-settings/temp-condition/:id/view',
            name: 'temp-condition view',
            component: (props) => <TempConditionForm view {...props} />,
            authobject: 'temp-condition-view',
          },
          {
            path: '/system-settings/temp-condition/:id',
            name: 'temp-condition edit',
            component: (props) => <TempConditionForm edit {...props} />,
            authobject: 'temp-condition-update',
          },
        ]
      },
      //storage-type
      {
        path: '/system-settings/storage-type',
        name: 'storage-type',
        breadcrumbName: 'Storage Type',
        routes: [
          { path: '/system-settings/storage-type', redirect: '/system-settings/storage-type/list' },
          {
            path: '/system-settings/storage-type/list',
            name: 'storage-type',
            component: StorageTypeList,
            authobject: 'storage-type-view',
          },
          {
            path: '/system-settings/storage-type/add',
            name: 'storage-type add',
            component: (props) => <StorageTypeForm add {...props} />,
            authobject: 'storage-type-create',
          },
          {
            path: '/system-settings/storage-type/:id/view',
            name: 'storage-type view',
            component: (props) => <StorageTypeForm view {...props} />,
            authobject: 'storage-type-view',
          },
          {
            path: '/system-settings/storage-type/:id',
            name: 'storage-type edit',
            component: (props) => <StorageTypeForm edit {...props} />,
            authobject: 'storage-type-update',
          },
        ]
      },
      //sales-organization
      {
        path: '/system-settings/sales-organization',
        name: 'sales-organization',
        breadcrumbName: 'Sales Organization',
        routes: [
          { path: '/system-settings/sales-organization', redirect: '/system-settings/sales-organization/list' },
          {
            path: '/system-settings/sales-organization/list',
            name: 'sales-organization',
            component: SalesOrganizationList,
            authobject: 'sales-organization-view',
          },
          {
            path: '/system-settings/sales-organization/add',
            name: 'sales-organization add',
            component: (props) => <SalesOrganizationForm add {...props} />,
            authobject: 'sales-organization-create',
          },
          {
            path: '/system-settings/sales-organization/:id/view',
            name: 'sales-organization view',
            component: (props) => <SalesOrganizationForm view {...props} />,
            authobject: 'sales-organization-view',
          },
          {
            path: '/system-settings/sales-organization/:id',
            name: 'sales-organization edit',
            component: (props) => <SalesOrganizationForm edit {...props} />,
            authobject: 'sales-organization-update',
          },
        ]
      },
      //account-assignment
      {
        path: '/system-settings/account-assignment',
        name: 'account-assignment',
        breadcrumbName: 'Account Assignment',
        routes: [
          { path: '/system-settings/account-assignment', redirect: '/system-settings/account-assignment/list' },
          {
            path: '/system-settings/account-assignment/list',
            name: 'account-assignment',
            component: AccountAssignmentList,
            authobject: 'account-assignment-view',
          },
          {
            path: '/system-settings/account-assignment/add',
            name: 'account-assignment add',
            component: (props) => <AccountAssignmentForm add {...props} />,
            authobject: 'account-assignment-create',
          },
          {
            path: '/system-settings/account-assignment/:id/view',
            name: 'account-assignment view',
            component: (props) => <AccountAssignmentForm view {...props} />,
            authobject: 'account-assignment-view',
          },
          {
            path: '/system-settings/account-assignment/:id',
            name: 'account-assignment edit',
            component: (props) => <AccountAssignmentForm edit {...props} />,
            authobject: 'account-assignment-update',
          },
        ]
      },
      //item-category
      {
        path: '/system-settings/item-category',
        name: 'item-category',
        breadcrumbName: 'Item Category',
        routes: [
          { path: '/system-settings/item-category', redirect: '/system-settings/item-category/list' },
          {
            path: '/system-settings/item-category/list',
            name: 'item-category',
            component: ItemCategoryList,
            authobject: 'item-category-view',
          },
          {
            path: '/system-settings/item-category/add',
            name: 'item-category add',
            component: (props) => <ItemCategoryForm add {...props} />,
            authobject: 'item-category-create',
          },
          {
            path: '/system-settings/item-category/:id/view',
            name: 'item-category view',
            component: (props) => <ItemCategoryForm view {...props} />,
            authobject: 'item-category-view',
          },
          {
            path: '/system-settings/item-category/:id',
            name: 'item-category edit',
            component: (props) => <ItemCategoryForm edit {...props} />,
            authobject: 'item-category-update',
          },
        ]
      },
      //valuation-type
      {
        path: '/system-settings/valuation-type',
        name: 'valuation-type',
        breadcrumbName: 'Valuation Type',
        routes: [
          { path: '/system-settings/valuation-type', redirect: '/system-settings/valuation-type/list' },
          {
            path: '/system-settings/valuation-type/list',
            name: 'valuation-type',
            component: ValuationTypeList,
            authobject: 'valuation-type-view',
          },
          {
            path: '/system-settings/valuation-type/add',
            name: 'valuation-type add',
            component: (props) => <ValuationTypeForm add {...props} />,
            authobject: 'valuation-type-create',
          },
          {
            path: '/system-settings/valuation-type/:id/view',
            name: 'valuation-type view',
            component: (props) => <ValuationTypeForm view {...props} />,
            authobject: 'valuation-type-view',
          },
          {
            path: '/system-settings/valuation-type/:id',
            name: 'valuation-type edit',
            component: (props) => <ValuationTypeForm edit {...props} />,
            authobject: 'valuation-type-update',
          },
        ]
      },
      //valuation-class
      {
        path: '/system-settings/valuation-class',
        name: 'valuation-class',
        breadcrumbName: 'Valuation Class',
        routes: [
          { path: '/system-settings/valuation-class', redirect: '/system-settings/valuation-class/list' },
          {
            path: '/system-settings/valuation-class/list',
            name: 'valuation-class',
            component: ValuationClassList,
            authobject: 'valuation-class-view',
          },
          {
            path: '/system-settings/valuation-class/add',
            name: 'valuation-class add',
            component: (props) => <ValuationClassForm add {...props} />,
            authobject: 'valuation-class-create',
          },
          {
            path: '/system-settings/valuation-class/:id/view',
            name: 'valuation-class view',
            component: (props) => <ValuationClassForm view {...props} />,
            authobject: 'valuation-class-view',
          },
          {
            path: '/system-settings/valuation-class/:id',
            name: 'valuation-class edit',
            component: (props) => <ValuationClassForm edit {...props} />,
            authobject: 'valuation-class-update',
          },
        ]
      },
      //procurement-group
      {
        path: '/system-settings/purchasing-group',
        name: 'purchasing-group',
        breadcrumbName: 'Purchasing Group',
        routes: [
          { path: '/system-settings/purchasing-group', redirect: '/system-settings/purchasing-group/list' },
          {
            path: '/system-settings/purchasing-group/list',
            name: 'purchasing-group',
            component: ProcurementGroupList,
            authobject: 'procurement-group-view',
          },
          {
            path: '/system-settings/purchasing-group/add',
            name: 'purchasing-group add',
            component: (props) => <ProcurementGroupForm add {...props} />,
            authobject: 'procurement-group-create',
          },
          {
            path: '/system-settings/purchasing-group/:id/view',
            name: 'purchasing-group view',
            component: (props) => <ProcurementGroupForm view {...props} />,
            authobject: 'procurement-group-view',
          },
          {
            path: '/system-settings/purchasing-group/:id',
            name: 'purchasing-group edit',
            component: (props) => <ProcurementGroupForm edit {...props} />,
            authobject: 'procurement-group-update',
          },
        ]
      },
      //location-type
      {
        path: '/system-settings/location-type',
        name: 'location-type',
        breadcrumbName: 'Location Type',
        routes: [
          { path: '/system-settings/location-type', redirect: '/system-settings/location-type/list' },
          {
            path: '/system-settings/location-type/list',
            name: 'location-type',
            component: LocationTypeList,
            authobject: 'location-type-view',
          },
          {
            path: '/system-settings/location-type/add',
            name: 'location-type add',
            component: (props) => <LocationTypeForm add {...props} />,
            authobject: 'location-type-create',
          },
          {
            path: '/system-settings/location-type/:id/view',
            name: 'location-type view',
            component: (props) => <LocationTypeForm view {...props} />,
            authobject: 'location-type-view',
          },
          {
            path: '/system-settings/location-type/:id',
            name: 'location-type edit',
            component: (props) => <LocationTypeForm edit {...props} />,
            authobject: 'location-type-update',
          },
        ]
      },
      //storage
      {
        path: '/system-settings/storage',
        name: 'storage',
        breadcrumbName: 'Storage',
        routes: [
          { path: '/system-settings/storage', redirect: '/system-settings/storage/list' },
          {
            path: '/system-settings/storage/list',
            name: 'storage',
            component: StorageList,
            authobject: 'storage-view',
          },
          {
            path: '/system-settings/storage/add',
            name: 'storage add',
            component: (props) => <StorageForm add {...props} />,
            authobject: 'storage-create',
          },
          {
            path: '/system-settings/storage/upload',
            name: 'storage upload',
            component: (props) => <StorageUpload add {...props} />,
            authobject: 'storage-create',
          },
          {
            path: '/system-settings/storage/:id/view',
            name: 'storage view',
            component: (props) => <StorageForm view {...props} />,
            authobject: 'storage-view',
          },
          {
            path: '/system-settings/storage/:id',
            name: 'storage edit',
            component: (props) => <StorageForm edit {...props} />,
            authobject: 'storage-update',
          },
        ]
      },
      //classification-material
      {
        path: '/system-settings/classification-material',
        name: 'classification-material',
        breadcrumbName: 'Classification',
        routes: [
          { path: '/system-settings/classification-material', redirect: '/system-settings/classification-material/list' },
          {
            path: '/system-settings/classification-material/list',
            name: 'classification-material',
            component: ClassificationMaterialList,
            authobject: 'classification-material-view',
          },
          {
            path: '/system-settings/classification-material/add',
            name: 'classification-material add',
            component: (props) => <ClassificationMaterialForm add {...props} />,
            authobject: 'classification-material-create',
          },
          {
            path: '/system-settings/classification-material/:id/view',
            name: 'classification-material view',
            component: (props)=> <ClassificationMaterialForm view {...props} />,
            authobject: 'classification-material-view',
          },
          {
            path: '/system-settings/classification-material/:id/:paramid',
            name: 'classification-material edit',
            component: ClassificationEditParamForm,
            authobject: 'classification-material-update',
          },
          {
            path: '/system-settings/classification-material/:id',
            name: 'classification-material edit',
            component: (props) => <ClassificationMaterialForm edit {...props} />,
            authobject: 'classification-material-update',
          },
        ]
      },
      //material type
      {
        path: '/system-settings/material-type',
        name: 'material-type',
        breadcrumbName: 'Material Type',
        routes: [
          { path: '/system-settings/material-type', redirect: '/system-settings/material-type/list' },
          {
            path: '/system-settings/material-type/list',
            name: 'material-type',
            component: MaterialTypeList,
            authobject: 'material-view',
          },
          {
            path: '/system-settings/material-type/add',
            name: 'material-type add',
            component: (props) => <MaterialTypeForm add {...props} />,
            authobject: 'material-create',
          },
          {
            path: '/system-settings/material-type/:id/view',
            name: 'material-type view',
            component: (props) => <MaterialTypeForm view {...props} />,
            authobject: 'material-view',
          },
          {
            path: '/system-settings/material-type/:id',
            name: 'material-type edit',
            component: (props) => <MaterialTypeForm edit {...props} />,
            authobject: 'material-update',
          },
        ]
      },
      // user-group
      {
        path: '/system-settings/user-group',
        name: 'user-group',
        breadcrumbName: 'User Group',
        routes: [
          { path: '/system-settings/user-group', redirect: '/system-settings/user-group/list' },
          {
            path: '/system-settings/user-group/list',
            name: 'user-group',
            component: UserGroupList,
            authobject: 'user-group-view',
          },
          {
            path: '/system-settings/user-group/add',
            name: 'user-group add',
            component: (props) => <UserGroupForm add {...props} />,
            authobject: 'user-group-create',
          },
          {
            path: '/system-settings/user-group/:id/view',
            name: 'user-group view',
            component: (props) => <UserGroupForm view {...props} />,
            authobject: 'user-group-view',
          },
          {
            path: '/system-settings/user-group/:id',
            name: 'user-group edit',
            component: (props) => <UserGroupForm edit {...props} />,
            authobject: 'user-group-update',
          },
        ]
      },
      //company
      {
        path: '/system-settings/company',
        name: 'company',
        breadcrumbName: 'Company',
        routes: [
          { path: '/system-settings/company', redirect: '/system-settings/company/list' },
          {
            path: '/system-settings/company/list',
            name: 'company',
            component: CompanyList,
            authobject: 'company-view',
          },
          {
            path: '/system-settings/company/add',
            name: 'company add',
            component: (props) => <CompanyForm add {...props} />,
            authobject: 'company-create',
          },
          {
            path: '/system-settings/company/:id/view',
            name: 'company view',
            component: (props) => <CompanyForm view {...props} />,
            authobject: 'company-view',
          },
          {
            path: '/system-settings/company/:id',
            name: 'company edit',
            component: (props) => <CompanyForm edit {...props} />,
            authobject: 'company-update',
          },
        ]
      },
      //department
      {
        path: '/system-settings/department',
        name: 'department',
        breadcrumbName: 'Department',
        routes: [
          { path: '/system-settings/department', redirect: '/system-settings/department/list' },
          {
            path: '/system-settings/department/list',
            name: 'department',
            component: DepartmentList,
            authobject: 'department-view',
          },
          {
            path: '/system-settings/department/add',
            name: 'department add',
            component: (props) => <DepartmentForm add {...props} />,
            authobject: 'department-create',
          },
          {
            path: '/system-settings/department/:id/view',
            name: 'department view',
            component: (props) => <DepartmentForm view {...props} />,
            authobject: 'department-view',
          },
          {
            path: '/system-settings/department/:id',
            name: 'department edit',
            component: (props) => <DepartmentForm edit {...props} />,
            authobject: 'department-update',
          },
        ]
      },
      //cost-center
      {
        path: '/system-settings/cost-center',
        name: 'cost-center',
        breadcrumbName: 'Cost Center',
        routes: [
          { path: '/system-settings/cost-center', redirect: '/system-settings/cost-center/list' },
          {
            path: '/system-settings/cost-center/list',
            name: 'cost-center',
            component: CostCenterList,
            authobject: 'cost-center-view',
          },
          {
            path: '/system-settings/cost-center/add',
            name: 'cost-center add',
            component: (props) => <CostCenterForm add {...props} />,
            authobject: 'cost-center-create',
          },
          {
            path: '/system-settings/cost-center/:id/view',
            name: 'cost-center view',
            component: (props) => <CostCenterForm view {...props} />,
            authobject: 'cost-center-view',
          },
          {
            path: '/system-settings/cost-center/:id',
            name: 'cost-center edit',
            component: (props) => <CostCenterForm edit {...props} />,
            authobject: 'cost-center-update',
          },
        ]
      },
      //stock-determination
      {
        path: '/system-settings/stock-determination',
        name: 'stock-determination',
        breadcrumbName: 'Stock Determination',
        routes:[
          { path: '/system-settings/stock-determination', redirect: '/system-settings/stock-determination/list' },
          {
            path: '/system-settings/stock-determination/list',
            name: 'stock-determination',
            component: StockDeterminationList,
            authobject: 'stock-determination-view',
          },
          {
            path: '/system-settings/stock-determination/add',
            name: 'stock-determination add',
            component: (props)=> <StockDeterminationForm add {...props}/>,
            authobject: 'stock-determination-create',
          },
          {
            path: '/system-settings/stock-determination/upload',
            name: 'stock-determination upload',
            component: (props) => <StockDeterminationUpload add {...props} />,
            authobject: 'stock-determination-create',
          },
          {
            path: '/system-settings/stock-determination/:id/view',
            name: 'stock-determination view',
            component: (props) => <StockDeterminationForm view {...props} />,
            authobject: 'stock-determination-view',
          },
          {
            path: '/system-settings/stock-determination/:id',
            name: 'stock-determination edit',
            component: (props)=> <StockDeterminationForm edit {...props}/>,
            authobject: 'stock-determination-update',
          },
        ]
      },
      //work-center
      {
        path: '/system-settings/work-center',
        name: 'work-center',
        breadcrumbName: 'Work Center',
        routes:[
          { path: '/system-settings/work-center', redirect: '/system-settings/work-center/list' },
          {
            path: '/system-settings/work-center/list',
            name: 'work-center',
            component: WorkCenterList,
            authobject: 'work-center-view',
          },
          {
            path: '/system-settings/work-center/add',
            name: 'work-center add',
            component: (props)=> <WorkCenterForm add {...props}/>,
            authobject: 'work-center-create',
          },
          {
            path: '/system-settings/work-center/:id/view',
            name: 'work-center view',
            component: (props) => <WorkCenterForm view {...props} />,
            authobject: 'work-center-view',
          },
          {
            path: '/system-settings/work-center/:id',
            name: 'work-center edit',
            component: (props)=> <WorkCenterForm edit {...props}/>,
            authobject: 'work-center-update',
          },
        ]
      },
      //movement-type
      {
        path: '/system-settings/movement-type',
        name: 'movement-type',
        breadcrumbName: 'Movement Type',
        routes:[
          { path: '/system-settings/movement-type', redirect: '/system-settings/movement-type/list' },
          {
            path: '/system-settings/movement-type/list',
            name: 'movement-type',
            component: MovementTypeList,
            authobject: 'movement-type-view',
          },
          {
            path: '/system-settings/movement-type/add',
            name: 'movement-type add',
            component: (props)=> <MovementTypeForm add {...props}/>,
            authobject: 'movement-type-create',
          },
          {
            path: '/system-settings/movement-type/:id/view',
            name: 'movement-type view',
            component: (props) => <MovementTypeForm view {...props} />,
            authobject: 'movement-type-view',
          },
          {
            path: '/system-settings/movement-type/:id/:paramid',
            name: 'movement-type edit',
            component: MovementTypeEditReasonForm,
            authobject: 'movement-type-update',
          },
          {
            path: '/system-settings/movement-type/:id',
            name: 'movement-type edit',
            component: (props)=> <MovementTypeForm edit {...props}/>,
            authobject: 'movement-type-update',
          },
        ]
      },
      //transport-type
      {
        path: '/system-settings/transport-type',
        name: 'transport-type',
        breadcrumbName: 'Transport Type',
        routes: [
          { path: '/system-settings/transport-type', redirect: '/system-settings/transport-type/list' },
          {
            path: '/system-settings/transport-type/list',
            name: 'transport-type',
            component: TransportTypeList,
            authobject: 'transport-type-view',
          },
          {
            path: '/system-settings/transport-type/add',
            name: 'transport-type add',
            component: (props) => <TransportTypeForm add {...props} />,
            authobject: 'transport-type-create',
          },
          {
            path: '/system-settings/transport-type/:id/view',
            name: 'transport-type view',
            component: (props) => <TransportTypeForm view {...props} />,
            authobject: 'transport-type-view',
          },
          {
            path: '/system-settings/transport-type/:id',
            name: 'transport-type edit',
            component: (props) => <TransportTypeForm edit {...props} />,
            authobject: 'transport-type-update',
          },
        ]
      },
      //incoterm
      {
        path: '/system-settings/incoterm',
        name: 'incoterm',
        breadcrumbName: 'Incoterm',
        routes: [
          { path: '/system-settings/incoterm', redirect: '/system-settings/incoterm/list' },
          {
            path: '/system-settings/incoterm/list',
            name: 'incoterm',
            component: IncotermList,
            authobject: 'incoterm-view',
          },
          {
            path: '/system-settings/incoterm/add',
            name: 'incoterm add',
            component: (props) => <IncotermForm add {...props} />,
            authobject: 'incoterm-create',
          },
          {
            path: '/system-settings/incoterm/:id/view',
            name: 'incoterm view',
            component: (props) => <IncotermForm view {...props} />,
            authobject: 'incoterm-view',
          },
          {
            path: '/system-settings/incoterm/:id',
            name: 'incoterm edit',
            component: (props) => <IncotermForm edit {...props} />,
            authobject: 'incoterm-update',
          },
        ]
      },
      //term-of-payment
      {
        path: '/system-settings/term-of-payment',
        name: 'term-of-payment',
        breadcrumbName: 'Term of Payment',
        routes: [
          { path: '/system-settings/term-of-payment', redirect: '/system-settings/term-of-payment/list' },
          {
            path: '/system-settings/term-of-payment/list',
            name: 'term-of-payment',
            component: TermOfPaymentList,
            authobject: 'term-payment-view',
          },
          {
            path: '/system-settings/term-of-payment/add',
            name: 'term-of-payment add',
            component: (props) => <TermOfPaymentForm add {...props} />,
            authobject: 'term-payment-create',
          },
          {
            path: '/system-settings/term-of-payment/:id/view',
            name: 'term-of-payment view',
            component: (props) => <TermOfPaymentForm view {...props} />,
            authobject: 'term-payment-view',
          },
          {
            path: '/system-settings/term-of-payment/:id',
            name: 'term-of-payment edit',
            component: (props) => <TermOfPaymentForm edit {...props} />,
            authobject: 'term-payment-update',
          },
        ]
      },
      //project
      {
        path: '/system-settings/project',
        name: 'project',
        breadcrumbName: 'Project',
        routes: [
          { path: '/system-settings/project', redirect: '/system-settings/project/list' },
          {
            path: '/system-settings/project/list',
            name: 'project',
            component: ProjectList,
            authobject: 'incoterm-view',
          },
          {
            path: '/system-settings/project/add',
            name: 'project add',
            component: (props) => <ProjectForm add {...props} />,
            authobject: 'project-create',
          },
          {
            path: '/system-settings/project/:id/view',
            name: 'project view',
            component: (props) => <ProjectForm view {...props} />,
            authobject: 'project-view',
          },
          {
            path: '/system-settings/project/:id',
            name: 'project edit',
            component: (props) => <ProjectForm edit {...props} />,
            authobject: 'project-update',
          },
        ]
      },
      //chart-of-account
      {
        path: '/system-settings/chart-of-account',
        name: 'chart-of-account',
        breadcrumbName: 'Chart of Account',
        routes: [
          { path: '/system-settings/chart-of-account', redirect: '/system-settings/chart-of-account/list' },
          {
            path: '/system-settings/chart-of-account/list',
            name: 'chart-of-account',
            component: CoaList,
            authobject: 'chart-of-account-view',
          },
          {
            path: '/system-settings/chart-of-account/add',
            name: 'chart-of-account add',
            component: (props) => <CoaForm add {...props} />,
            authobject: 'chart-of-account-create',
          },
          // {
          //     path: '/system-settings/chart-of-account/:id/view',
          //     name: 'chart-of-account view',
          //     component: (props) => <CoaForm view {...props} />,
          // },
          {
            path: '/system-settings/chart-of-account/upload',
            name: 'chart-of-account upload',
            component: (props) => <CoaUpload add {...props} />,
            authobject: 'chart-of-account-create',
          },
          {
            path: '/system-settings/chart-of-account/:id',
            name: 'chart-of-account edit',
            component: (props) => <CoaForm edit {...props} />,
            authobject: 'chart-of-account-update',
          },
          
        ]
      },
      //account-group
      {
        path: '/system-settings/chart-of-account/:id/account-group',
        name: 'account-group',
        breadcrumbName: 'Account Group',
        routes: [
          { path: '/system-settings/chart-of-account/:id/account-group', redirect: '/system-settings/chart-of-account/:id/account-group/list' },
          {
            path: '/system-settings/chart-of-account/:id/account-group/list',
            name: 'account-group',
            component: AccountGroupList,
            authobject: 'account-group-view',
          },
          {
            path: '/system-settings/chart-of-account/:id/account-group/add',
            name: 'account-group add',
            component: (props) => <AccountGroupForm add {...props} />,
            authobject: 'account-group-create',
          },
          {
            path: '/system-settings/chart-of-account/:id_chart_of_account/account-group/:id',
            name: 'account-group edit',
            component: (props) => <AccountGroupForm edit {...props} />,
            authobject: 'account-group-update',
          },
        ]
      },
      //account
      {
        path: '/system-settings/account-group/:id/account',
        name: 'account',
        breadcrumbName: 'Account',
        routes: [
          { path: '/system-settings/account-group/:id/account', redirect: '/system-settings/account-group/:id/account/list' },
          {
            path: '/system-settings/account-group/:id/account/list',
            name: 'account-group',
            component: AccountList,
            authobject: 'account-view',
          },
          {
            path: '/system-settings/account-group/:id/account/add',
            name: 'account-group add',
            component: (props) => <AccountForm add {...props} />,
            authobject: 'account-create',
          },
          {
            path: '/system-settings/account-group/:id_account_group/account/:id',
            name: 'account-group edit',
            component: (props) => <AccountForm edit {...props} />,
            authobject: 'account-update',
          },
        ]
      },
      //accounting-procedure
      {
        path: '/system-settings/accounting-procedure',
        name: 'accounting-procedure',
        breadcrumbName: 'Accounting Procedure',
        routes: [
          { path: '/system-settings/accounting-procedure', redirect: '/system-settings/accounting-procedure/list' },
          {
            path: '/system-settings/accounting-procedure/list',
            name: 'accounting-procedure',
            component: AccountingProcedureList,
            authobject: 'accounting-procedure-view',
          },
          {
            path: '/system-settings/accounting-procedure/add',
            name: 'accounting-procedure add',
            component: (props) => <AccountingProcedureForm add {...props} />,
            authobject: 'accounting-procedure-create',
          },
          {
            path: '/system-settings/accounting-procedure/:id/view',
            name: 'accounting-procedure view',
            component: (props) => <AccountingProcedureForm view {...props} />,
            authobject: 'accounting-procedure-view',
          },
          {
            path: '/system-settings/accounting-procedure/:id',
            name: 'accounting-procedure edit',
            component: (props) => <AccountingProcedureForm edit {...props} />,
            authobject: 'accounting-procedure-update',
          },
        ]
      },
      //global-config
      {
        path: '/system-settings/global-config',
        name: 'global-config',
        breadcrumbName: 'Global Config',
        routes: [
          { path: '/system-settings/global-config', redirect: '/system-settings/global-config/list' },
          {
            path: '/system-settings/global-config/list',
            name: 'global-config',
            component: GlobalConfigList,
            authobject: 'setting-view',
          },
          {
            path: '/system-settings/procurement-group/add',
            name: 'global-config add',
            component: (props) => <GlobalConfigForm add {...props} />,
            authobject: 'setting-create',
          },
          {
            path: '/system-settings/global-config/:key/view',
            name: 'global-config view',
            component: (props) => <GlobalConfigForm view {...props} />,
            authobject: 'setting-view',
          },
          {
            path: '/system-settings/global-config/:key',
            name: 'global-config edit',
            component: (props) => <GlobalConfigForm edit {...props} />,
            authobject: 'setting-update',
          },
        ]
      },
      //release-object
      {
        path: '/system-settings/release-object',
        name: 'release-object',
        breadcrumbName: 'Release Object',
        routes: [
          { path: '/system-settings/release-object', redirect: '/system-settings/release-object/list' },
          {
            path: '/system-settings/release-object/list',
            name: 'release-object',
            component: ReleaseObjectList,
            authobject: 'release-object-view',
          },
          {
            path: '/system-settings/release-object/add',
            name: 'release-object add',
            component: (props) => <ReleaseObjectForm add {...props} />,
            authobject: 'release-object-create',
          },
          {
            path: '/system-settings/release-object/:id/view',
            name: 'release-object view',
            component: (props) => <ReleaseObjectForm view {...props} />,
            authobject: 'release-object-view',
          },
          {
            path: '/system-settings/release-object/:id',
            name: 'release-object edit',
            component: (props) => <ReleaseObjectForm edit {...props} />,
            authobject: 'release-object-update',
          },
        ]
      },
      //release-group
      {
        path: '/system-settings/release-group',
        name: 'release-group',
        breadcrumbName: 'Release Group',
        routes: [
          { path: '/system-settings/release-group', redirect: '/system-settings/release-group/list' },
          {
            path: '/system-settings/release-group/list',
            name: 'release-group',
            component: ReleaseGroupList,
            authobject: 'release-group-view',
          },
          {
            path: '/system-settings/release-group/add',
            name: 'release-group add',
            component: (props) => <ReleaseGroupForm add {...props} />,
            authobject: 'release-group-create',
          },
          {
            path: '/system-settings/release-group/:id/view',
            name: 'release-group view',
            component: (props) => <ReleaseGroupForm view {...props} />,
            authobject: 'release-group-view',
          },
          {
            path: '/system-settings/release-group/:id',
            name: 'release-group edit',
            component: (props) => <ReleaseGroupForm edit {...props} />,
            authobject: 'release-group-update',
          },
        ]
      },
      //release-code
      {
        path: '/system-settings/release-code',
        name: 'release-code',
        breadcrumbName: 'Release Code',
        routes: [
          { path: '/system-settings/release-code', redirect: '/system-settings/release-code/list' },
          {
            path: '/system-settings/release-code/list',
            name: 'release-code',
            component: ReleaseCodeList,
            authobject: 'release-code-view',
          },
          {
            path: '/system-settings/release-code/add',
            name: 'release-code add',
            component: (props) => <ReleaseCodeForm add {...props} />,
            authobject: 'release-code-create',
          },
          {
            path: '/system-settings/release-code/:id/view',
            name: 'release-code view',
            component: (props) => <ReleaseCodeForm view {...props} />,
            authobject: 'release-code-view',
          },
          {
            path: '/system-settings/release-code/:id',
            name: 'release-code edit',
            component: (props) => <ReleaseCodeForm edit {...props} />,
            authobject: 'release-code-update',
          },
        ]
      },

      //release-strategy
      {
        path: '/system-settings/release-strategy',
        name: 'release-strategy',
        breadcrumbName: 'Release Strategy',
        routes: [
          { path: '/system-settings/release-strategy', redirect: '/system-settings/release-strategy/list' },
          {
            path: '/system-settings/release-strategy/list',
            name: 'release-strategy',
            component: ReleaseStrategyList,
            authobject: 'release-strategy-view',
          },
          {
            path: '/system-settings/release-strategy/add',
            name: 'release-strategy add',
            component: (props) => <ReleaseStrategyForm add {...props} />,
            authobject: 'release-strategy-create',
          },
          {
            path: '/system-settings/release-strategy/:id/view',
            name: 'release-strategy view',
            component: (props) => <ReleaseStrategyForm view {...props} />,
            authobject: 'release-strategy-view',
          },
          {
            path: '/system-settings/release-strategy/:id',
            name: 'release-strategy edit',
            component: (props) => <ReleaseStrategyForm edit {...props} />,
            authobject: 'release-strategy-update',
          },
        ]
      },

      //release-code-user
      {
        path: '/system-settings/release-code-user',
        name: 'release-code-user',
        breadcrumbName: 'Release Code User',
        routes: [
          { path: '/system-settings/release-code-user', redirect: '/system-settings/release-code-user/list' },
          {
            path: '/system-settings/release-code-user/list',
            name: 'release-code-user',
            component: ReleaseCodeUserList,
            authobject: 'release-code-view',
          },
          {
            path: '/system-settings/release-code-user/add',
            name: 'release-code-user add',
            component: (props) => <ReleaseCodeUserForm add {...props} />,
            authobject: 'release-code-create',
          },
          {
            path: '/system-settings/release-code-user/:id/view',
            name: 'release-code-user view',
            component: (props) => <ReleaseCodeUserForm view {...props} />,
            authobject: 'release-code-view',
          },
          {
            path: '/system-settings/release-code-user/:id',
            name: 'release-code-user edit',
            component: (props) => <ReleaseCodeUserForm edit {...props} />,
            authobject: 'release-code-update',
          },
        ]
      },

      //depreciation-group
      {
        path: '/system-settings/depreciation-group',
        name: 'depreciation-group',
        breadcrumbName: 'Depreciation Group',
        routes: [
          { path: '/system-settings/depreciation-group', redirect: '/system-settings/depreciation-group/list' },
          {
            path: '/system-settings/depreciation-group/list',
            name: 'depreciation-group',
            component: DepreciationGroupList,
            authobject: 'depre-group-view',
          },
          {
            path: '/system-settings/depreciation-group/add',
            name: 'depreciation-group add',
            component: (props) => <DepreciationGroupForm add {...props} />,
            authobject: 'depre-group-create',
          },
          {
            path: '/system-settings/depreciation-group/:id/view',
            name: 'depreciation-group view',
            component: (props) => <DepreciationGroupForm view {...props} />,
            authobject: 'depre-group-view',
          },
          {
            path: '/system-settings/depreciation-group/:id',
            name: 'depreciation-type edit',
            component: (props) => <DepreciationGroupForm edit {...props} />,
            authobject: 'depre-group-update',
          },
        ]
      },

      //depreciation-type
      {
        path: '/system-settings/depreciation-type',
        name: 'depreciation-type',
        breadcrumbName: 'Depreciation',
        routes: [
          { path: '/system-settings/depreciation-type', redirect: '/system-settings/depreciation-type/list' },
          {
            path: '/system-settings/depreciation-type/list',
            name: 'depreciation-type',
            component: DepreciationTypeList,
            authobject: 'depre-type-view',
          },
          {
            path: '/system-settings/depreciation-type/add',
            name: 'depreciation-type add',
            component: (props) => <DepreciationTypeForm add {...props} />,
            authobject: 'depre-type-create',
          },
          {
            path: '/system-settings/depreciation-type/:id/view',
            name: 'depreciation-type view',
            component: (props)=> <DepreciationTypeForm view {...props} />,
            authobject: 'depre-type-view',
          },
          {
            path: '/system-settings/depreciation-type/:id/:paramid',
            name: 'depreciation-type edit',
            component: DepreciationEditPeriodForm,
            authobject: 'depre-type-update',
          },
          {
            path: '/system-settings/depreciation-type/:id',
            name: 'depreciation-type edit',
            component: (props) => <DepreciationTypeForm edit {...props} />,
            authobject: 'depre-type-update',
          },
        ]
      },

      //asset-valuation-type
      {
        path: '/system-settings/asset-valuation-type',
        name: 'asset-valuation-type',
        breadcrumbName: 'Asset Valuation Type',
        routes: [
          { path: '/system-settings/asset-valuation-type', redirect: '/system-settings/asset-valuation-type/list' },
          {
            path: '/system-settings/asset-valuation-type/list',
            name: 'asset-valuation-type',
            component: AssetValuationList,
            authobject: 'valuation-asset-view',
          },
          {
            path: '/system-settings/asset-valuation-type/add',
            name: 'asset-valuation-type add',
            component: (props) => <AssetValuationForm add {...props} />,
            authobject: 'valuation-asset-create',
          },
          {
            path: '/system-settings/asset-valuation-type/:id/view',
            name: 'asset-valuation-type view',
            component: (props) => <AssetValuationForm view {...props} />,
            authobject: 'valuation-asset-view',
          },
          {
            path: '/system-settings/asset-valuation-type/:id',
            name: 'asset-valuation-type edit',
            component: (props) => <AssetValuationForm edit {...props} />,
            authobject: 'valuation-asset-update',
          },
        ]
      },

      //retired-reason
      {
        path: '/system-settings/retired-reason',
        name: 'retired-reason',
        breadcrumbName: 'Retired Reason',
        routes: [
          { path: '/system-settings/retired-reason', redirect: '/system-settings/retired-reason/list' },
          {
            path: '/system-settings/retired-reason/list',
            name: 'retired-reason',
            component: RetiredReasonList,
            authobject: 'retired-reason-view',
          },
          {
            path: '/system-settings/retired-reason/add',
            name: 'retired-reason add',
            component: (props) => <RetiredReasonForm add {...props} />,
            authobject: 'retired-reason-create',
          },
          {
            path: '/system-settings/retired-reason/:id/view',
            name: 'retired-reason view',
            component: (props) => <RetiredReasonForm view {...props} />,
            authobject: 'retired-reason-view',
          },
          {
            path: '/system-settings/retired-reason/:id',
            name: 'retired-reason edit',
            component: (props) => <RetiredReasonForm edit {...props} />,
            authobject: 'retired-reason-update',
          },
        ]
      },

      //asset-type
      {
        path: '/system-settings/asset-type',
        name: 'asset-type',
        breadcrumbName: 'Asset Type',
        routes: [
          { path: '/system-settings/asset-type', redirect: '/system-settings/asset-type/list' },
          {
            path: '/system-settings/asset-type/list',
            name: 'asset-type',
            component: AssetTypeList,
            authobject: 'asset-type-view',
          },
          {
            path: '/system-settings/asset-type/add',
            name: 'asset-type add',
            component: (props) => <AssetTypeForm add {...props} />,
            authobject: 'asset-type-create',
          },
          {
            path: '/system-settings/asset-type/:id/view',
            name: 'asset-type view',
            component: (props) => <AssetTypeForm view {...props} />,
            authobject: 'asset-type-view',
          },
          {
            path: '/system-settings/asset-type/:id',
            name: 'asset-type edit',
            component: (props) => <AssetTypeForm edit {...props} />,
            authobject: 'asset-type-update',
          },
        ]
      },

      //asset-status
      {
        path: '/system-settings/asset-status',
        name: 'asset-status',
        breadcrumbName: 'Asset Status',
        routes: [
          { path: '/system-settings/asset-status', redirect: '/system-settings/asset-status/list' },
          {
            path: '/system-settings/asset-status/list',
            name: 'asset-status',
            component: AssetStatusList,
            authobject: 'asset-status-view',
          },
          {
            path: '/system-settings/asset-status/add',
            name: 'asset-status add',
            component: (props) => <AssetStatusForm add {...props} />,
            authobject: 'asset-status-create',
          },
          {
            path: '/system-settings/asset-status/:id/view',
            name: 'asset-status view',
            component: (props) => <AssetStatusForm view {...props} />,
            authobject: 'asset-status-view',
          },
          {
            path: '/system-settings/asset-status/:id',
            name: 'asset-status edit',
            component: (props) => <AssetStatusForm edit {...props} />,
            authobject: 'asset-status-update',
          },
        ]
      },
            
      /**
             * Business Partner =============================================================
             */
      {
        path: '/business-partner',
        name: 'business-partner',
        breadcrumbName: 'Business Partner',
        component: Dashboard,
      },
      //location
      {
        path: '/business-partner/location',
        name: 'location',
        breadcrumbName: 'Location',
        routes: [
          { path: '/business-partner/location', redirect: '/business-partner/location/list' },
          {
            path: '/business-partner/location/list',
            name: 'location',
            component: LocationList,
            authobject: 'location-view',
          },
          {
            path: '/business-partner/location/add',
            name: 'location add',
            component: (props) => <LocationForm add {...props} />,
            authobject: 'location-create',
          },
          {
            path: '/business-partner/location/:id/view',
            name: 'location view',
            component: (props) => <LocationForm view {...props} />,
            authobject: 'location-view',
          },
          {
            path: '/business-partner/location/:id',
            name: 'location edit',
            component: (props) => <LocationForm edit {...props} />,
            authobject: 'location-update',
          },
        ]
      },
      //vendor
      {
        path: '/business-partner/vendor',
        name: 'vendor',
        breadcrumbName: 'Vendor',
        routes:[
          { path: '/business-partner/vendor', redirect: '/business-partner/vendor/list' },
          {
            path: '/business-partner/vendor/list',
            name: 'vendor',
            component: VendorList,
            authobject: 'supplier-view',
          },
          {
            path: '/business-partner/vendor/add',
            name: 'vendor add',
            component: (props)=> <VendorForm add {...props}/>,
            authobject: 'supplier-create',
          },
          {
            path: '/business-partner/vendor/:id/view',
            name: 'vendor view',
            component: (props) => <VendorForm view {...props} />,
            authobject: 'supplier-view',
          },
          {
            path: '/business-partner/vendor/:id',
            name: 'vendor edit',
            component: (props)=> <VendorForm edit {...props}/>,
            authobject: 'supplier-update',
          },
        ]
      },
      //vendor-group
      {
        path: '/system-settings/vendor-group',
        name: 'vendor-group',
        breadcrumbName: 'Vendor Group',
        routes: [
          { path: '/system-settings/vendor-group', redirect: '/system-settings/vendor-group/list' },
          {
            path: '/system-settings/vendor-group/list',
            name: 'vendor-group',
            component: VendorGroupList,
            authobject: 'vendor-group-view',
          },
          {
            path: '/system-settings/vendor-group/add',
            name: 'vendor-group add',
            component: (props) => <VendorGroupForm add {...props} />,
            authobject: 'vendor-group-create',
          },
          {
            path: '/system-settings/vendor-group/:id/view',
            name: 'vendor-group view',
            component: (props) => <VendorGroupForm view {...props} />,
            authobject: 'vendor-group-view',
          },
          {
            path: '/system-settings/vendor-group/:id',
            name: 'vendor-group edit',
            component: (props) => <VendorGroupForm edit {...props} />,
            authobject: 'vendor-group-update',
          },
        ]
      },
      /**
             * Inventory ====================================================
             */
      {
        path: '/inventory',
        name: 'inventory',
        breadcrumbName: 'Inventory',
        component: Dashboard,
      },
      //stock-overview
      {
        path: '/inventory/stock-overview',
        name: 'stock-overview',
        breadcrumbName: 'Stock Overview',
        routes:[
          { path: '/inventory/stock-overview', redirect: '/inventory/stock-overview/list' },
          {
            path: '/inventory/stock-overview/list',
            name: 'stock-overview',
            component: StockOverviewList,
            authobject: 'stock-overview',
          },
        ]
      },
      //stock-opname
      {
        path: '/inventory/stock-opname',
        name: 'stock-opname',
        breadcrumbName: 'Stock Opname',
        routes: [
          { path: '/inventory/stock-opname', redirect: '/inventory/stock-opname/list' },
          {
            path: '/inventory/stock-opname/list',
            name: 'stock-opname',
            component: StockOpnameList,
            authobject: 'stock-opname-view',
          },
          {
            path: '/inventory/stock-opname/add',
            name: 'stock-opname add',
            component: (props) => <StockOpnameForm add {...props} />,
            authobject: 'stock-opname-create',
          },
          {
            path: '/inventory/stock-opname/:id/view',
            name: 'stock-opname view',
            component: (props) => <StockOpnameForm view {...props} />,
            authobject: 'stock-opname-view',
          },
          {
            path: '/inventory/stock-opname/:id',
            name: 'stock-opname edit',
            component: (props) => <StockOpnameForm edit {...props} />,
            authobject: 'stock-opname-update',
          },
        ]
      },
      //Reservation
      {
        path: '/inventory/reservation',
        name: 'reservation',
        breadcrumbName: 'Reservation',
        routes:[
          { path: '/inventory/reservation', redirect: '/inventory/reservation/list' },
          {
            path: '/inventory/reservation/list',
            name: 'reservation',
            component: ReservationList,
            authobject: 'reservation-view',
          },
          {
            path: '/inventory/reservation/catalogue',
            name: 'reservation add',
            component: (props)=> <ReservationCatalogue add {...props}/>,
            authobject: 'reservation-create',
          },
          {
            path: '/inventory/reservation/add',
            name: 'reservation add',
            component: (props)=> <ReservationForm add {...props}/>,
            authobject: 'reservation-create',
          },
          {
            path: '/inventory/reservation/:reservation_code/view',
            name: 'reservation view',
            component: (props) => <ReservationForm view {...props} />,
            authobject: 'reservation-view',
          },
          {
            path: '/inventory/reservation/:reservation_code',
            name: 'reservation edit',
            component: (props)=> <ReservationForm edit {...props}/>,
            authobject: 'reservation-update',
          },
                    
        ]
      },
      //PR
      {
        path: '/inventory/purchase-requisition',
        name: 'purchase-requisition',
        breadcrumbName: 'Purchase Requisition',
        routes:[
          { path: '/inventory/purchase-requisition', redirect: '/inventory/purchase-requisition/list' },
          {
            path: '/inventory/purchase-requisition/list',
            name: 'Purchase Requisition',
            component: PurchaseRequisitionList,
            authobject: 'reservation-view',
          },
          {
            path: '/inventory/purchase-requisition/add',
            name: 'Purchase Requisition add',
            component: (props)=> <PurchaseRequisitionForm add {...props}/>,
            authobject: 'reservation-create',
          },
          {
            path: '/inventory/purchase-requisition/:reservation_code/view',
            name: 'Purchase Requisition view',
            component: (props) => <PurchaseRequisitionForm view {...props} />,
            authobject: 'reservation-view',
          },
          {
            path: '/inventory/purchase-requisition/:reservation_code',
            name: 'Purchase Requisition edit',
            component: (props)=> <PurchaseRequisitionForm edit {...props}/>,
            authobject: 'reservation-update',
          },
                    
        ]
      },
      //Incoming Invoice
      {
        path: '/inventory/incoming-invoice',
        name: 'incoming-invoice',
        breadcrumbName: 'Incoming Invoice',
        routes:[
          { path: '/inventory/incoming-invoice', redirect: '/inventory/incoming-invoice/list' },
          {
            path: '/inventory/incoming-invoice/list',
            name: 'incoming-invoice',
            component: IncomingInvoiceList,
            authobject: 'incoming-invoice-view',
          },
          {
            path: '/inventory/incoming-invoice/add',
            name: 'incoming-invoice add',
            component: (props)=> <IncomingInvoiceForm add {...props}/>,
            authobject: 'incoming-invoice-create',
          },
          {
            path: '/inventory/incoming-invoice/:id/view',
            name: 'incoming-invoice view',
            component: (props) => <IncomingInvoiceForm view {...props} />,
            authobject: 'incoming-invoice-view',
          },
          {
            path: '/inventory/incoming-invoice/:id',
            name: 'incoming-invoice edit',
            component: (props)=> <IncomingInvoiceForm edit {...props}/>,
            authobject: 'incoming-invoice-update',
          },
                    
        ]
      },      
      //incoming-invoice-report
      {
        path: '/inventory/incoming-invoice-report',
        name: 'incoming-invoice-report',
        breadcrumbName: 'Payment Report',
        routes:[
          { path: '/inventory/incoming-invoice-report', redirect: '/inventory/incoming-invoice-report/list' },
          {
            path: '/inventory/incoming-invoice-report/list',
            name: 'incoming-invoice-report',
            component: IncomingInvoiceReport,
            authobject: 'incoming-invoice-view',
          }
        ]
      },
      //PO
      {
        path: '/inventory/purchase-order',
        name: 'purchase-order',
        breadcrumbName: 'Purchase Order',
        routes:[
          { path: '/inventory/purchase-order', redirect: '/inventory/purchase-order/list' },
          {
            path: '/inventory/purchase-order/list',
            name: 'Purchase Order',
            component: PurchaseOrderList,
            authobject: 'purchase-orders-view',
          },
          {
            path: '/inventory/purchase-order/add',
            name: 'Purchase Order add',
            component: (props)=> <PurchaseOrderForm add {...props}/>,
            authobject: 'purchase-orders-create',
          },
          {
            path: '/inventory/purchase-order/:id/view',
            name: 'Purchase Order view',
            component: (props) => <PurchaseOrderForm view {...props} />,
            authobject: 'purchase-orders-view',
          },
          {
            path: '/inventory/purchase-order/:id',
            name: 'Purchase Order edit',
            component: (props)=> <PurchaseOrderForm edit {...props}/>,
            authobject: 'purchase-orders-update',
          },
                    
        ]
      },
      //RFQ
      {
        path: '/inventory/request-for-quotation',
        name: 'request-for-quotation',
        breadcrumbName: 'Request for Quotation',
        routes:[
          { path: '/inventory/request-for-quotation', redirect: '/inventory/request-for-quotation/list' },
          {
            path: '/inventory/request-for-quotation/list',
            name: 'Request for Quotation',
            component: RFQList,
            authobject: 'rfq-view',
          },
          {
            path: '/inventory/request-for-quotation/add',
            name: 'Request for Quotation add',
            component: (props)=> <RFQForm add {...props}/>,
            authobject: 'rfq-create',
          },
          {
            path: '/inventory/request-for-quotation/:id/view',
            name: 'Request for Quotation view',
            component: (props) => <RFQForm view {...props} />,
            authobject: 'rfq-view',
          },
          {
            path: '/inventory/request-for-quotation/:bid_number/awarding',
            name: 'Request for Quotation Awarding',
            component: (props)=> <RFQFormAwarding edit {...props}/>,
            authobject: 'rfq-update',
          },
          {
            path: '/inventory/request-for-quotation/:id',
            name: 'Request for Quotation edit',
            component: (props)=> <RFQForm edit {...props}/>,
            authobject: 'rfq-update',
          },
          
                    
        ]
      },
      //Goods Movement
      {
        path: '/inventory/goods-movement',
        name: 'goods-movement',
        breadcrumbName: 'Goods Movement',
        routes:[
          { path: '/inventory/goods-movement', redirect: '/inventory/goods-movement/list' },
          {
            path: '/inventory/goods-movement/create',
            name: 'goods-movement create',
            component: GoodsMovementForm,
            authobject: 'goods-movement-create',
          },
          {
            path: '/inventory/goods-movement/upload',
            name: 'goods-movement upload',
            component: GoodsMovementUpload,
            authobject: 'goods-movement-create',
          },
          {
            path: '/inventory/goods-movement/list',
            name: 'goods-movement list',
            component: GoodsMovementList,
            authobject: 'goods-movement-view',
          },
          {
            path: '/inventory/goods-movement/:id',
            name: 'goods-movement edit',
            component: (props)=> <GoodsMovementForm edit {...props}/>,
            authobject: 'goods-movement-update',
          },
        ]
      },
      //goods-movement-report
      {
        path: '/inventory/goods-movement-report',
        name: 'goods-movement-report',
        breadcrumbName: 'Goods Movement Report',
        routes:[
          { path: '/inventory/goods-movement-report', redirect: '/inventory/goods-movement-report/list' },
          {
            path: '/inventory/goods-movement-report/list',
            name: 'goods-movement-report',
            component: GoodsMovementReport,
            authobject: 'goods-movement-view',
          },
          {
            path: '/inventory/goods-movement-report/:id/view',
            name: 'goods-movement-report view',
            component: (props)=> <GoodsMovementReportForm view {...props}/>,
            authobject: 'goods-movement-view',
          },
        ]
      },
      //monthly-consumption
      {
        path: '/inventory/monthly-consumption',
        name: 'monthly-consumption',
        breadcrumbName: 'Monthly Consumption',
        routes:[
          { path: '/inventory/monthly-consumption', redirect: '/inventory/monthly-consumption/list' },
          {
            path: '/inventory/monthly-consumption/list',
            name: 'monthly-consumption',
            component: MonthlyConsumptionReport,
            authobject: 'monthly-consumption-view',
          },
        ]
      },
      //purchase document history
      {
        path: '/inventory/purchase-document-history',
        name: 'purchase-document-history',
        breadcrumbName: 'Purchase Document History',
        routes:[
          { path: '/inventory/purchase-document-history', redirect: '/inventory/purchase-document-history/list' },
          {
            path: '/inventory/purchase-document-history/list',
            name: 'purchase-document-history',
            component: PurchaseDocumentHistoryList,
            authobject: 'purchase-orders-view',
          },
        ]
      },
      /**
             * Inventory ====================================================
             */
      // {
      //     path: '/delivery',
      //     name: 'delivery',
      //     breadcrumbName: 'Delivery',
      //     routes: [
      //         { path: '/delivery', redirect: '/delivery/picking-list' },

                    
      //     ]
      // },
      /**
             * Work Order ====================================================
             */
      {
        path: '/work-order',
        name: 'work-order',
        breadcrumbName: 'Work Order',
        component: Dashboard,
      },
      //Task List
      {
        path: '/work-order/task-list',
        name: 'task-list',
        breadcrumbName: 'Task List',
        routes:[
          { path: '/work-order/task-list', redirect: '/work-order/task-list/list' },
          {
            path: '/work-order/task-list/list',
            name: 'task-list list',
            component: TaskList,
            authobject: 'task-list-view',
          },
          {
            path: '/work-order/task-list/add',
            name: 'task-list add',
            component: (props) => <TaskListForm add {...props} />,
            authobject: 'task-list-create',
          },
          {
            path: '/work-order/task-list/:id/view',
            name: 'task-list view',
            component: (props) => <TaskListForm view {...props} />,
            authobject: 'task-list-view',
          },
          {
            path: '/work-order/task-list/:id',
            name: 'task-list edit',
            component: (props)=> <TaskListForm edit {...props}/>,
            authobject: 'task-list-update',
          },
        ]
      },
      //Service Request
      {
        path: '/work-order/service-request',
        name: 'service-request',
        breadcrumbName: 'Service Request',
        routes:[
          { path: '/work-order/service-request', redirect: '/work-order/service-request/list' },
          {
            path: '/work-order/service-request/list',
            name: 'service-request list',
            component: ServiceRequestList,
            authobject: 'service-request-view',
          },
          {
            path: '/work-order/service-request/add',
            name: 'service-request add',
            component: (props) => <ServiceRequestForm add {...props} />,
            authobject: 'service-request-create',
          },
          {
            path: '/work-order/service-request/:id/view',
            name: 'service-request view',
            component: (props) => <ServiceRequestForm view {...props} />,
            authobject: 'service-request-view',
          },
          {
            path: '/work-order/service-request/:id',
            name: 'service-request edit',
            component: (props)=> <ServiceRequestForm edit {...props}/>,
            authobject: 'service-request-update',
          },
        ]
      },
      //Corrective Maintenance
      {
        path: '/work-order/corrective-maintenance',
        name: 'corrective-maintenance',
        breadcrumbName: 'Corrective Maintenance',
        routes:[
          { path: '/work-order/corrective-maintenance', redirect: '/work-order/corrective-maintenance/list' },
          {
            path: '/work-order/corrective-maintenance/list',
            name: 'corrective-maintenance list',
            component: CmWorkOrderList,
            authobject: 'service-request-view',
          },
          {
            path: '/work-order/corrective-maintenance/:id/add',
            name: 'corrective-maintenance create',
            component: (props) => <CmWorkOrderForm add {...props} />,
            authobject: 'corrective-maintenance-create',
          },
          {
            path: '/work-order/corrective-maintenance/:id/view',
            name: 'corrective-maintenance view',
            component: (props) => <CmWorkOrderDetail view {...props} />,
            authobject: 'corrective-maintenance-view',
          },
          {
            path: '/work-order/corrective-maintenance/:id/',
            name: 'corrective-maintenance update',
            component: (props) => <CmWorkOrderDetail edit {...props} />,
            authobject: 'corrective-maintenance-update',
          },
        ]
      },
      /**
             * Delivery ====================================================
             */
      {
        path: '/delivery',
        name: 'delivery',
        breadcrumbName: 'Delivery',
        component: Dashboard,
      },
      //Delivery List
      {
        path: '/delivery/delivery-list',
        name: 'delivery-list',
        breadcrumbName: 'Delivery List',
        routes:[
          { path: '/delivery/delivery-list', redirect: '/delivery/delivery-list/list' },
          {
            path: '/delivery/delivery-list/list',
            name: 'delivery-list list',
            component: DeliveryList,
            authobject: 'delivery-orders-view',
          },
          {
            path: '/delivery/delivery-list/:id/view',
            name: 'delivery-list view',
            component: (props) => <DeliveryForm view {...props} />,
            authobject: 'delivery-orders-view',
          },
          {
            path: '/delivery/delivery-list/:id',
            name: 'delivery-list edit',
            component: (props)=> <DeliveryForm edit {...props}/>,
            //authobject: 'delivery-orders-update',
          },
        ]
      },
      //Picking List
      {
        path: '/delivery/picking-list',
        name: 'picking-list',
        breadcrumbName: 'Picking List',
        // routes:[
        //     { path: '/delivery/picking-list', redirect: '/delivery/picking-list/list' },
        //     {
        //         path: '/delivery/picking-list/list',
        //         name: 'picking-list list',
        component: PickingList,
        authobject: 'picking-list-view',
        //     },
        // ]
      },
      
      //journal-depreciation
      {
        path: '/master/journal-depreciation',
        name: 'journal-depreciation',
        breadcrumbName: 'Journal Depreciation',
        routes:[
          { path: '/master/journal-depreciation', redirect: '/master/journal-depreciation/list' },
          {
            path: '/master/journal-depreciation/list',
            name: 'journal-depreciation',
            component: JournalDepreciationReport,
            authobject: 'journal-depreciation-view',
          },
        ]
      },
      //storage-condition
      {
        path: '/system-settings/account-condition',
        name: 'account-condition',
        breadcrumbName: 'Accounting Condition',
        routes: [
          { path: '/system-settings/account-condition', redirect: '/system-settings/account-condition/list' },
          {
            path: '/system-settings/account-condition/list',
            name: 'account-condition',
            component: AccountConditionList,
            authobject: 'condition-master-view',
          },
          {
            path: '/system-settings/account-condition/add',
            name: 'account-condition add',
            component: (props) => <AccountConditionForm add {...props} />,
            authobject: 'condition-master-create',
          },
          {
            path: '/system-settings/account-condition/:id/view',
            name: 'account-condition view',
            component: (props) => <AccountConditionForm view {...props} />,
            authobject: 'condition-master-view',
          },
          {
            path: '/system-settings/account-condition/:id',
            name: 'account-condition edit',
            component: (props) => <AccountConditionForm edit {...props} />,
            authobject: 'condition-master-update',
          },
        ]
      },

                  
      /**
             * Account Settings ====================================================
             */
      {
        path: '/account-settings',
        name: 'account-settings',
        breadcrumbName: 'Account Settings',
        component: AccountSettings,
        authobject: 'user-update-profile-self',
      },
            
            
            
      // 403 
      {
        path: '/403',
        component: Page403,
      },
      {
        path: '/400',
        component: Page400,
      },
      //redirect / to /dashboard
      { path: '/', redirect: '/dashboard' },
    ]
  },
];

export default routes